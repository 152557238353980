import { Box, Snackbar, Alert, AlertTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  addAttachmentsToReportPZ05Request,
  editReportPZ05Request,
  loadReportPZ05ByIdRequest,
} from '../../../redux/reportsPZ05Redux';
import { checkLoggedUser, getUser } from '../../../redux/usersRedux';
import ReportForm from '../../common/ReportForm/ReportForm';

const EditReportPZ05 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const [reportPZ05, setReportPZ05] = useState('');
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const [requestStatus, setRequestStatus] = useState('');

  const user = useSelector((state) => getUser(state));
  useEffect(() => {
    const start = async () => {
      await dispatch(checkLoggedUser());
      const res = await dispatch(loadReportPZ05ByIdRequest(id));
      if (res) {
        setReportPZ05(res.data);
        setLoading(false);
        if (res.data.statusReport !== 'NEW' && user.users.role !== 'ADMIN') {
          navigate('/pz05');
        }
      } else {
        navigate('/pz05');
      }
    };
    start();
  }, [dispatch]);

  const handleSubmit = async (data) => {
    console.log(data);
    setAlert(true);
    const { files, reportDetailsData, ...otherData } = data;
    const repDetailId = reportPZ05.reportDetails.id;

    reportDetailsData.id = repDetailId;

    const res = await dispatch(
      editReportPZ05Request({ id, reportDetailsData, ...otherData }),
    );

    setRequestStatus(res.status);
    if (!files || files.length > 0) {
      const attachments = await dispatch(
        addAttachmentsToReportPZ05Request({ files, reportId: res.data.id }),
      );
    }

    setTimeout(() => {
      navigate('/pz05');
    }, 2000);
  };

  const handleClose = () => {
    setAlert(false);
  };

  if (!loading) {
    return (
      <Box>
        {requestStatus === 200 && (
          <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              variant="filled"
              onClose={handleClose}
              severity="success"
              sx={{ width: '100%' }}
            >
              <AlertTitle>Success</AlertTitle>
              Raport zaktualizowany pomyślnie
            </Alert>
          </Snackbar>
        )}
        {requestStatus === 500 && (
          <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              variant="filled"
              onClose={handleClose}
              severity="error"
              sx={{ width: '100%' }}
            >
              <AlertTitle>Error</AlertTitle>
              Błąd serwera
            </Alert>
          </Snackbar>
        )}
        <ReportForm
          action={handleSubmit}
          actionText="edycji niezgodności"
          props={reportPZ05}
        />
      </Box>
    );
  }
};

export default EditReportPZ05;
