import {
  Autocomplete,
  Box,
  Button,
  LinearProgress,
  TextField,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { generateRaportPZ05 } from '../../../redux/reportsPZ05Redux';
import 'dayjs/locale/pl';
const warehouses = [
  { description: '8AAA' },
  { description: '8AAB' },
  { description: '8AAC' },
  { description: '8AAE' },
  { description: '8AAG' },
];

const decision = [
  { description: 'KIER_DS_REKLAMACJI' },
  { description: 'KIER_SYST_ZAPEWNIENIA_JAK' },
  { description: 'LIDER_PRZEPAKOWANIA' },
  { description: 'KIER_ODC_BWH' },
  { description: 'DSM' },
  { description: 'WOM' },
];

const inconformity = [{ description: 'Jakość' }, { description: 'Wiek' }];

const GenerateRaportPZ05 = () => {
  const dispatch = useDispatch();
  const [dateFrom, setDateFrom] = useState(undefined);
  const [dateTo, setDateTo] = useState(undefined);
  const [warehouse, setWarehouse] = useState(undefined);
  const [inconformityDes, setInconformityDes] = useState(undefined);
  const [decisionMaker, setDecisionMaker] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    let newDateTo = null;
    if (dateTo !== undefined) {
      newDateTo = new Date(dateTo);
      newDateTo.setHours(23, 59, 59, 999);
    }

    const res = await dispatch(
      generateRaportPZ05({
        dateFrom,
        dateTo: newDateTo,
        warehouse,
        inconformityDes,
        decisionMaker,
      }),
    );

    setLoading(false);
  };

  //   statusReport;
  //   activeDecision;

  return (
    <Box sx={{ width: '100%' }}>
      {loading && <LinearProgress color="success" sx={{ mb: 1 }} />}
      <Box
        sx={{
          display: { xs: 'flex', md: 'flex' },
          flexDirection: { xs: 'column', md: 'row' },
          ml: { xs: 2, md: 0 },
        }}
      >
        <LocalizationProvider adapterLocale="pl" dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Data początkowa"
            format="DD.MM.YYYY"
            views={['year', 'month', 'day']}
            value={dateFrom}
            onChange={(newValue) => {
              setDateFrom(newValue);
            }}
            sx={{ mr: 2, mb: { xs: 2 }, width: { xs: '100%', md: '300px' } }}
          />
          <DatePicker
            label="Data końcowa"
            format="DD.MM.YYYY"
            views={['year', 'month', 'day']}
            value={dateTo}
            onChange={(newValue) => {
              setDateTo(newValue);
            }}
            sx={{ mr: 2, width: { xs: '100%', md: '300px' } }}
          />
        </LocalizationProvider>
      </Box>
      <Box
        sx={{
          display: { xs: 'flex', md: 'flex' },
          flexDirection: { xs: 'column', md: 'row' },
          ml: { xs: 2, md: 0 },
        }}
      >
        <Autocomplete
          disablePortal
          id="warehouse"
          options={warehouses.map((el) => ({
            label: el.description,
          }))}
          sx={{ width: { xs: '100%', md: '300px' }, mt: 2, mr: 2 }}
          value={warehouse}
          onChange={(e, value) => setWarehouse(value ? value.label : undefined)}
          renderInput={(params) => <TextField {...params} label="Magazyn" />}
        />
        <Autocomplete
          disablePortal
          id="warehouse"
          options={inconformity.map((el) => ({
            label: el.description,
          }))}
          sx={{ width: { xs: '100%', md: '300px' }, mt: 2, mr: 2 }}
          value={inconformityDes}
          onChange={(e, value) =>
            setInconformityDes(value ? value.label : undefined)
          }
          renderInput={(params) => (
            <TextField {...params} label="Rodzaj niezgodności" />
          )}
        />
        <Autocomplete
          disablePortal
          id="warehouse"
          options={decision.map((el) => ({
            label: el.description,
          }))}
          sx={{ width: { xs: '100%', md: '300px' }, mt: 2, mr: 2 }}
          value={decisionMaker}
          onChange={(e, value) =>
            setDecisionMaker(value ? value.label : undefined)
          }
          renderInput={(params) => <TextField {...params} label="Decydent" />}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: { xs: 'center', md: 'flex-start' },
        }}
      >
        <Button
          variant="contained"
          sx={{
            mt: 1,
            ml: { xs: 2, md: 0 },
            width: { xs: '200px', md: '200px' },
            color: 'white',
            bgcolor: '#C09A53',
            ':hover': { bgcolor: '#9c7c43' },
          }}
          onClick={handleSubmit}
        >
          Generuj raport
        </Button>
      </Box>
    </Box>
  );
};
export default GenerateRaportPZ05;
