import {
  Box,
  Divider,
  Typography,
  Button,
  Modal,
  Autocomplete,
  TextField,
  Snackbar,
  Alert,
  AlertTitle,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import {
  dateFormatter,
  dateFormatterWithTime,
} from '../../../utils/dateFormatter';
import { ATT_URL, IMGS_URL } from '../../../config';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAttachmentsToReportPZ05Request,
  createDecisionPZ05Request,
  generatePdfRequest,
  generateRNPdfRequest,
  loadReportPZ05ByIdRequest,
  sendUpdateMail,
  updateDecisionsPZ05Request,
  updateOnlyDecisionPZ05Request,
  updateReportPZ05Request,
  updateValueDecisionPZ05Request,
  updateValueReportsPZ05Request,
} from '../../../redux/reportsPZ05Redux';
import { useEffect, useState } from 'react';
import { getUser } from '../../../redux/usersRedux';
import { changeStatusName } from '../../../utils/changeStatusName';
import DecisionForm from '../DecisionForm/DecisionForm';
import { useNavigate } from 'react-router-dom';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '90%', md: '70%' },
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const decisions = [
  { description: 'Sprzedaż', value: 'SALE' },
  { description: 'Naprawa', value: 'REPAIR' },
  { description: 'Sortowanie', value: 'SEGREGATION' },
  { description: 'Przesunięcie', value: 'MOVEMENT' },
  { description: 'Utylizacja', value: 'UTILIZATION' },
  // { description: 'Segregacja na linii', value: 'LINE_SEGREGATION' },
];

const warehouses = [
  { description: '8AAA' },
  { description: '8AAB' },
  { description: '8AAC' },
  { description: '8AAE' },
  { description: '8AAG' },
];

const qualifications = [
  { description: 'Manufacturing_Process' },
  { description: 'Manufacturing_Materials' },
  { description: 'Manufacturing_Products' },
  { description: 'NPD_Design' },
  { description: 'SC_Uncontrollable' },
  { description: 'Others' },
];

const DetailPZ05Form = ({ props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [attachments, setAttachments] = useState(
    props ? props.attachments : [],
  );
  const [decision, setDecision] = useState('');
  const [decisionDes, setDecisionDes] = useState('');
  const [warehouse, setWarehouse] = useState('');
  const [comments, setComments] = useState('');
  const [alert, setAlert] = useState(false);
  const [reasonCode, setReasonCode] = useState('');
  const [decisionStatus, setDecisionStatus] = useState('');
  const [error, setError] = useState('');
  const [updateStatus, setUpdateStatus] = useState('');
  const [qualification, setQualification] = useState('');
  const [errorQualification, setErrorQualification] = useState(false);
  const [updateValueStatus, setUpdateValueStatus] = useState('');
  const [backdropStatus, setBackdropStatus] = useState(false);
  const [deleteDecisionStatus, setDeleteDecisionStatus] = useState(false);
  const user = useSelector((state) => getUser(state));

  const handleCloseAlert = () => {
    setAlert(false);
  };

  const handleAttachments = async (e) => {
    e.preventDefault();
    const files = e.target.files;
    await dispatch(
      addAttachmentsToReportPZ05Request({ files, reportId: props.id }),
    );
  };

  useEffect(() => {
    const start = async () => {
      const res = await dispatch(loadReportPZ05ByIdRequest(props.id));
      setAttachments(res.data.attachments);
      let status = 0;
      if (
        res.data.statusReport !== 'DONE' &&
        res.data.reportDetails.decisions.length > 0
      ) {
        res.data.reportDetails.decisions.map((el) =>
          el.status === 'DONE' ? status++ : status,
        );
      }
      if (status > 0 && status === res.data.reportDetails.decisions.length) {
        const data = {
          id: props.id,
          statusReport: 'DONE',
          reportDetailsData: {
            id: props.reportDetails.id,
            statusDetail: 'DONE',
          },
        };
        await dispatch(updateReportPZ05Request(data));
      }
    };
    start();
  }, [attachments]);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleDecision = async () => {
    setError(false);
    setErrorQualification(false);
    setAlert(true);
    if (decision === '') {
      return setError(true);
    }
    if (decision === 'UTILIZATION' && qualification === '') {
      return setErrorQualification(true);
    }

    if (
      user.users.role === 'ADMIN' ||
      user.users.role === props.decisionMaker
    ) {
      const decisionData = {
        reportDetailId: props.reportDetails.id,
        userId: user.users.id,
        comments: comments,
        userRole: user.users.role,
        userAssignment: 'KONTROLER',
        activeDecision: decision,
        productId: props.productId,
        qty: props.reportDetails.qty,
        warehouseMove: warehouse !== '' ? warehouse : '',
        activeWarehouse: props.reportDetails.activeWarehouse,
        dateCreated: dateFormatterWithTime(new Date()),
        decisionDes: decisionDes,
        qualificationData: qualification !== '' ? qualification : null,
      };

      const decisionRes = await dispatch(
        createDecisionPZ05Request(decisionData),
      );
      setDecisionStatus(decisionRes.status);

      if (decisionRes.status === 201) {
        let uwolnienie = false;
        let utylizacja = false;
        let standard = false;

        if (decision === 'SALE') {
          uwolnienie = true;
        } else if (decision === 'UTILIZATION') {
          utylizacja = true;
        } else {
          standard = true;
        }
        await dispatch(
          sendUpdateMail({
            id: props.id, //dodalem to
            reportNumber: props.reportNumber,
            product: props.product,
            batch: props.reportDetails.batch,
            expiryDate: props.reportDetails.expiryDate,
            qty: props.reportDetails.qty,
            hlQty: props.reportDetails.hlQty,
            incompatibilitiesLists: props.incompatibilitiesLists,
            commentsFirst: props.comments,
            uwolnienie,
            utylizacja,
            standard,
            statusReport: true,
            warehouse: props.warehouse,
            ...decisionData,
          }),
        );
      }

      if (decisionRes.status === 201) {
        const data = {
          id: props.id,
          statusReport: 'IN_PROGRESS',
          decisionMaker: 'KONTROLER',
          reportDetailsData: {
            id: props.reportDetails.id,
            activeWarehouse: props.warehouse,
            assignment: 'KONTROLER',
            statusDetail: 'IN_PROGRESS',
          },
        };

        const res = await dispatch(updateReportPZ05Request(data));

        setReasonCode(res.status);
      }
    }
    if (reasonCode === 200 && decisionStatus === 201) {
      setModalOpen(false);
    }
    setModalOpen(false);
    setTimeout(() => {
      navigate(0);
    }, 2000);
  };

  const handleSubmit = async (data, qty) => {
    setAlert(true);
    let userAssignment = '';
    let status = '';
    if (
      data.activeDecision === 'RELEASED' ||
      data.activeDecision === 'DESTROYED'
    ) {
      status = 'DONE';
    } else {
      status = 'IN_PROGRESS';
    }
    if (
      (user.users.role === 'ADMIN' || user.users.role === 'KONTROLER') &&
      status !== 'DONE'
    ) {
      userAssignment = props.reportDetails.basicMaker;
    } else {
      userAssignment = 'KONTROLER';
    }

    const res = await dispatch(
      updateDecisionsPZ05Request({ status, userAssignment, ...data }),
    );

    const activeWarehouse = data.activeWarehouse;

    setUpdateStatus(res.status);

    if (res.status === 200) {
      const data = {
        id: props.id,
        statusReport: 'IN_PROGRESS',
        decisionMaker: userAssignment,
        reportDetailsData: {
          id: props.reportDetails.id,
          activeWarehouse: activeWarehouse,
          assignment: userAssignment,
          statusDetail: 'IN_PROGRESS',
        },
      };

      const res = await dispatch(updateReportPZ05Request(data));

      setReasonCode(res.status);
    }

    if (res.status === 200) {
      let uwolnienie = false;
      let utylizacja = false;
      let standard = false;
      let statusReport = false;

      if (
        data.activeDecision === 'SALE' ||
        data.activeDecision === 'RELEASED'
      ) {
        uwolnienie = true;
      } else if (
        data.activeDecision === 'UTILIZATION' ||
        data.activeDecision === 'DESTROYED'
      ) {
        utylizacja = true;
      } else {
        standard = true;
      }

      if (status !== 'DONE') {
        statusReport = true;
      }
      const { id, ...otherData } = data;
      await dispatch(
        sendUpdateMail({
          id: props.id,
          reportNumber: props.reportNumber,
          product: props.product,
          batch: props.reportDetails.batch,
          expiryDate: props.reportDetails.expiryDate,
          qty: qty,
          hlQty: props.reportDetails.hlQty,
          incompatibilitiesLists: props.incompatibilitiesLists,
          commentsFirst: props.comments,
          uwolnienie,
          utylizacja,
          standard,
          userAssignment:
            status === 'DONE' ? props.reportDetails.basicMaker : userAssignment,
          statusReport,
          warehouse: props.warehouse,
          ...otherData,
        }),
      );
    }

    if (res.status === 200) {
      setTimeout(() => {
        navigate(0);
      }, 2000);
    }
  };

  const handleSplitDecision = async (dataOne, dataTwo) => {
    await dispatch(createDecisionPZ05Request(dataTwo));
    await dispatch(updateValueDecisionPZ05Request(dataOne));

    setTimeout(() => {
      navigate(0);
    }, 500);
  };

  const generateRedCartPDF = async () => {
    setBackdropStatus(true);
    let wiek = false;
    if (props.incompatibilitiesLists.inconformity.description === 'Wiek') {
      wiek = true;
    }

    const data = {
      dateCreated: dateFormatter(props.createdAt),
      title: props.reportNumber,
      content: props.user.firstName,
      reason: props.incompatibilitiesLists.additionalDes.description,
      wiek: wiek,
      image: `${IMGS_URL}/logo2.jpg`,
      materialNumber: props.product.materialNumber,
      materialDescription: props.product.description,
      package: props.product.package.description,
      qty: props.reportDetails.qty,
      reportNumber: props.reportNumber,
      signature: `${props.user.firstName} ${props.user.lastName}`,
      batch: props.reportDetails.batch,
      expiryDate: dateFormatter(props.reportDetails.expiryDate),
    };
    await dispatch(generatePdfRequest(data));
    setBackdropStatus(false);
  };

  const generateRNCartPDF = async () => {
    setBackdropStatus(true);
    const decision = props.reportDetails.decisions.filter(
      (el) => el.activeWarehouse === user.users.warehouse,
    );
    const data = {
      image: `${IMGS_URL}/logo2.jpg`,
      warehouse: props.warehouse,
      dateCreated: dateFormatter(props.createdAt),
      reportNumber: props.reportNumber,
      product: `${props.product.materialNumber} ${props.product.description}`,
      package: props.product.package.description,
      expiryDate: dateFormatter(props.reportDetails.expiryDate),
      batch: props.reportDetails.batch,
      qty: props.reportDetails.qty,
      reason: props.incompatibilitiesLists.inconformity.description,
      desNonCompliance:
        props.incompatibilitiesLists.desNonCompliance.description,
      additionalDes: props.incompatibilitiesLists.additionalDes.description,
      decisionMaker: props.reportDetails.basicMaker,
      decisions: decision,
    };

    await dispatch(generateRNPdfRequest(data));
    setBackdropStatus(false);
  };

  const handleChangeValue = async (id, data) => {
    setAlert(true);
    if (props.reportDetails.decisions.length === 1) {
      const dataReport = {
        qty: data.qty,
        hlQty: (data.qty * props.product.casHL).toString(),
      };
      const resReport = await dispatch(
        updateValueReportsPZ05Request(props.id, dataReport),
      );

      const res = await dispatch(updateValueDecisionPZ05Request(data));
      setUpdateValueStatus(res.status);

      if (res.status === 200 && resReport.status === 200) {
        setTimeout(() => {
          navigate(0);
        }, 1000);
      }
    } else {
      let totalQty = parseFloat(data.qty);
      props.reportDetails.decisions.map((el) =>
        el.id !== id
          ? (totalQty = parseFloat(totalQty) + parseFloat(el.qty))
          : totalQty,
      );

      const dataReport = {
        qty: totalQty.toString(),
        hlQty: (totalQty * props.product.casHL).toString(),
      };

      const resReport = await dispatch(
        updateValueReportsPZ05Request(props.id, dataReport),
      );

      const res = await dispatch(updateValueDecisionPZ05Request(data));
      setUpdateValueStatus(res.status);

      if (res.status === 200 && resReport.status === 200) {
        setTimeout(() => {
          navigate(0);
        }, 1000);
      }
    }
  };

  const handleDeleteAction = async (data, decisionId) => {
    setAlert(true);

    data.id = props.id;

    const { decision, ...otherData } = data;

    if (decision === null) {
      if (props.reportDetails.decisions.length === 1) {
        otherData.statusReport = 'NEW';
        otherData.reportDetailsData.statusDetail = 'NEW';
      } else if (props.reportDetails.decisions.length > 1) {
        const result = props.reportDetails.decisions.filter(
          (el) => el.id !== decisionId && el.status !== 'DONE',
        );
        if (result.length > 0) {
          otherData.statusReport = 'IN_PROGRESS';
          otherData.reportDetailsData.statusDetail = 'IN_PROGRESS';
          otherData.decisionMaker = result[0].userRole;
        } else {
          otherData.statusReport = 'DONE';
          otherData.reportDetailsData.statusDetail = 'DONE';
          otherData.decisionMaker = 'KONTROLER';
        }
      }

      const res = await dispatch(updateReportPZ05Request(otherData));

      setDeleteDecisionStatus(res.status);
      setTimeout(() => {
        navigate(0);
      }, 1000);
    } else {
      const res2 = await dispatch(updateOnlyDecisionPZ05Request(decision));
      const res = await dispatch(updateReportPZ05Request(otherData));

      setDeleteDecisionStatus(res2.status);
      setTimeout(() => {
        navigate(0);
      }, 1000);
    }
  };

  // console.log(props);

  return (
    <Box sx={{ mt: { xs: 5, md: 2 }, ml: { xs: 2 }, mr: { xs: 2 } }}>
      {reasonCode === 200 && decisionStatus === 201 && (
        <Snackbar
          open={alert}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert
            variant="filled"
            onClose={handleCloseAlert}
            severity="success"
            sx={{ width: '100%' }}
          >
            <AlertTitle>Success</AlertTitle>
            Pomyślnie dodano działanie
          </Alert>
        </Snackbar>
      )}
      {updateStatus === 200 && (
        <Snackbar
          open={alert}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert
            variant="filled"
            onClose={handleCloseAlert}
            severity="success"
            sx={{ width: '100%' }}
          >
            <AlertTitle>Success</AlertTitle>
            Pomyślnie dodano działanie
          </Alert>
        </Snackbar>
      )}
      {deleteDecisionStatus === 200 && (
        <Snackbar
          open={alert}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert
            variant="filled"
            onClose={handleCloseAlert}
            severity="success"
            sx={{ width: '100%' }}
          >
            <AlertTitle>Success</AlertTitle>
            Pomyślnie usunięto działanie
          </Alert>
        </Snackbar>
      )}
      {updateValueStatus === 200 && (
        <Snackbar
          open={alert}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert
            variant="filled"
            onClose={handleCloseAlert}
            severity="success"
            sx={{ width: '100%' }}
          >
            <AlertTitle>Success</AlertTitle>
            Pomyślnie zmieniono ilość
          </Alert>
        </Snackbar>
      )}
      {error && (
        <Snackbar
          open={alert}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert
            variant="filled"
            onClose={handleCloseAlert}
            severity="error"
            sx={{ width: '100%' }}
          >
            <AlertTitle>Error</AlertTitle>
            Uzupełnij wymagane pola
          </Alert>
        </Snackbar>
      )}
      {errorQualification && (
        <Snackbar
          open={alert}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert
            variant="filled"
            onClose={handleCloseAlert}
            severity="error"
            sx={{ width: '100%' }}
          >
            <AlertTitle>Brak kwalifikacji</AlertTitle>W przypadku wyboru decyzji
            utylizacja należy wybrać kwalifikację
          </Alert>
        </Snackbar>
      )}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropStatus}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <Typography
          sx={{ fontStyle: 'italic' }}
          variant="h5"
          component="h2"
          gutterBottom
        >
          {`Raport niezgodności: ${props.reportNumber}`}
        </Typography>
        <Box>
          <Button
            sx={{ mr: 1 }}
            variant="contained"
            color="error"
            onClick={generateRedCartPDF}
            startIcon={<PictureAsPdfIcon />}
          >
            Czerwona kartka
          </Button>
          <Button
            variant="contained"
            startIcon={<PictureAsPdfIcon />}
            onClick={generateRNCartPDF}
          >
            Raport niezgodności
          </Button>
        </Box>
      </Box>

      <Divider sx={{ width: '100%', mb: 3, bgcolor: '#C09A53' }} />
      <Typography
        variant="h6"
        component="h2"
        gutterBottom
        sx={{ fontWeight: 'bold' }}
      >
        Dane podstawowe:
      </Typography>
      <Typography variant="body1" gutterBottom>
        Data wystawienia raportu:{' '}
        <Typography
          component="span"
          sx={{ fontWeight: 'bold', fontStyle: 'italic' }}
        >
          {dateFormatter(props.createdAt)}
        </Typography>
      </Typography>
      <Typography variant="body1" gutterBottom>
        Magazyn źródłowy:{' '}
        <Typography
          component="span"
          sx={{ fontWeight: 'bold', fontStyle: 'italic' }}
        >
          {props.warehouse}
        </Typography>
      </Typography>
      <Typography variant="body1" gutterBottom>
        Użytkownik:{' '}
        <Typography
          component="span"
          sx={{ fontWeight: 'bold', fontStyle: 'italic' }}
        >
          {`${props.user.firstName} ${props.user.lastName}`}
        </Typography>
      </Typography>

      <Divider sx={{ width: '100%', mt: 1, mb: 3, bgcolor: '#C09A53' }} />
      <Box
        sx={{
          display: { xs: 'block', md: 'flex' },
        }}
      >
        <Box sx={{ width: { xs: '100%', md: '75%' } }}>
          <Typography
            sx={{
              fontWeight: 'bold',
            }}
            variant="h6"
            component="h2"
            gutterBottom
          >
            Szczegóły niezgodności:
          </Typography>
          <Typography variant="body1" gutterBottom>
            Pochodzenie produktu:{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'bold', fontStyle: 'italic' }}
            >
              {props.incompatibilitiesLists.source.description}
            </Typography>
          </Typography>
          <Typography variant="body1" gutterBottom>
            Rodzaj niezgodności:{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'bold', fontStyle: 'italic' }}
            >
              {props.incompatibilitiesLists.inconformity.description}
            </Typography>
          </Typography>
          <Typography variant="body1" gutterBottom>
            Obszar niezgodności:{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'bold', fontStyle: 'italic' }}
            >
              {props.incompatibilitiesLists.area.description}
            </Typography>
          </Typography>
          <Typography variant="body1" gutterBottom>
            Opis niezgodności:{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'bold', fontStyle: 'italic' }}
            >
              {props.incompatibilitiesLists.desNonCompliance.description}
            </Typography>
          </Typography>
          <Typography variant="body1" gutterBottom>
            Dodatkowy opis:{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'bold', fontStyle: 'italic' }}
            >
              {props.incompatibilitiesLists.additionalDes.description}
            </Typography>
          </Typography>
          <Typography variant="body1" gutterBottom>
            Komentarz:{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'bold', fontStyle: 'italic' }}
            >
              {props.comments}
            </Typography>
          </Typography>

          <Typography variant="body1" gutterBottom>
            Status:{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'bold', fontStyle: 'italic' }}
            >
              {changeStatusName(props.statusReport)}
            </Typography>
          </Typography>
        </Box>
        <Box
          sx={{
            width: { xs: '100%', md: '25%' },
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            sx={{
              fontWeight: 'bold',
            }}
            variant="h6"
            component="h2"
            gutterBottom
          >
            Załączniki:
          </Typography>
          {attachments.length > 0 &&
            attachments.map((el) => (
              <a
                key={el.fileName}
                href={`${ATT_URL}/${props.id}/${el.fileName}`}
                target="_blank"
                rel="noreferrer"
              >
                {el.fileName}
              </a>
            ))}
          {(user.users.role === 'KONTROLER' || user.users.role === 'ADMIN') && (
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              sx={{ mt: 2 }}
            >
              Dodaj załącznik
              <VisuallyHiddenInput
                type="file"
                multiple
                onChange={handleAttachments}
              />
            </Button>
          )}
        </Box>
      </Box>
      <Divider sx={{ width: '100%', mt: 3, mb: 3, bgcolor: '#C09A53' }} />
      <Typography
        variant="h6"
        component="h2"
        gutterBottom
        sx={{ fontWeight: 'bold' }}
      >
        Dane produktu:
      </Typography>
      <Typography variant="body1" gutterBottom>
        Indeks:{' '}
        <Typography
          component="span"
          sx={{ fontWeight: 'bold', fontStyle: 'italic' }}
        >
          {props.product.materialNumber}
        </Typography>
      </Typography>
      <Typography variant="body1" gutterBottom>
        Nazwa:{' '}
        <Typography
          component="span"
          sx={{ fontWeight: 'bold', fontStyle: 'italic' }}
        >
          {props.product.description}
        </Typography>
      </Typography>

      <Typography variant="body1" gutterBottom>
        Data przydatności:{' '}
        <Typography
          component="span"
          sx={{ fontWeight: 'bold', fontStyle: 'italic' }}
        >
          {dateFormatter(props.reportDetails.expiryDate)}
        </Typography>
      </Typography>
      <Typography variant="body1" gutterBottom>
        Partia:{' '}
        <Typography
          component="span"
          sx={{ fontWeight: 'bold', fontStyle: 'italic' }}
        >
          {props.reportDetails.batch}
        </Typography>
      </Typography>
      <Typography variant="body1" gutterBottom>
        Ilość cas:{' '}
        <Typography
          component="span"
          sx={{ fontWeight: 'bold', fontStyle: 'italic' }}
        >
          {props.reportDetails.qty}
        </Typography>
      </Typography>
      <Typography variant="body1" gutterBottom>
        Ilość HL:{' '}
        <Typography
          component="span"
          sx={{ fontWeight: 'bold', fontStyle: 'italic' }}
        >
          {parseFloat(props.reportDetails.hlQty).toFixed(2)}
        </Typography>
      </Typography>
      <Divider
        sx={{
          width: '100%',
          mt: 3,
          mb: 3,
          bgcolor: '#C09A53',
        }}
      />
      <Typography
        variant="h6"
        component="h2"
        gutterBottom
        sx={{ fontWeight: 'bold' }}
      >
        Działania:
      </Typography>
      {(user.users.role === props.reportDetails.basicMaker &&
        props.statusReport === 'NEW') ||
      user.users.role === 'ADMIN' ? (
        <Button
          variant="contained"
          sx={{ mt: 2, bgcolor: '#009900', ':hover': { bgcolor: '#006600' } }}
          onClick={handleModalOpen}
        >
          Rozpocznij działanie
        </Button>
      ) : (
        ''
      )}

      {user.users.role === 'KONTROLER' || user.users.role === 'KIER_ODC_BWH'
        ? props.reportDetails.decisions.length > 0 &&
          props.reportDetails.decisions.map((el) =>
            el.activeWarehouse === user.users.warehouse ? (
              <DecisionForm
                key={el.id}
                action={handleSubmit}
                action2={handleSplitDecision}
                props={el}
              />
            ) : (
              ''
            ),
          )
        : user.users.role === 'ADMIN' ||
          user.users.role === 'KIER_DS_REKLAMACJI' ||
          user.users.role === 'WOM' ||
          user.users.role === 'KIER_SYST_ZAPEWNIENIA_JAK' ||
          user.users.role === 'LIDER_PRZEPAKOWANIA'
        ? props.reportDetails.decisions.length > 0 &&
          props.reportDetails.decisions.map((el) => (
            <DecisionForm
              key={el.id}
              action={handleSubmit}
              action2={handleSplitDecision}
              action3={handleChangeValue}
              deleteAction={handleDeleteAction}
              props={el}
            />
          ))
        : ''}

      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display="flex">
            <Autocomplete
              disablePortal
              id="combo-decisions"
              options={decisions.map((el) => ({
                id: el.value,
                label: el.description,
              }))}
              sx={{ width: '30%', m: 1 }}
              value={decisionDes}
              onChange={(e, value) => (
                setDecision(value ? value.id : ''),
                setDecisionDes(value ? value.label : '')
              )}
              renderInput={(params) => (
                <TextField required {...params} label="Decyzja" />
              )}
            />
            {decisionDes === 'Przesunięcie' && (
              <Autocomplete
                disablePortal
                id="combo-warehouses"
                options={warehouses.map((el) => ({
                  label: el.description,
                }))}
                sx={{ width: '20%', m: 1 }}
                value={warehouse}
                onChange={(e, value) => setWarehouse(value ? value.label : '')}
                renderInput={(params) => (
                  <TextField required {...params} label="Magazyn" />
                )}
              />
            )}
            {decisionDes === 'Utylizacja' && (
              <Autocomplete
                disablePortal
                id="combo-qualifications"
                options={qualifications.map((el) => ({
                  label: el.description,
                }))}
                sx={{ width: '20%', m: 1 }}
                value={qualification}
                onChange={(e, value) =>
                  setQualification(value ? value.label : '')
                }
                renderInput={(params) => (
                  <TextField required {...params} label="Kwalifikacja" />
                )}
              />
            )}
            <TextField
              variant="outlined"
              sx={{ width: '50%', m: 1 }}
              label="Komentarz"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button
              sx={{
                mt: 2,
                ml: 2,
                color: 'white',
                bgcolor: '#C09A53',
                ':hover': { bgcolor: '#9c7c43' },
              }}
              onClick={handleDecision}
            >
              Zatwierdź
            </Button>
            <Button
              sx={{
                mt: 2,
                bgcolor: '#FF0000',
                ':hover': { bgcolor: '#CC0000' },
              }}
              variant="contained"
              onClick={handleModalClose}
            >
              Zamknij
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default DetailPZ05Form;
