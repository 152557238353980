import { Box, Button, Divider, TextField } from '@mui/material';
import { useState } from 'react';

const ComplainsForm = ({ action, actionText, buttonText, ...props }) => {
  const [description, setDescription] = useState(props.description || '');

  const handleSubmit = () => {
    action({ description });
    setDescription('');
  };

  return (
    <Box sx={{ width: '100%' }}>
      <h1>{`Formularz ${actionText}`} </h1>
      <Divider sx={{ width: '100%', mb: 3, bgcolor: '#C09A53' }} />
      <Box
        sx={{
          display: { xs: 'flex', md: 'flex' },
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { xs: 'center', md: 'space-evenly' },
        }}
      >
        <TextField
          sx={{
            xs: { display: 'block', width: '100%' },
            md: { display: 'flex' },
            width: '70%',
          }}
          variant="standard"
          label="Opis"
          required
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Button
          sx={{
            bgcolor: '#C09A53',
            color: 'white',
            ':hover': { bgcolor: '#9c7c43' },
            width: '150px',
            height: '50px',
            mt: { xs: 3, md: 0 },
          }}
          onClick={handleSubmit}
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
};

export default ComplainsForm;
