import axios from 'axios';
import { API_URL } from '../config';
import initialState from './initialState';
import { Warehouse } from '@mui/icons-material';

export const getReportsPZ05 = ({ reportPZ05 }) => reportPZ05;

const reducerName = 'reportsPZ05';
const createActionName = (name) => `app/${reducerName}/${name}`;

const LOAD_REPORTS_PZ05 = createActionName('LOAD_REPORTS_PZ05');
const ADD_REPORT_PZ05 = createActionName('ADD_REPORT_PZ05');
const EDIT_REPORT_PZ05 = createActionName('EDIT_REPORT_PZ05');
const DELETE_REPORT_PZ05 = createActionName('DELETE_REPORT_PZ05');

export const loadReportsPZ05 = (payload) => ({
  payload,
  type: LOAD_REPORTS_PZ05,
});
export const addReportPZ05 = (payload) => ({ payload, type: ADD_REPORT_PZ05 });
export const editReportPZ05 = (payload) => ({
  payload,
  type: EDIT_REPORT_PZ05,
});
export const deleteReportPZ05 = (payload) => ({
  payload,
  type: DELETE_REPORT_PZ05,
});

export const loadReportsPZ05Request = () => {
  return async (dispatch) => {
    try {
      let res = await axios.get(`${API_URL}/reports-pz05`, {
        withCredentials: true,
      });

      dispatch(loadReportsPZ05(res.data));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const loadReportsPZ05ByUserWarehouse = (activeWarehouse) => {
  return async (dispatch) => {
    try {
      let res = await axios.get(
        `${API_URL}/reports-pz05/user/${activeWarehouse}`,
        {
          withCredentials: true,
        },
      );

      dispatch(loadReportsPZ05(res.data));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const loadReportsPZ05ByUserRole = (userRole) => {
  return async (dispatch) => {
    try {
      let res = await axios.get(`${API_URL}/reports-pz05/role/${userRole}`, {
        withCredentials: true,
      });

      dispatch(loadReportsPZ05(res.data));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const loadReportPZ05ByIdRequest = (id) => {
  return async (dispatch) => {
    try {
      let res = await axios.get(`${API_URL}/reports-pz05/${id}`, {
        withCredentials: true,
      });

      // dispatch(loadReportsPZ05(res.data));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const loadReportPZ05ByQualityRequest = () => {
  return async (dispatch) => {
    try {
      let res = await axios.get(`${API_URL}/reports-pz05/quality`, {
        withCredentials: true,
      });
      dispatch(loadReportsPZ05(res.data));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const addReportPZ05Request = (data) => {
  return async (dispatch) => {
    try {
      let res = await axios.post(`${API_URL}/reports-pz05`, data, {
        withCredentials: true,
      });

      return res;
    } catch (e) {
      return e.response;
    }
  };
};

export const sendCreateMail = (id) => {
  return async (dispatch) => {
    try {
      let res = await axios.post(`${API_URL}/reports-pz05/email/create`, id, {
        withCredentials: true,
      });

      return res;
    } catch (e) {
      return e.response;
    }
  };
};

export const sendUpdateMail = (data) => {
  return async (dispatch) => {
    try {
      let res = await axios.post(`${API_URL}/reports-pz05/email/update`, data, {
        withCredentials: true,
      });

      return res;
    } catch (e) {
      return e.response;
    }
  };
};

export const updateReportPZ05Request = (data) => {
  return async (dispatch) => {
    try {
      let res = await axios.put(
        `${API_URL}/reports-pz05/status/${data.id}`,
        data,
        {
          withCredentials: true,
        },
      );

      return res;
    } catch (e) {
      return e.response;
    }
  };
};

export const createDecisionPZ05Request = (decisionData) => {
  return async (dispatch) => {
    try {
      let res = await axios.post(`${API_URL}/decisions`, decisionData, {
        withCredentials: true,
      });

      return res;
    } catch (e) {
      return e.response;
    }
  };
};

export const deleteDecisionPZ05Request = (id) => {
  return async (dispatch) => {
    try {
      let res = await axios.delete(`${API_URL}/decisions/${id}`, {
        withCredentials: true,
      });
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const deleteActionPZ05Request = (id) => {
  return async (dispatch) => {
    try {
      let res = await axios.delete(`${API_URL}/actions/${id}`, {
        withCredentials: true,
      });
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const updateOnlyDecisionPZ05Request = (decisionData) => {
  return async (dispatch) => {
    try {
      let res = await axios.put(
        `${API_URL}/decisions/only/${decisionData.id}`,
        decisionData,
        {
          withCredentials: true,
        },
      );
      return res;
    } catch (e) {
      return e.response;
    }
  };
};

export const generatePdfRequest = (dataToPDF) => {
  return async (dispatch) => {
    try {
      await axios
        .post(
          `${API_URL}/pdf-creator`,

          {
            context: {
              imageUrl: dataToPDF.image,
              dateCreated: dataToPDF.dateCreated,
              reason: dataToPDF.reason,
              wiek: dataToPDF.wiek,
              title: dataToPDF.title,
              content: dataToPDF.content,
              materialNumber: dataToPDF.materialNumber,
              materialDescription: dataToPDF.materialDescription,
              package: dataToPDF.package,
              qty: dataToPDF.qty,
              reportNumber: dataToPDF.reportNumber,
              signature: dataToPDF.signature,
              batch: dataToPDF.batch,
              expiryDate: dataToPDF.expiryDate,
            },
          },

          { responseType: 'blob' },
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));

          const a = document.createElement('a');
          a.href = url;
          a.download = dataToPDF.reportNumber + '.pdf';
          document.body.appendChild(a);

          a.click();

          window.URL.revokeObjectURL(url);
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const generateRNPdfRequest = (dataToPDF) => {
  return async (dispatch) => {
    try {
      console.log(dataToPDF);
      await axios
        .post(
          `${API_URL}/pdf-creator/report`,

          {
            context: {
              imageUrl: dataToPDF.image,
              dateCreated: dataToPDF.dateCreated,
              warehouse: dataToPDF.warehouse,
              reportNumber: dataToPDF.reportNumber,
              product: dataToPDF.product,
              package: dataToPDF.package,
              expiryDate: dataToPDF.expiryDate,
              batch: dataToPDF.batch,
              qty: dataToPDF.qty,
              reason: dataToPDF.reason,
              desNonCompliance: dataToPDF.desNonCompliance,
              additionalDes: dataToPDF.additionalDes,
              decisionMaker: dataToPDF.decisionMaker,
              decisions: dataToPDF.decisions,
            },
          },

          { responseType: 'blob' },
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));

          const a = document.createElement('a');
          a.href = url;
          a.download = `Raport niezgodności ${dataToPDF.reportNumber}.pdf`;
          document.body.appendChild(a);

          a.click();
          console.log(url);

          window.URL.revokeObjectURL(url);
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateValueReportsPZ05Request = (id, data) => {
  return async (dispatch) => {
    try {
      let res = await axios.put(`${API_URL}/reports-pz05/value/${id}`, data, {
        withCredentials: true,
      });

      return res;
    } catch (e) {
      return e.response;
    }
  };
};

export const updateValueDecisionPZ05Request = (valueData) => {
  return async (dispatch) => {
    try {
      let res = await axios.put(
        `${API_URL}/decisions/value/${valueData.id}`,
        valueData,
        {
          withCredentials: true,
        },
      );

      return res;
    } catch (e) {
      return e.response;
    }
  };
};
export const addAttachmentsToReportPZ05Request = (data) => {
  return async (dispatch) => {
    try {
      const { files, ...otherData } = data;
      const fd = new FormData();
      for (let i = 0; i < files.length; i++) {
        fd.append('files', files[i]);
      }
      fd.append('reportId', otherData.reportId);
      let res = await axios.post(`${API_URL}/attachments`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return res;
    } catch (e) {
      return e.response;
    }
  };
};

export const editReportPZ05Request = (data) => {
  return async (dispatch) => {
    try {
      let res = await axios.put(`${API_URL}/reports-pz05/${data.id}`, data, {
        withCredentials: true,
      });

      return res;
    } catch (e) {
      return e.message.status;
    }
  };
};

export const updateDecisionsPZ05Request = (decisionData) => {
  return async (dispatch) => {
    try {
      let res = await axios.put(
        `${API_URL}/decisions/${decisionData.id}`,
        decisionData,
        {
          withCredentials: true,
        },
      );

      return res;
    } catch (e) {
      return e.response;
    }
  };
};

export const generateRaportPZ05 = (data) => {
  return async (dispatch) => {
    try {
      let res = await axios.post(`${API_URL}/reports-pz05/reports`, data, {
        withCredentials: true,
        responseType: 'arraybuffer',
      });

      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      // Tworzenie linku do pobrania
      const url = window.URL.createObjectURL(blob);

      // Tworzenie linku do pobrania i kliknięcie w niego (rozpoczyna pobieranie)
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Raport_PZ05.xlsx';
      a.click();

      // Zwolnij zasoby URL
      window.URL.revokeObjectURL(url);
      // return res;
    } catch (e) {
      return e.message.status;
    }
  };
};

export const deleteReportPZ05Request = (id) => {
  return async (dispatch) => {
    try {
      let res = await axios.delete(`${API_URL}/reports-pz05/${id}`, {
        withCredentials: true,
      });
      dispatch(deleteReportPZ05(id));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

const reportsPZ05Reducer = (statePart = initialState, action) => {
  switch (action.type) {
    case ADD_REPORT_PZ05:
      return [...statePart, action.payload];
    case EDIT_REPORT_PZ05:
      return statePart.map((el) =>
        el.id === action.payload.id ? action.payload : el,
      );
    case DELETE_REPORT_PZ05:
      return [...statePart.filter((el) => el.id !== action.payload)];
    case LOAD_REPORTS_PZ05:
      return action.payload;
    default:
      return statePart;
  }
};

export default reportsPZ05Reducer;
