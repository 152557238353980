import { useDispatch } from 'react-redux';

import ComplainsForm from '../../common/ComplainsForm/ComplainsForm';

import { useState } from 'react';
import { Snackbar, Alert, AlertTitle } from '@mui/material';
import { addAdditionalDesRequest } from '../../../redux/additionalDesRedux';
const AddAdditionalDesForm = () => {
  const dispatch = useDispatch();

  const [requestStatus, setRequestStatus] = useState('');
  const [alert, setAlert] = useState(false);
  const handleSubmit = async (data) => {
    setAlert(true);
    const { description } = data;

    if (description === '') {
      setRequestStatus(400);
    } else {
      const res = await dispatch(addAdditionalDesRequest(data));
      if (res.status === 201) {
        setRequestStatus(201);
      } else if (res.status === 409) {
        setRequestStatus(409);
      } else if (res.status === 500) {
        setRequestStatus(500);
      }
    }
  };

  const handleClose = () => {
    setAlert(false);
  };
  return (
    <>
      {requestStatus === 201 && (
        <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            variant="filled"
            onClose={handleClose}
            severity="success"
            sx={{ width: '100%' }}
          >
            <AlertTitle>Success</AlertTitle>
            Pomyślnie dodano
          </Alert>
        </Snackbar>
      )}
      {requestStatus === 409 && (
        <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            variant="filled"
            onClose={handleClose}
            severity="warning"
            sx={{ width: '100%' }}
          >
            <AlertTitle>Warning</AlertTitle>
            Dane juz istnieją
          </Alert>
        </Snackbar>
      )}
      {requestStatus === 500 && (
        <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            variant="filled"
            onClose={handleClose}
            severity="error"
            sx={{ width: '100%' }}
          >
            <AlertTitle>Warning</AlertTitle>
            Błąd serwera
          </Alert>
        </Snackbar>
      )}
      {requestStatus === 400 && (
        <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            variant="filled"
            onClose={handleClose}
            severity="error"
            sx={{ width: '100%' }}
          >
            <AlertTitle>Warning</AlertTitle>
            Uzupełnij wszystkie wymagane pola
          </Alert>
        </Snackbar>
      )}
      <ComplainsForm
        action={handleSubmit}
        actionText="tworzenia"
        buttonText="Zatwierdź"
      />
    </>
  );
};

export default AddAdditionalDesForm;
