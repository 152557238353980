import { Box, Divider } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

import { useEffect } from 'react';

import ListSingleForm from '../../common/ListSingleForm/ListSingleForm';
import {
  deleteAreaRequest,
  getAreas,
  loadAreasRequest,
} from '../../../redux/areasRedux';
import AddAreaForm from '../../features/AddAreaForm/AddAreaForm';

const AddArea = () => {
  const dispatch = useDispatch();
  const areas = useSelector((state) => getAreas(state));

  const handleSubmit = async (id) => {
    await dispatch(deleteAreaRequest(id));
  };

  useEffect(() => {
    const start = async () => {
      await dispatch(loadAreasRequest());
    };

    start();
  }, [dispatch]);

  return (
    <Box>
      <AddAreaForm />
      <Divider sx={{ mt: 5 }} />
      {areas.length > 0 && (
        <ListSingleForm
          action={handleSubmit}
          props={areas}
          text="Obszar niezgodności"
          link="edit-area"
        />
      )}
    </Box>
  );
};

export default AddArea;
