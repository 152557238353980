import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loadBrandsRequest } from '../../../redux/brandsRedux';
import { loadPackagesRequest } from '../../../redux/packagesRedux';
import {
  Box,
  Divider,
  Snackbar,
  Alert,
  AlertTitle,
  TextField,
  Autocomplete,
  Button,
} from '@mui/material';

const BeerForm = ({ props, action, actionText, buttonText }) => {
  const dispatch = useDispatch();
  const [packages, setPackages] = useState('');
  const [brands, setBrands] = useState('');

  const [brandId, setBrandId] = useState(props ? props.brand.id : '');
  const [brandDes, setBrandDes] = useState(
    props ? props.brand.description : '',
  );
  const [packageId, setPackageId] = useState(props ? props.package.id : '');
  const [packageDes, setPackageDes] = useState(
    props ? props.package.description : '',
  );
  const [materialNumber, setMaterialNumber] = useState(
    props ? props.materialNumber : '',
  );
  const [description, setDescription] = useState(
    props ? props.description : '',
  );
  const [shelfLife, setShelfLife] = useState(props ? props.shelfLife : '');
  const [palletQty, setPalletQty] = useState(props ? props.palletQty : '');
  const [casHL, setCasHL] = useState(props ? props.casHL : '');

  const [alert, setAlert] = useState(false);

  useEffect(() => {
    const start = async () => {
      const packages = await dispatch(loadPackagesRequest());
      setPackages(packages.data);
      const brands = await dispatch(loadBrandsRequest());
      setBrands(brands.data);
    };
    start();
  }, [dispatch]);

  const handleClose = () => {
    setAlert(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      materialNumber === '' ||
      description === '' ||
      brandId === '' ||
      packageId === '' ||
      shelfLife === '' ||
      palletQty === '' ||
      casHL === ''
    ) {
      setAlert(true);
    } else {
      const data = {
        materialNumber: parseInt(materialNumber),
        description,
        brandId,
        packageId,
        shelfLife: parseInt(shelfLife),
        palletQty: parseInt(palletQty),
        casHL: toString(casHL).includes(',')
          ? parseFloat(casHL.replace(',', '.'))
          : parseFloat(casHL),
      };
      action(data);
    }
  };
  return (
    <Box sx={{ width: '100%' }}>
      <h1>{`Formularz ${actionText}`}</h1>
      <Divider sx={{ width: '100%', mb: 3, bgcolor: '#C09A53' }} />
      <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="error"
          sx={{ width: '100%' }}
        >
          <AlertTitle>Warning</AlertTitle>
          Uzupełnij wszystkie wymagane pola
        </Alert>
      </Snackbar>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: { xs: 'flex', md: 'flex' },
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
            m: { md: 2 },
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TextField
            sx={{ mb: 2 }}
            label="Indeks"
            variant="outlined"
            required
            value={materialNumber}
            onChange={(e) => setMaterialNumber(e.target.value)}
          />
          <TextField
            sx={{ mb: 2 }}
            label="Nazwa materiału"
            variant="outlined"
            required
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {brands.length > 0 && (
            <Autocomplete
              disablePortal
              id="combo-brands"
              options={brands.map((el) => ({
                id: el.id,
                label: el.description,
              }))}
              value={brandDes}
              sx={{ width: '100%', mb: 2 }}
              onChange={(e, value) => (
                setBrandId(value ? value.id : ''),
                setBrandDes(value ? value.label : '')
              )}
              renderInput={(params) => (
                <TextField {...params} label="Marka produktu" required />
              )}
            />
          )}
          {packages.length > 0 && (
            <Autocomplete
              disablePortal
              id="combo-packages"
              options={packages.map((el) => ({
                id: el.id,
                label: el.description,
              }))}
              sx={{ width: '100%', mb: 2 }}
              value={packageDes}
              onChange={(e, value) => (
                setPackageId(value ? value.id : ''),
                setPackageDes(value ? value.label : '')
              )}
              renderInput={(params) => (
                <TextField {...params} label="Opakowanie" required />
              )}
            />
          )}
        </Box>
        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
            m: { md: 2 },
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TextField
            sx={{ mb: 2 }}
            label="Trwałość"
            variant="outlined"
            type="number"
            required
            value={shelfLife}
            onChange={(e) => setShelfLife(e.target.value)}
          />
          <TextField
            sx={{ mb: 2 }}
            label="Ilość/PAL"
            variant="outlined"
            type="number"
            required
            value={palletQty}
            onChange={(e) => setPalletQty(e.target.value)}
          />
          <TextField
            sx={{ mb: 2 }}
            label="HL/cas"
            variant="outlined"
            required
            value={casHL}
            onChange={(e) => setCasHL(toString(e.target.value))}
          />
          <Button
            sx={{
              color: 'white',
              bgcolor: '#C09A53',
              ':hover': { bgcolor: '#9c7c43' },
              height: '56px',
            }}
            type="submit"
          >
            {buttonText}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default BeerForm;
