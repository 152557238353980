import { Box, Divider } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

import { useEffect } from 'react';

import ListSingleForm from '../../common/ListSingleForm/ListSingleForm';

import {
  deletePackageRequest,
  getPackages,
  loadPackagesRequest,
} from '../../../redux/packagesRedux';
import AddPackageForm from '../../features/AddPackageForm/AddPackageForm';

const AddPackage = () => {
  const dispatch = useDispatch();
  const packages = useSelector((state) => getPackages(state));

  const handleSubmit = async (id) => {
    await dispatch(deletePackageRequest(id));
  };

  useEffect(() => {
    const start = async () => {
      await dispatch(loadPackagesRequest());
    };

    start();
  }, [dispatch]);

  return (
    <Box>
      <AddPackageForm />
      <Divider sx={{ mt: 5 }} />
      {packages.length > 0 && (
        <ListSingleForm
          action={handleSubmit}
          props={packages}
          text="Opakowanie"
          link="edit-package"
        />
      )}
    </Box>
  );
};

export default AddPackage;
