import axios from 'axios';
import { API_URL } from '../config';
import initialState from './initialState';

export const getDesNon = ({ desNon }) => desNon;
// export const getAreaById = ({ area }, id) => area.find((el) => el.id === id);

const reducerName = 'desNons';
const createActionName = (name) => `app/${reducerName}/${name}`;

const LOAD_DES_NON = createActionName('LOAD_DES_NON');
const ADD_DES_NON = createActionName('ADD_DES_NON');
const EDIT_DES_NON = createActionName('EDIT_DES_NON');
const DELETE_DES_NON = createActionName('DELETE_DES_NON');

export const loadDesNon = (payload) => ({ payload, type: LOAD_DES_NON });
export const addDesNon = (payload) => ({ payload, type: ADD_DES_NON });
export const editDesNon = (payload) => ({ payload, type: EDIT_DES_NON });
export const deleteDesNon = (payload) => ({ payload, type: DELETE_DES_NON });

export const loadDesNonRequest = () => {
  return async (dispatch) => {
    try {
      let res = await axios.get(`${API_URL}/des-non-compliances`, {
        withCredentials: true,
      });

      dispatch(loadDesNon(res.data));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const loadDesNonByIdRequest = (id) => {
  return async (dispatch) => {
    try {
      let res = await axios.get(`${API_URL}/des-non-compliances/${id}`, {
        withCredentials: true,
      });

      dispatch(loadDesNon(res.data));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const addDesNonRequest = (data) => {
  return async (dispatch) => {
    try {
      let res = await axios.post(`${API_URL}/des-non-compliances`, data, {
        withCredentials: true,
      });
      dispatch(addDesNon(res.data));
      return res;
    } catch (e) {
      return e.response;
    }
  };
};

export const editDesNonRequest = (data) => {
  return async (dispatch) => {
    try {
      let res = await axios.put(
        `${API_URL}/des-non-compliances/${data.id}`,
        data,
        {
          withCredentials: true,
        },
      );
      dispatch(editDesNon(res.data));
    } catch (e) {
      return e.message.status;
    }
  };
};

export const deleteDesNonRequest = (id) => {
  return async (dispatch) => {
    try {
      let res = await axios.delete(`${API_URL}/des-non-compliances/${id}`, {
        withCredentials: true,
      });
      dispatch(deleteDesNon(id));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

const desNonsReducer = (statePart = initialState, action) => {
  switch (action.type) {
    case ADD_DES_NON:
      return [...statePart, action.payload];
    case EDIT_DES_NON:
      return statePart.map((el) =>
        el.id === action.payload.id ? action.payload : el,
      );
    case DELETE_DES_NON:
      return [...statePart.filter((el) => el.id !== action.payload)];
    case LOAD_DES_NON:
      return action.payload;
    default:
      return statePart;
  }
};

export default desNonsReducer;
