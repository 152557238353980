import { Box, Divider } from '@mui/material';
import AddSourceForm from '../../features/AddSourceForm/AddSourceForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteSourceRequest,
  getSources,
  loadSourcesRequest,
} from '../../../redux/sourcesRedux';
import { useEffect } from 'react';

import ListSingleForm from '../../common/ListSingleForm/ListSingleForm';

const AddSource = () => {
  const dispatch = useDispatch();
  const sources = useSelector((state) => getSources(state));

  const handleSubmit = async (id) => {
    await dispatch(deleteSourceRequest(id));
  };

  useEffect(() => {
    const start = async () => {
      await dispatch(loadSourcesRequest());
    };

    start();
  }, [dispatch]);

  return (
    <Box>
      <AddSourceForm />
      <Divider sx={{ mt: 5 }} />
      {sources.length > 0 && (
        <ListSingleForm
          action={handleSubmit}
          props={sources}
          text="Pochodzenie produktu"
          link="edit-source"
        />
      )}
    </Box>
  );
};

export default AddSource;
