import {
  Autocomplete,
  Box,
  Button,
  Divider,
  TextField,
  Snackbar,
  Alert,
  AlertTitle,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadSourcesRequest } from '../../../redux/sourcesRedux';
import { loadReasonsRequest } from '../../../redux/reasonRedux';
import { getBeers, loadBeersRequest } from '../../../redux/beersRedux';
import { checkLoggedUser, getUser } from '../../../redux/usersRedux';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/pl';
import { DatePicker } from '@mui/x-date-pickers';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { generateExpirationDateFromBatch } from '../../../utils/generateExpirationDateFromBatch';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const ReportForm = ({ action, actionText, props }) => {
  const dispatch = useDispatch();

  const [reasons, setReasons] = useState('');
  const beers = useSelector((state) => getBeers(state));
  const user = useSelector((state) => getUser(state));
  const [source, setSource] = useState('');
  const [sourceDes, setSourceDes] = useState(
    props ? props.incompatibilitiesLists.source.description : '',
  );
  const [sourceId, setSourceId] = useState(
    props ? props.incompatibilitiesLists.source.id : '',
  );

  const [inconformity, setInconformity] = useState('');
  const [inconformityDes, setInconformityDes] = useState(
    props ? props.incompatibilitiesLists.inconformity.description : '',
  );
  const [inconformityId, setInconformityId] = useState(
    props ? props.incompatibilitiesLists.inconformity.id : '',
  );

  const [area, setArea] = useState('');
  const [areaDes, setAreaDes] = useState(
    props ? props.incompatibilitiesLists.area.description : '',
  );
  const [areaId, setAreaId] = useState(
    props ? props.incompatibilitiesLists.area.id : '',
  );

  const [desNonCompliance, setDesNonCompliance] = useState('');
  const [desNonComplianceDes, setDesNonComplianceDes] = useState(
    props ? props.incompatibilitiesLists.desNonCompliance.description : '',
  );
  const [desNonComplianceId, setDesNonComplianceId] = useState(
    props ? props.incompatibilitiesLists.desNonCompliance.id : '',
  );

  const [additionalDes, setAdditionalDes] = useState('');
  const [additionalDesDes, setAdditionalDesDes] = useState(
    props ? props.incompatibilitiesLists.additionalDes.description : '',
  );
  const [additionalDesId, setAdditionalDesId] = useState(
    props ? props.incompatibilitiesLists.additionalDes.id : '',
  );

  const [decisionMakerArr, setDecisionMakerArr] = useState('');
  const [decisionMaker, setDecisionMaker] = useState(
    props ? props.decisionMaker : '',
  );

  const [beerId, setBeerId] = useState(props ? props.productId : '');
  const [beerDes, setBeerDes] = useState(
    props ? `${props.product.materialNumber} ${props.product.description}` : '',
  );
  const [product, setProduct] = useState(props ? props.product : '');
  const [reasonId, setReasonId] = useState(
    props ? props.incompatibilitiesListsId : '',
  );
  const [batch, setBatch] = useState(props ? props.reportDetails.batch : '');
  const [date, setDate] = useState(props ? props.reportDetails.expiryDate : '');

  const [qty, setQty] = useState(props ? props.reportDetails.qty : '');
  const [hlQty, setHlQty] = useState(props ? props.reportDetails.hlQty : 0);
  const [comments, setComments] = useState(props ? props.comments : '');
  const [attachments, setAttachments] = useState();

  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const start = async () => {
      await dispatch(checkLoggedUser());
      const reasonsData = await dispatch(loadReasonsRequest());
      setReasons(reasonsData.data);
      const sourceData = await dispatch(loadSourcesRequest());
      setSource(sourceData.data);

      await dispatch(loadBeersRequest());

      if (reasons.length > 0 && props) {
        handleSource(props.incompatibilitiesLists.source.id);
        handleInconformity(props.incompatibilitiesLists.inconformity.id);
        handleArea(props.incompatibilitiesLists.area.id);
        handleDesNonCompliance(
          props.incompatibilitiesLists.desNonCompliance.id,
        );
        handleAdditionalDes(props.incompatibilitiesLists.additionalDes.id);
        handleDecisionMaker(props.decisionMaker);
      }
    };
    start();
  }, [dispatch, reasons.length]);

  const handleClose = () => {
    setAlert(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (reasonId === '' || beerId === '') {
      setError(true);
      setAlert(true);
    } else {
      action({
        warehouse: user.users.warehouse,
        incompatibilitiesListsId: reasonId,
        productId: beerId,
        comments,
        decisionMaker,
        userId: user.users.id,
        reportDetailsData: [
          {
            batch,
            expiryDate: date,
            qty,
            hlQty,
            activeWarehouse: user.users.warehouse,
            assignment: decisionMaker,
            basicMaker: decisionMaker,
          },
        ],
        files: attachments,
      });
    }
  };

  const handleSource = async (id) => {
    if (id === '') {
      setInconformity('');
      setInconformityDes('');
      setInconformityId('');
      setArea('');
      setAreaDes('');
      setAreaId('');
      setDesNonCompliance('');
      setDesNonComplianceDes('');
      setDesNonComplianceId('');
      setAdditionalDes('');
      setAdditionalDesDes('');
      setAdditionalDesId('');
      setDecisionMakerArr('');
      setDecisionMaker('');
      setReasonId('');
      return console.log('empty');
    }
    let inconformityData = [];
    let inconformityArray = [];
    reasons.map((el) => {
      if (el.source.id === id) {
        if (!inconformityArray.includes(el.inconformity.description)) {
          inconformityArray.push(el.inconformity.description);
          inconformityData.push(el.inconformity);
        }
      }
    });
    await setInconformity(inconformityData);
  };

  const handleInconformity = async (id) => {
    if (id === '') {
      setArea('');
      setAreaDes('');
      setAreaId('');
      setDesNonCompliance('');
      setDesNonComplianceDes('');
      setDesNonComplianceId('');
      setAdditionalDes('');
      setAdditionalDesDes('');
      setAdditionalDesId('');
      setDecisionMakerArr('');
      setDecisionMaker('');
      setReasonId('');
      return console.log('empty');
    }
    let areaData = [];
    let areaArray = [];
    reasons.map((el) => {
      if (el.source.id === sourceId && el.inconformity.id === id) {
        if (!areaArray.includes(el.area.description)) {
          areaArray.push(el.area.description);
          areaData.push(el.area);
        }
      }
    });
    await setArea(areaData);
  };

  const handleArea = async (id) => {
    if (id === '') {
      setDesNonCompliance('');
      setDesNonComplianceDes('');
      setDesNonComplianceId('');
      setAdditionalDes('');
      setAdditionalDesDes('');
      setAdditionalDesId('');
      setDecisionMakerArr('');
      setDecisionMaker('');
      setReasonId('');

      return console.log('empty');
    }
    let desNonComplianceData = [];
    let desNonComplianceArray = [];
    reasons.map((el) => {
      if (
        el.source.id === sourceId &&
        el.inconformity.id === inconformityId &&
        el.area.id === id
      ) {
        if (!desNonComplianceArray.includes(el.desNonCompliance.description)) {
          desNonComplianceArray.push(el.desNonCompliance.description);
          desNonComplianceData.push(el.desNonCompliance);
        }
      }
    });
    await setDesNonCompliance(desNonComplianceData);
  };

  const handleDesNonCompliance = async (id) => {
    if (id === '') {
      setAdditionalDes('');
      setAdditionalDesDes('');
      setAdditionalDesId('');
      setDecisionMakerArr('');
      setDecisionMaker('');
      setReasonId('');
      return console.log('empty');
    }
    let additionalDesData = [];
    let additionalDesArray = [];
    reasons.map((el) => {
      if (
        el.source.id === sourceId &&
        el.inconformity.id === inconformityId &&
        el.area.id === areaId &&
        el.desNonCompliance.id === id
      ) {
        if (!additionalDesArray.includes(el.additionalDes.description)) {
          additionalDesArray.push(el.additionalDes.description);
          additionalDesData.push(el.additionalDes);
        }
      }
    });
    await setAdditionalDes(additionalDesData);
  };

  const handleAdditionalDes = async (id) => {
    if (id === '') {
      setDecisionMakerArr('');
      setDecisionMaker('');
      setReasonId('');
      return console.log('empty');
    }
    let decisionMakerData = [];

    reasons.map((el) => {
      if (
        el.source.id === sourceId &&
        el.inconformity.id === inconformityId &&
        el.area.id === areaId &&
        el.desNonCompliance.id === desNonComplianceId &&
        el.additionalDes.id === id
      ) {
        if (!decisionMakerData.includes(el.decisionMaker)) {
          decisionMakerData.push(el.decisionMaker);
        }
      }
    });

    await setDecisionMakerArr(decisionMakerData);
  };

  const handleDecisionMaker = async (label) => {
    if (label === '') {
      return console.log('empty');
    }

    reasons.map(async (el) => {
      if (
        el.source.id === sourceId &&
        el.inconformity.id === inconformityId &&
        el.area.id === areaId &&
        el.desNonCompliance.id === desNonComplianceId &&
        el.additionalDes.id === additionalDesId &&
        el.decisionMaker === label
      ) {
        setReasonId(el.id);
      }
    });
  };

  const handleProduct = async (id) => {
    if (id === '') {
      setProduct('');
      setBatch('');
      setDate('');
      setQty('');
      setHlQty(0);
    } else {
      beers.map((el) => {
        if (el.id === id) {
          setProduct(el);
        }
      });
    }
  };

  const handleBatch = async (value) => {
    if (value.length === 7) {
      const expDate = await generateExpirationDateFromBatch(
        value,
        product.description,
        product.shelfLife,
      );

      setDate(dayjs(expDate));
    }

    setBatch(value);
  };

  const handleQty = async (value) => {
    setQty(value);

    setHlQty((value * product.casHL).toFixed(2));
  };

  const handleAttachments = (e) => {
    e.preventDefault();
    const files = e.target.files;
    setAttachments(files);
  };

  return (
    <Box sx={{ width: '100%' }}>
      {error && (
        <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            variant="filled"
            onClose={handleClose}
            severity="error"
            sx={{ width: '100%' }}
          >
            <AlertTitle>Error</AlertTitle>
            Uzupełnij wszystkie wymagane pola
          </Alert>
        </Snackbar>
      )}
      <h1>{`Formularz ${actionText}`}</h1>
      <Divider sx={{ width: '100%', mb: 3, bgcolor: '#C09A53' }} />

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: { xs: 'flex', md: 'flex' },
          flexDirection: { xs: 'column', md: 'column' },
        }}
      >
        <h5>Przyczyna niezgodności</h5>
        <Divider sx={{ width: '100%', mb: 3, bgcolor: '#C09A53' }} />
        <Box
          sx={{
            display: { xs: 'flex', md: 'flex' },
            flexDirection: { xs: 'column', md: 'row' },
            width: { xs: '100%', md: '100%' },
            mb: 3,
          }}
        >
          {source.length > 0 && (
            <Autocomplete
              disablePortal
              id="combo-source"
              options={source.map((el) => ({
                id: el.id,
                label: el.description,
              }))}
              value={sourceDes}
              sx={{ width: { xs: '100%', md: '25%' }, mb: 2, mx: 1 }}
              onChange={(e, value) => (
                setSourceId(value ? value.id : ''),
                setSourceDes(value ? value.label : ''),
                handleSource(value ? value.id : '')
              )}
              renderInput={(params) => (
                <TextField {...params} label="Pochodzenie produktu" required />
              )}
            />
          )}
          {sourceId !== '' && inconformity.length > 0 && (
            <Autocomplete
              disablePortal
              id="combo-source"
              options={inconformity.map((el) => ({
                id: el.id,
                label: el.description,
              }))}
              value={inconformityDes}
              sx={{ width: { xs: '100%', md: '25%' }, mb: 2, mx: 1 }}
              onChange={(e, value) => (
                setInconformityId(value ? value.id : ''),
                setInconformityDes(value ? value.label : ''),
                handleInconformity(value ? value.id : '')
              )}
              renderInput={(params) => (
                <TextField {...params} label="Rodzaj niezgodności" />
              )}
            />
          )}
          {sourceId !== '' && inconformityId !== '' && area.length > 0 && (
            <Autocomplete
              disablePortal
              id="combo-source"
              options={area.map((el) => ({
                id: el.id,
                label: el.description,
              }))}
              value={areaDes}
              sx={{ width: { xs: '100%', md: '25%' }, mb: 2, mx: 1 }}
              onChange={(e, value) => (
                setAreaId(value ? value.id : ''),
                setAreaDes(value ? value.label : ''),
                handleArea(value ? value.id : '')
              )}
              renderInput={(params) => (
                <TextField {...params} label="Obszar niezgodności" />
              )}
            />
          )}
          {sourceId !== '' &&
            inconformityId !== '' &&
            areaId !== '' &&
            desNonCompliance.length > 0 && (
              <Autocomplete
                disablePortal
                id="combo-source"
                options={desNonCompliance.map((el) => ({
                  id: el.id,
                  label: el.description,
                }))}
                value={desNonComplianceDes}
                sx={{ width: { xs: '100%', md: '25%' }, mb: 2, mx: 1 }}
                onChange={(e, value) => (
                  setDesNonComplianceId(value ? value.id : ''),
                  setDesNonComplianceDes(value ? value.label : ''),
                  handleDesNonCompliance(value ? value.id : '')
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Opis niezgodności" />
                )}
              />
            )}
        </Box>
        <Box
          sx={{
            display: { xs: 'flex', md: 'flex' },
            flexDirection: { xs: 'column', md: 'row' },
            width: { xs: '100%', md: '100%' },
            mb: 3,
          }}
        >
          {sourceId !== '' &&
            inconformityId !== '' &&
            areaId !== '' &&
            desNonComplianceId !== '' &&
            additionalDes.length > 0 && (
              <Autocomplete
                disablePortal
                id="combo-source"
                options={additionalDes.map((el) => ({
                  id: el.id,
                  label: el.description,
                }))}
                value={additionalDesDes}
                sx={{ width: { xs: '100%', md: '60%' }, mb: 2, mx: 1 }}
                onChange={(e, value) => (
                  setAdditionalDesId(value ? value.id : ''),
                  setAdditionalDesDes(value ? value.label : ''),
                  handleAdditionalDes(value ? value.id : '')
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Dodatkowy opis" />
                )}
              />
            )}
          {sourceId !== '' &&
            inconformityId !== '' &&
            areaId !== '' &&
            desNonComplianceId !== '' &&
            additionalDesId !== '' &&
            decisionMakerArr.length > 0 && (
              <Autocomplete
                disablePortal
                id="combo-source"
                options={decisionMakerArr.map((el) => ({
                  label: el,
                }))}
                value={decisionMaker}
                sx={{ width: { xs: '100%', md: '40%' }, mb: 2, mx: 1 }}
                onChange={(e, value) => (
                  setDecisionMaker(value ? value.label : ''),
                  handleDecisionMaker(value ? value.label : '')
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Decydent" />
                )}
              />
            )}
        </Box>
        <h5>Dane produktu</h5>
        <Divider sx={{ width: '100%', mb: 3, bgcolor: '#C09A53' }} />
        <Box
          sx={{
            display: { xs: 'flex', md: 'flex' },
            flexDirection: { xs: 'column', md: 'column' },
            width: { xs: '100%', md: '100%' },
            mb: 3,
          }}
        >
          <Box
            sx={{
              display: { xs: 'flex', md: 'flex' },
              flexDirection: { xs: 'column', md: 'row' },
              width: { xs: '100%', md: '100%' },
              mb: 3,
            }}
          >
            {beers.length > 0 && (
              <Autocomplete
                disablePortal
                id="combo-source"
                options={beers.map((el) => ({
                  id: el.id,
                  label: `${el.materialNumber} ${el.description}`,
                }))}
                value={beerDes}
                sx={{ width: { xs: '100%', md: '50%' }, mb: 2, mx: 1 }}
                onChange={(e, value) => (
                  setBeerId(value ? value.id : ''),
                  setBeerDes(value ? value.label : ''),
                  handleProduct(value ? value.id : '')
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Indeks" />
                )}
              />
            )}
            {product !== '' && (
              <>
                <TextField
                  sx={{ width: { xs: '100%', md: '30%' }, mx: 1, mb: 2 }}
                  variant="outlined"
                  label="Marka"
                  disabled
                  value={product.brand.description}
                />

                <TextField
                  sx={{ width: { xs: '100%', md: '20%' }, mx: 1, mb: 2 }}
                  variant="outlined"
                  label="Opakowanie"
                  disabled
                  value={product.package.description}
                />
              </>
            )}
          </Box>

          {product !== '' && (
            <Box
              sx={{
                display: { xs: 'flex', md: 'flex' },
                flexDirection: { xs: 'column', md: 'row' },
                width: { xs: '100%', md: '100%' },
                mb: 3,
              }}
            >
              <TextField
                sx={{ width: { xs: '100%', md: '30%' }, mx: 1, mb: 2 }}
                variant="outlined"
                label="Partia"
                value={batch}
                onChange={(e) => handleBatch(e.target.value)}
              />
              <LocalizationProvider
                adapterLocale="pl"
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  label="Data przydatności"
                  format="DD.MM.YYYY"
                  views={['year', 'month', 'day']}
                  value={dayjs(date)}
                  onChange={(newValue) => {
                    setDate(dayjs(newValue));
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

              <TextField
                sx={{ width: { xs: '100%', md: '25%' }, mx: 1, mb: 2 }}
                variant="outlined"
                label="Ilość cas"
                value={qty}
                onChange={(e) => handleQty(e.target.value)}
              />
              <TextField
                sx={{ width: { xs: '100%', md: '25%' }, mx: 1 }}
                variant="outlined"
                label="Ilość HL"
                value={hlQty}
                disabled
              />
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: { xs: 'flex', md: 'flex' },
            flexDirection: { xs: 'column', md: 'row' },
            width: { xs: '100%', md: '100%' },
            mb: 3,
          }}
        >
          <Box sx={{ width: { xs: '100%', md: '50%' }, mx: 1 }}>
            <h5>Uwagi</h5>
            <Divider sx={{ width: '100%', mb: 3, bgcolor: '#C09A53' }} />
            <TextField
              sx={{ width: '100%' }}
              variant="outlined"
              label="Uwagi"
              multiline
              rows={4}
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </Box>
          {!props && (
            <Box sx={{ width: { xs: '100%', md: '50%' }, mx: 1 }}>
              <h5>Załączniki</h5>
              <Divider sx={{ width: '100%', mb: 3, bgcolor: '#C09A53' }} />
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
              >
                Dodaj załącznik
                <VisuallyHiddenInput
                  type="file"
                  multiple
                  onChange={handleAttachments}
                />
              </Button>
              {attachments && (
                <Box sx={{ mt: 2 }}>
                  {Object.values(attachments).map((el, index) => (
                    <Box key={index}>{el.name}</Box>
                  ))}
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Divider sx={{ width: '100%', mb: 3, bgcolor: '#C09A53' }} />
        <Button
          sx={{
            color: 'white',
            bgcolor: '#C09A53',
            ':hover': { bgcolor: '#9c7c43' },
            height: '40px',
            width: '150px',
          }}
          type="submit"
        >
          Zatwierdź
        </Button>
      </Box>
    </Box>
  );
};

export default ReportForm;
