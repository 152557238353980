import axios from 'axios';
import { API_URL } from '../config';
import initialState from './initialState';

export const getSources = ({ source }) => source;
export const getSourceById = ({ source }, id) =>
  source.find((el) => el.id === id);

const reducerName = 'sources';
const createActionName = (name) => `app/${reducerName}/${name}`;

const LOAD_SOURCES = createActionName('LOAD_SOURCES');
const ADD_SOURCE = createActionName('ADD_SOURCE');
const EDIT_SOURCE = createActionName('EDIT_SOURCE');
const DELETE_SOURCE = createActionName('DELETE_SOURCE');

export const loadSources = (payload) => ({ payload, type: LOAD_SOURCES });
export const addSource = (payload) => ({ payload, type: ADD_SOURCE });
export const editSource = (payload) => ({ payload, type: EDIT_SOURCE });
export const deleteSource = (payload) => ({ payload, type: DELETE_SOURCE });

export const loadSourcesRequest = () => {
  return async (dispatch) => {
    try {
      let res = await axios.get(`${API_URL}/sources`, {
        withCredentials: true,
      });

      dispatch(loadSources(res.data));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const loadSourcesByIdRequest = (id) => {
  return async (dispatch) => {
    try {
      let res = await axios.get(`${API_URL}/sources/${id}`, {
        withCredentials: true,
      });

      dispatch(loadSources(res.data));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const addSourceRequest = (data) => {
  return async (dispatch) => {
    try {
      let res = await axios.post(`${API_URL}/sources`, data, {
        withCredentials: true,
      });
      dispatch(addSource(res.data));
      return res;
    } catch (e) {
      return e.response;
    }
  };
};

export const editSourceRequest = (data) => {
  return async (dispatch) => {
    try {
      let res = await axios.put(`${API_URL}/sources/${data.id}`, data, {
        withCredentials: true,
      });
      dispatch(editSource(res.data));
    } catch (e) {
      return e.message.status;
    }
  };
};

export const deleteSourceRequest = (id) => {
  return async (dispatch) => {
    try {
      let res = await axios.delete(`${API_URL}/sources/${id}`, {
        withCredentials: true,
      });
      dispatch(deleteSource(id));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

const sourcesReducer = (statePart = initialState, action) => {
  switch (action.type) {
    case ADD_SOURCE:
      return [...statePart, action.payload];
    case EDIT_SOURCE:
      return statePart.map((el) =>
        el.id === action.payload.id ? action.payload : el,
      );
    case DELETE_SOURCE:
      return [...statePart.filter((el) => el.id !== action.payload)];
    case LOAD_SOURCES:
      return action.payload;
    default:
      return statePart;
  }
};

export default sourcesReducer;
