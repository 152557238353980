import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import MainLayout from './components/layout/MainLayout/MainLayout';
import Home from './components/pages/Home/Home';
import Login from './components/pages/Login/Login';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { checkLoggedUser, getUser } from './redux/usersRedux';
import LogOut from './components/pages/LogOut/LogOut';

import NonCompliance from './components/pages/NonCompliance/NonCompliance';
import NotFound from './components/pages/NotFound/NotFound';
import AddSource from './components/pages/AddSource/AddSource';
import EditSource from './components/pages/EditSource/EditSource';
import AddArea from './components/pages/AddArea/AddArea';
import EditArea from './components/pages/EditArea/EditArea';
import AddInconformity from './components/pages/AddInconformity/AddInconformity';
import EditInconformity from './components/pages/EditInconformity/EditInconformity';
import AddDesNon from './components/pages/AddDesNon/AddDesNon';

import EditDesNon from './components/pages/EditDesNon/EditDesNon';
import AddAdditionalDes from './components/pages/AddAdditionalDes/AddAdditionalDes';
import EditAdditionalDes from './components/pages/EditAdditionalDes/EditAdditionalDes';
import ReasonList from './components/pages/ReasonList/ReasonList';
import AddReason from './components/pages/AddReason/AddReason';
import EditReason from './components/pages/EditReason/EditReason';
import AddBrand from './components/pages/AddBrand/AddBrand';
import EditBrand from './components/pages/EditBrand/EditBrand';
import AddPackage from './components/pages/AddPackage/AddPackage';
import EditPackage from './components/pages/EditPackage/EditPackage';
import BeerList from './components/pages/BeerList/BeerList';
import AddBeer from './components/pages/AddBeer/AddBeer';
import EditBeer from './components/pages/EditBeer/EditBeer';
import UsersList from './components/pages/UsersList/UsersList';
import ConfirmationAccount from './components/pages/ConfirmationAccount/ConfirmationAccount';
import EditUser from './components/pages/EditUser/EditUser';
import AccessDenied from './components/pages/AccessDenied/AccessDenied';
import MyAccount from './components/pages/MyAccount/MyAcconut';
import AddUser from './components/pages/AddUser/AddUser';
import ReportList from './components/pages/ReportList/ReportList';
import AddReportPZ05 from './components/pages/AddReportPZ05/AddReportPZ05';
import EditReportPZ05 from './components/pages/EditReportPZ05/EditReportPZ05';
import DetailsPZ05 from './components/pages/DetailsPZ05/DetailsPZ05';
import { Role } from '@prisma/client';
import ReportsPZ05 from './components/pages/ReportsPZ05/ReportsPZ05';

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const user = useSelector((state) => getUser(state));
  useEffect(() => {
    const start = async () => {
      setLoading(true);
      await dispatch(checkLoggedUser());
      setLoading(false);
    };

    start();
  }, [dispatch]);

  const location = useLocation();

  if (!loading) {
    return (
      <MainLayout>
        <Routes>
          <Route
            path="/login"
            element={
              user.users !== null ? <Navigate to="/" replace /> : <Login />
            }
          />
          <Route
            path="/"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : (
                <Home />
              )
            }
          />
          <Route path="/logout" element={<LogOut />} />

          <Route
            path="/beer"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ||
                user.users.role === Role.KONTROLER ? (
                <BeerList />
              ) : (
                <AccessDenied />
              )
            }
          />
          <Route
            path="/add-beer"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ||
                user.users.role === Role.KONTROLER ? (
                <AddBeer />
              ) : (
                <AccessDenied />
              )
            }
          />
          <Route
            path="/edit-beer/:id"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ||
                user.users.role === Role.KONTROLER ? (
                <EditBeer />
              ) : (
                <AccessDenied />
              )
            }
          />
          <Route
            path="/noncompliant"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ? (
                <NonCompliance />
              ) : (
                <AccessDenied />
              )
            }
          />
          <Route
            path="/pochodzenie"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ? (
                <AddSource />
              ) : (
                <AccessDenied />
              )
            }
          />

          <Route
            path="/edit-source/:id"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ? (
                <EditSource />
              ) : (
                <AccessDenied />
              )
            }
          />
          <Route
            path="/obszar"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ? (
                <AddArea />
              ) : (
                <AccessDenied />
              )
            }
          />

          <Route
            path="/edit-area/:id"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ? (
                <EditArea />
              ) : (
                <AccessDenied />
              )
            }
          />
          <Route
            path="/rodzaj"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ? (
                <AddInconformity />
              ) : (
                <AccessDenied />
              )
            }
          />

          <Route
            path="/edit-inconformity/:id"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ? (
                <EditInconformity />
              ) : (
                <AccessDenied />
              )
            }
          />
          <Route
            path="/opis"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ? (
                <AddDesNon />
              ) : (
                <AccessDenied />
              )
            }
          />
          <Route
            path="/edit-desNon/:id"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ? (
                <EditDesNon />
              ) : (
                <AccessDenied />
              )
            }
          />
          <Route
            path="/dodatkowy-opis"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ? (
                <AddAdditionalDes />
              ) : (
                <AccessDenied />
              )
            }
          />
          <Route
            path="/edit-description/:id"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ? (
                <EditAdditionalDes />
              ) : (
                <AccessDenied />
              )
            }
          />
          <Route
            path="/przyczyny"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ||
                user.users.role === Role.KIER_DS_REKLAMACJI ||
                user.users.role === Role.KONTROLER ? (
                <ReasonList />
              ) : (
                <AccessDenied />
              )
            }
          />
          <Route
            path="/add-reason"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ||
                user.users.role === Role.KIER_DS_REKLAMACJI ? (
                <AddReason />
              ) : (
                <AccessDenied />
              )
            }
          />

          <Route
            path="/edit-reason/:id"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ||
                user.users.role === Role.KIER_DS_REKLAMACJI ? (
                <EditReason />
              ) : (
                <AccessDenied />
              )
            }
          />
          <Route
            path="/marka"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ? (
                <AddBrand />
              ) : (
                <AccessDenied />
              )
            }
          />

          <Route
            path="/edit-brand/:id"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ? (
                <EditBrand />
              ) : (
                <AccessDenied />
              )
            }
          />
          <Route
            path="/opakowanie"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ? (
                <AddPackage />
              ) : (
                <AccessDenied />
              )
            }
          />

          <Route
            path="/edit-package/:id"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ? (
                <EditPackage />
              ) : (
                <AccessDenied />
              )
            }
          />
          <Route
            path="/users"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ? (
                <UsersList />
              ) : (
                <AccessDenied />
              )
            }
          />
          <Route
            path="/edit-user/:id"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ? (
                <EditUser />
              ) : (
                <AccessDenied />
              )
            }
          />
          <Route
            path="/account"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : (
                <MyAccount />
              )
            }
          />
          <Route
            path="/add-user"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : (
                <AddUser />
              )
            }
          />
          <Route
            path="/pz05"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ||
                user.users.role === Role.KONTROLER ||
                user.users.role === Role.KIER_DS_REKLAMACJI ||
                user.users.role === Role.DSM ||
                user.users.role === Role.KIER_ODC_BWH ||
                user.users.role === Role.KIER_SYST_ZAPEWNIENIA_JAK ||
                user.users.role === Role.LIDER_PRZEPAKOWANIA ||
                user.users.role === Role.ROZLEW ||
                user.users.role === Role.WOM ? (
                <ReportList />
              ) : (
                <AccessDenied />
              )
            }
          />
          <Route
            path="/pz05/raport"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ||
                user.users.role === Role.KONTROLER ||
                user.users.role === Role.KIER_DS_REKLAMACJI ? (
                <ReportsPZ05 />
              ) : (
                <AccessDenied />
              )
            }
          />
          <Route
            path="/add-pz05"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ||
                user.users.role === Role.KONTROLER ? (
                <AddReportPZ05 />
              ) : (
                <AccessDenied />
              )
            }
          />

          <Route
            path="/edit-pz05/:id"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ||
                user.users.role === Role.KONTROLER ? (
                <EditReportPZ05 />
              ) : (
                <AccessDenied />
              )
            }
          />
          <Route
            path="/details-pz05/:id"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : user.users.role === Role.ADMIN ||
                user.users.role === Role.KONTROLER ||
                user.users.role === Role.KIER_DS_REKLAMACJI ||
                user.users.role === Role.KIER_SYST_ZAPEWNIENIA_JAK ||
                user.users.role === Role.KIER_ODC_BWH ||
                user.users.role === Role.LIDER_PRZEPAKOWANIA ||
                user.users.role === Role.WOM ||
                user.users.role === Role.DSM ? (
                <DetailsPZ05 />
              ) : (
                <AccessDenied />
              )
            }
          />

          <Route path="/verify/:token" element={<ConfirmationAccount />} />
          <Route
            path="*"
            element={
              user.users === null ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: location.pathname }}
                />
              ) : (
                <NotFound />
              )
            }
          />
        </Routes>
      </MainLayout>
    );
  }
}

export default App;
