import { Box, Divider } from '@mui/material';
import GenerateRaportPZ05 from '../../features/GenerateRaportPZ05/GenerateRaportPZ05';

const ReportsPZ05 = () => {
  return (
    <Box sx={{ marginTop: '50px', ml: { xs: 2, md: 0 } }}>
      <h1>Raport PZ05</h1>
      <Divider sx={{ width: '100%', mb: 3, bgcolor: '#C09A53' }} />
      <GenerateRaportPZ05 />
    </Box>
  );
};

export default ReportsPZ05;
