import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkLoggedUser, getUser } from '../../../redux/usersRedux';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {
  deleteReportPZ05Request,
  getReportsPZ05,
  loadReportPZ05ByQualityRequest,
  loadReportsPZ05ByUserRole,
  loadReportsPZ05ByUserWarehouse,
  loadReportsPZ05Request,
} from '../../../redux/reportsPZ05Redux';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Chip,
  Tooltip,
  Select,
  MenuItem,
  Pagination,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import ReadMore from '@mui/icons-material/ReadMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import { dateFormatter } from '../../../utils/dateFormatter';
import { changeStatusName } from '../../../utils/changeStatusName';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

const ReportListForm = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => getUser(state));
  const [activePage, setActivePage] = useState(1);
  const [itemsPage, setItemsPage] = useState(30);
  const [pageCount, setPageCount] = useState(0);
  const reports = useSelector((state) => getReportsPZ05(state));
  const [reportsFiltered, setReportsFiltered] = useState('');
  const [newReport, setNewReport] = useState(true);
  const [inProgressReport, setInProgressReport] = useState(true);
  const [doneReport, setDoneReport] = useState(false);
  const [dateFrom, setDateFrom] = useState(undefined);
  const [dateTo, setDateTo] = useState(undefined);

  useEffect(() => {
    const start = async () => {
      await dispatch(checkLoggedUser());
      if (
        user.users.role === 'KONTROLER' ||
        user.users.role === 'ROZLEW' ||
        user.users.role === 'KIER_ODC_BWH'
      ) {
        await dispatch(loadReportsPZ05ByUserWarehouse(user.users.warehouse));
      } else if (user.users.role === 'ADMIN') {
        await dispatch(loadReportsPZ05Request());
      } else if (
        user.users.role === 'WOM' ||
        user.users.role === 'KIER_SYST_ZAPEWNIENIA_JAK' ||
        user.users.role === 'LIDER_PRZEPAKOWANIA'
      ) {
        await dispatch(loadReportsPZ05ByUserRole(user.users.role));
      } else if (user.users.role === 'KIER_DS_REKLAMACJI') {
        await dispatch(loadReportPZ05ByQualityRequest());
      }

      setLoading(false);
    };
    start();
  }, [dispatch]);

  useEffect(() => {
    setPageCount(Math.ceil(reports.length / itemsPage));
  }, [reports, itemsPage]);

  const handleSubmit = async (id) => {
    await dispatch(deleteReportPZ05Request(id));
  };

  const handleDecisionMaker = (decisionMakerArr) => {
    let decisionMaker = [];

    decisionMakerArr.map((el) => {
      if (
        user.users.role === 'KONTROLER' &&
        el.activeWarehouse === user.users.warehouse &&
        el.status !== 'DONE'
      ) {
        if (!decisionMaker.includes(el.userRole)) {
          decisionMaker.push(el.userRole);
        }
      } else if (user.users.role !== 'KONTROLER' && el.status !== 'DONE') {
        if (!decisionMaker.includes(el.userRole)) {
          decisionMaker.push(el.userRole);
        }
      }
    });

    if (decisionMaker.length === 1) {
      return <TableCell>{decisionMaker[0]}</TableCell>;
    } else if (decisionMaker.length === 0) {
      return <TableCell>KONTROLER</TableCell>;
    } else {
      return (
        <Tooltip
          followCursor
          title={
            <>
              {decisionMaker.map((el) => (
                <div key={el}>{el}</div>
              ))}
            </>
          }
        >
          <TableCell
            style={{
              textTransform: 'uppercase',
              backgroundColor: decisionMaker.includes(user.users.role)
                ? 'rgba(243,156,18, 0.6)'
                : '',
            }}
          >
            Decyzja złożona
          </TableCell>
        </Tooltip>
      );
    }
  };

  const handleChange = (event, value) => {
    setActivePage(value);
  };
  const handleItemsPage = (value) => {
    setItemsPage(value);
    if (activePage > Math.ceil(reports.length / value)) {
      setActivePage(Math.ceil(reports.length / value));
    }
  };

  const handleNewChange = (status) => {
    switch (status) {
      case 'new':
        setNewReport(!newReport);
        break;
      case 'progress':
        setInProgressReport(!inProgressReport);
        break;
      case 'done':
        setDoneReport(!doneReport);
        break;
      default:
        break;
    }
  };

  const handleDateTo = (date) => {
    if (date === undefined) return setDateTo(undefined);
    const newDateTo = new Date(date);
    newDateTo.setHours(23, 59, 59, 999);

    setDateTo(newDateTo);
  };

  const handleDateFrom = (date) => {
    if (date === undefined) return setDateFrom(undefined);
    const newDateFrom = new Date(date);
    newDateFrom.setHours(0, 0, 0, 0);
    setDateFrom(newDateFrom);
  };

  if (!loading) {
    return (
      <>
        <Select
          sx={{ mb: 3 }}
          labelId="select-count"
          id="select-page-count"
          value={itemsPage}
          label="Ilość na stronie"
          // onChange={(e) => setItemsPage(e.target.value)}
          onChange={(e) => handleItemsPage(e.target.value)}
        >
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>

        <Tooltip title="Dostępne pola do filtrowania: numer raportu, indeks, nazwa materiału oraz wymagane działanie">
          <TextField
            sx={{ mb: 3, ml: 3, mr: 1 }}
            label="Filtruj"
            value={reportsFiltered}
            variant="outlined"
            onChange={(e) => setReportsFiltered(e.target.value)}
          />
        </Tooltip>
        <LocalizationProvider adapterLocale="pl" dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Data początkowa"
            format="DD.MM.YYYY"
            views={['year', 'month', 'day']}
            value={dateFrom === undefined ? 'DD.MM.YYYY' : dayjs(dateFrom)}
            onChange={(newValue) => {
              handleDateFrom(newValue);
            }}
            slotProps={{
              field: {
                clearable: true,
                onChange: () => {
                  handleDateFrom(undefined);
                },
              },
            }}
            sx={{ mr: 2, mb: { xs: 2 }, width: { xs: '100%', md: '250px' } }}
          />
          <DatePicker
            label="Data końcowa"
            format="DD.MM.YYYY"
            views={['year', 'month', 'day']}
            value={dateTo === undefined ? 'DD.MM.YYYY' : dayjs(dateTo)}
            onChange={(newValue) => {
              handleDateTo(newValue);
            }}
            slotProps={{
              field: {
                clearable: true,
                onChange: () => {
                  handleDateTo(undefined);
                },
              },
            }}
            sx={{ mr: 2, width: { xs: '100%', md: '250px' } }}
          />
        </LocalizationProvider>

        <FormControlLabel
          sx={{ ml: 2 }}
          label="Nowe"
          control={
            <Checkbox
              color="primary"
              checked={newReport}
              onChange={() => handleNewChange('new')}
            />
          }
        />
        <FormControlLabel
          sx={{ ml: 2 }}
          label="W trakcie"
          control={
            <Checkbox
              sx={{ '&.Mui-checked': { color: 'orange' } }}
              checked={inProgressReport}
              onChange={() => handleNewChange('progress')}
            />
          }
        />
        <FormControlLabel
          sx={{ ml: 2 }}
          label="Zakończone"
          control={
            <Checkbox
              color="success"
              checked={doneReport}
              onChange={() => handleNewChange('done')}
            />
          }
        />

        <TableContainer
          component={Paper}
          sx={{ border: '1px solid black', width: '105%' }}
        >
          <Table sx={{ minWidth: 650 }} size="small" aria-label="report table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Nr Raportu</TableCell>
                <TableCell>Magazyn źródłowy</TableCell>
                <TableCell>Użytkownik</TableCell>
                <TableCell>Data utworzenia</TableCell>
                <TableCell>Indeks</TableCell>
                <TableCell>Nazwa Materiału</TableCell>
                <TableCell>Rodzaj niezgodności</TableCell>
                <TableCell>Wymagane działanie</TableCell>
                <TableCell>Status</TableCell>

                {(user.users.role === 'KONTROLER' ||
                  user.users.role === 'ADMIN') && (
                  <TableCell align="center">Akcja</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {reports.length > 0 &&
                reports
                  .slice((activePage - 1) * itemsPage, activePage * itemsPage)
                  .map((el) => {
                    if (reportsFiltered.length > 0) {
                      if (
                        (el.reportNumber
                          .toLowerCase()
                          .includes(reportsFiltered.toLowerCase()) ||
                          el.decisionMaker
                            .toLowerCase()
                            .includes(reportsFiltered.toLowerCase()) ||
                          el.product.description
                            .toLowerCase()
                            .includes(reportsFiltered.toLowerCase()) ||
                          el.product.materialNumber
                            .toString()
                            .toLowerCase()
                            .includes(reportsFiltered.toLocaleLowerCase())) &&
                        ((el.statusReport === 'NEW' && newReport) ||
                          (el.statusReport === 'IN_PROGRESS' &&
                            inProgressReport) ||
                          (el.statusReport === 'DONE' && doneReport))
                      ) {
                        if (dateFrom !== undefined && dateTo !== undefined) {
                          if (
                            new Date(el.createdAt) >= dateFrom &&
                            new Date(el.createdAt) <= dateTo
                          ) {
                            return (
                              <TableRow
                                hover
                                key={el.id}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                  // '&:nth-of-type(odd)': { backgroundColor: '#fafafa' },
                                }}
                              >
                                <TableCell>
                                  <Button
                                    sx={{ color: '#C09A53' }}
                                    component={Link}
                                    to={`/details-pz05/${el.id}`}
                                  >
                                    <ReadMore />
                                  </Button>
                                </TableCell>
                                <TableCell component="th">
                                  {el.reportNumber}
                                </TableCell>
                                <TableCell>{el.warehouse}</TableCell>
                                <TableCell>{`${el.user.firstName} ${el.user.lastName}`}</TableCell>
                                <TableCell>
                                  {dateFormatter(el.createdAt)}
                                </TableCell>
                                <TableCell>
                                  {el.product.materialNumber}
                                </TableCell>
                                <TableCell>{el.product.description}</TableCell>
                                <TableCell>
                                  {
                                    el.incompatibilitiesLists.inconformity
                                      .description
                                  }
                                </TableCell>
                                {el.reportDetails.decisions.length > 1 ? (
                                  handleDecisionMaker(
                                    el.reportDetails.decisions,
                                  )
                                ) : (
                                  <TableCell>{el.decisionMaker}</TableCell>
                                )}

                                {el.statusReport === 'DONE' && (
                                  <TableCell>
                                    <Chip
                                      label={changeStatusName(el.statusReport)}
                                      color="success"
                                    />
                                  </TableCell>
                                )}
                                {el.statusReport === 'IN_PROGRESS' && (
                                  <TableCell>
                                    <Chip
                                      label={changeStatusName(el.statusReport)}
                                      sx={{ backgroundColor: 'orange' }}
                                    />
                                  </TableCell>
                                )}
                                {el.statusReport === 'NEW' && (
                                  <TableCell>
                                    <Chip
                                      label={changeStatusName(el.statusReport)}
                                      color="primary"
                                    />
                                  </TableCell>
                                )}

                                {user.users.role === 'ADMIN' ||
                                el.userId === user.users.id ? (
                                  <TableCell align="center">
                                    <Box sx={{ display: 'flex' }}>
                                      {(el.statusReport === 'NEW' ||
                                        user.users.role === 'ADMIN') && (
                                        <Button
                                          sx={{ color: 'orange', p: '0' }}
                                          component={Link}
                                          to={`/edit-pz05/${el.id}`}
                                        >
                                          <EditIcon />
                                        </Button>
                                      )}
                                      {(el.statusReport === 'NEW' ||
                                        user.users.role === 'ADMIN') && (
                                        <Button
                                          sx={{ color: 'red', p: '0' }}
                                          onClick={() => handleSubmit(el.id)}
                                        >
                                          <DeleteIcon />
                                        </Button>
                                      )}
                                    </Box>
                                  </TableCell>
                                ) : (
                                  <TableCell />
                                )}
                              </TableRow>
                            );
                          }
                        } else if (dateTo !== undefined) {
                          if (new Date(el.createdAt) <= dateTo) {
                            return (
                              <TableRow
                                hover
                                key={el.id}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                  // '&:nth-of-type(odd)': { backgroundColor: '#fafafa' },
                                }}
                              >
                                <TableCell>
                                  <Button
                                    sx={{ color: '#C09A53' }}
                                    component={Link}
                                    to={`/details-pz05/${el.id}`}
                                  >
                                    <ReadMore />
                                  </Button>
                                </TableCell>
                                <TableCell component="th">
                                  {el.reportNumber}
                                </TableCell>
                                <TableCell>{el.warehouse}</TableCell>
                                <TableCell>{`${el.user.firstName} ${el.user.lastName}`}</TableCell>
                                <TableCell>
                                  {dateFormatter(el.createdAt)}
                                </TableCell>
                                <TableCell>
                                  {el.product.materialNumber}
                                </TableCell>
                                <TableCell>{el.product.description}</TableCell>
                                <TableCell>
                                  {
                                    el.incompatibilitiesLists.inconformity
                                      .description
                                  }
                                </TableCell>
                                {el.reportDetails.decisions.length > 1 ? (
                                  handleDecisionMaker(
                                    el.reportDetails.decisions,
                                  )
                                ) : (
                                  <TableCell>{el.decisionMaker}</TableCell>
                                )}

                                {el.statusReport === 'DONE' && (
                                  <TableCell>
                                    <Chip
                                      label={changeStatusName(el.statusReport)}
                                      color="success"
                                    />
                                  </TableCell>
                                )}
                                {el.statusReport === 'IN_PROGRESS' && (
                                  <TableCell>
                                    <Chip
                                      label={changeStatusName(el.statusReport)}
                                      sx={{ backgroundColor: 'orange' }}
                                    />
                                  </TableCell>
                                )}
                                {el.statusReport === 'NEW' && (
                                  <TableCell>
                                    <Chip
                                      label={changeStatusName(el.statusReport)}
                                      color="primary"
                                    />
                                  </TableCell>
                                )}

                                {user.users.role === 'ADMIN' ||
                                el.userId === user.users.id ? (
                                  <TableCell align="center">
                                    <Box sx={{ display: 'flex' }}>
                                      {(el.statusReport === 'NEW' ||
                                        user.users.role === 'ADMIN') && (
                                        <Button
                                          sx={{ color: 'orange', p: '0' }}
                                          component={Link}
                                          to={`/edit-pz05/${el.id}`}
                                        >
                                          <EditIcon />
                                        </Button>
                                      )}
                                      {(el.statusReport === 'NEW' ||
                                        user.users.role === 'ADMIN') && (
                                        <Button
                                          sx={{ color: 'red', p: '0' }}
                                          onClick={() => handleSubmit(el.id)}
                                        >
                                          <DeleteIcon />
                                        </Button>
                                      )}
                                    </Box>
                                  </TableCell>
                                ) : (
                                  <TableCell />
                                )}
                              </TableRow>
                            );
                          }
                        } else if (dateFrom !== undefined) {
                          if (new Date(el.createdAt) >= dateFrom) {
                            return (
                              <TableRow
                                hover
                                key={el.id}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                  // '&:nth-of-type(odd)': { backgroundColor: '#fafafa' },
                                }}
                              >
                                <TableCell>
                                  <Button
                                    sx={{ color: '#C09A53' }}
                                    component={Link}
                                    to={`/details-pz05/${el.id}`}
                                  >
                                    <ReadMore />
                                  </Button>
                                </TableCell>
                                <TableCell component="th">
                                  {el.reportNumber}
                                </TableCell>
                                <TableCell>{el.warehouse}</TableCell>
                                <TableCell>{`${el.user.firstName} ${el.user.lastName}`}</TableCell>
                                <TableCell>
                                  {dateFormatter(el.createdAt)}
                                </TableCell>
                                <TableCell>
                                  {el.product.materialNumber}
                                </TableCell>
                                <TableCell>{el.product.description}</TableCell>
                                <TableCell>
                                  {
                                    el.incompatibilitiesLists.inconformity
                                      .description
                                  }
                                </TableCell>
                                {el.reportDetails.decisions.length > 1 ? (
                                  handleDecisionMaker(
                                    el.reportDetails.decisions,
                                  )
                                ) : (
                                  <TableCell>{el.decisionMaker}</TableCell>
                                )}

                                {el.statusReport === 'DONE' && (
                                  <TableCell>
                                    <Chip
                                      label={changeStatusName(el.statusReport)}
                                      color="success"
                                    />
                                  </TableCell>
                                )}
                                {el.statusReport === 'IN_PROGRESS' && (
                                  <TableCell>
                                    <Chip
                                      label={changeStatusName(el.statusReport)}
                                      sx={{ backgroundColor: 'orange' }}
                                    />
                                  </TableCell>
                                )}
                                {el.statusReport === 'NEW' && (
                                  <TableCell>
                                    <Chip
                                      label={changeStatusName(el.statusReport)}
                                      color="primary"
                                    />
                                  </TableCell>
                                )}

                                {user.users.role === 'ADMIN' ||
                                el.userId === user.users.id ? (
                                  <TableCell align="center">
                                    <Box sx={{ display: 'flex' }}>
                                      {(el.statusReport === 'NEW' ||
                                        user.users.role === 'ADMIN') && (
                                        <Button
                                          sx={{ color: 'orange', p: '0' }}
                                          component={Link}
                                          to={`/edit-pz05/${el.id}`}
                                        >
                                          <EditIcon />
                                        </Button>
                                      )}
                                      {(el.statusReport === 'NEW' ||
                                        user.users.role === 'ADMIN') && (
                                        <Button
                                          sx={{ color: 'red', p: '0' }}
                                          onClick={() => handleSubmit(el.id)}
                                        >
                                          <DeleteIcon />
                                        </Button>
                                      )}
                                    </Box>
                                  </TableCell>
                                ) : (
                                  <TableCell />
                                )}
                              </TableRow>
                            );
                          }
                        } else {
                          return (
                            <TableRow
                              hover
                              key={el.id}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                                // '&:nth-of-type(odd)': { backgroundColor: '#fafafa' },
                              }}
                            >
                              <TableCell>
                                <Button
                                  sx={{ color: '#C09A53' }}
                                  component={Link}
                                  to={`/details-pz05/${el.id}`}
                                >
                                  <ReadMore />
                                </Button>
                              </TableCell>
                              <TableCell component="th">
                                {el.reportNumber}
                              </TableCell>
                              <TableCell>{el.warehouse}</TableCell>
                              <TableCell>{`${el.user.firstName} ${el.user.lastName}`}</TableCell>
                              <TableCell>
                                {dateFormatter(el.createdAt)}
                              </TableCell>
                              <TableCell>{el.product.materialNumber}</TableCell>
                              <TableCell>{el.product.description}</TableCell>
                              <TableCell>
                                {
                                  el.incompatibilitiesLists.inconformity
                                    .description
                                }
                              </TableCell>
                              {el.reportDetails.decisions.length > 1 ? (
                                handleDecisionMaker(el.reportDetails.decisions)
                              ) : (
                                <TableCell>{el.decisionMaker}</TableCell>
                              )}

                              {el.statusReport === 'DONE' && (
                                <TableCell>
                                  <Chip
                                    label={changeStatusName(el.statusReport)}
                                    color="success"
                                  />
                                </TableCell>
                              )}
                              {el.statusReport === 'IN_PROGRESS' && (
                                <TableCell>
                                  <Chip
                                    label={changeStatusName(el.statusReport)}
                                    sx={{ backgroundColor: 'orange' }}
                                  />
                                </TableCell>
                              )}
                              {el.statusReport === 'NEW' && (
                                <TableCell>
                                  <Chip
                                    label={changeStatusName(el.statusReport)}
                                    color="primary"
                                  />
                                </TableCell>
                              )}

                              {user.users.role === 'ADMIN' ||
                              el.userId === user.users.id ? (
                                <TableCell align="center">
                                  <Box sx={{ display: 'flex' }}>
                                    {(el.statusReport === 'NEW' ||
                                      user.users.role === 'ADMIN') && (
                                      <Button
                                        sx={{ color: 'orange', p: '0' }}
                                        component={Link}
                                        to={`/edit-pz05/${el.id}`}
                                      >
                                        <EditIcon />
                                      </Button>
                                    )}
                                    {(el.statusReport === 'NEW' ||
                                      user.users.role === 'ADMIN') && (
                                      <Button
                                        sx={{ color: 'red', p: '0' }}
                                        onClick={() => handleSubmit(el.id)}
                                      >
                                        <DeleteIcon />
                                      </Button>
                                    )}
                                  </Box>
                                </TableCell>
                              ) : (
                                <TableCell />
                              )}
                            </TableRow>
                          );
                        }
                      }
                    } else if (
                      (el.statusReport === 'NEW' && newReport) ||
                      (el.statusReport === 'IN_PROGRESS' && inProgressReport) ||
                      (el.statusReport === 'DONE' && doneReport)
                    ) {
                      if (dateFrom !== undefined && dateTo !== undefined) {
                        if (
                          new Date(el.createdAt) >= dateFrom &&
                          new Date(el.createdAt) <= dateTo
                        ) {
                          return (
                            <TableRow
                              hover
                              key={el.id}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                                // '&:nth-of-type(odd)': { backgroundColor: '#fafafa' },
                              }}
                            >
                              <TableCell>
                                <Button
                                  sx={{ color: '#C09A53' }}
                                  component={Link}
                                  to={`/details-pz05/${el.id}`}
                                >
                                  <ReadMore />
                                </Button>
                              </TableCell>
                              <TableCell component="th">
                                {el.reportNumber}
                              </TableCell>
                              <TableCell>{el.warehouse}</TableCell>
                              <TableCell>{`${el.user.firstName} ${el.user.lastName}`}</TableCell>
                              <TableCell>
                                {dateFormatter(el.createdAt)}
                              </TableCell>
                              <TableCell>{el.product.materialNumber}</TableCell>
                              <TableCell>{el.product.description}</TableCell>
                              <TableCell>
                                {
                                  el.incompatibilitiesLists.inconformity
                                    .description
                                }
                              </TableCell>
                              {el.reportDetails.decisions.length > 1 ? (
                                handleDecisionMaker(el.reportDetails.decisions)
                              ) : (
                                <TableCell>{el.decisionMaker}</TableCell>
                              )}

                              {el.statusReport === 'DONE' && (
                                <TableCell>
                                  <Chip
                                    label={changeStatusName(el.statusReport)}
                                    color="success"
                                  />
                                </TableCell>
                              )}
                              {el.statusReport === 'IN_PROGRESS' && (
                                <TableCell>
                                  <Chip
                                    label={changeStatusName(el.statusReport)}
                                    sx={{ backgroundColor: 'orange' }}
                                  />
                                </TableCell>
                              )}
                              {el.statusReport === 'NEW' && (
                                <TableCell>
                                  <Chip
                                    label={changeStatusName(el.statusReport)}
                                    color="primary"
                                  />
                                </TableCell>
                              )}

                              {user.users.role === 'ADMIN' ||
                              el.userId === user.users.id ? (
                                <TableCell align="center">
                                  <Box sx={{ display: 'flex' }}>
                                    {(el.statusReport === 'NEW' ||
                                      user.users.role === 'ADMIN') && (
                                      <Button
                                        sx={{ color: 'orange', p: '0' }}
                                        component={Link}
                                        to={`/edit-pz05/${el.id}`}
                                      >
                                        <EditIcon />
                                      </Button>
                                    )}
                                    {(el.statusReport === 'NEW' ||
                                      user.users.role === 'ADMIN') && (
                                      <Button
                                        sx={{ color: 'red', p: '0' }}
                                        onClick={() => handleSubmit(el.id)}
                                      >
                                        <DeleteIcon />
                                      </Button>
                                    )}
                                  </Box>
                                </TableCell>
                              ) : (
                                <TableCell />
                              )}
                            </TableRow>
                          );
                        }
                      } else if (dateTo !== undefined) {
                        if (new Date(el.createdAt) <= dateTo) {
                          return (
                            <TableRow
                              hover
                              key={el.id}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                                // '&:nth-of-type(odd)': { backgroundColor: '#fafafa' },
                              }}
                            >
                              <TableCell>
                                <Button
                                  sx={{ color: '#C09A53' }}
                                  component={Link}
                                  to={`/details-pz05/${el.id}`}
                                >
                                  <ReadMore />
                                </Button>
                              </TableCell>
                              <TableCell component="th">
                                {el.reportNumber}
                              </TableCell>
                              <TableCell>{el.warehouse}</TableCell>
                              <TableCell>{`${el.user.firstName} ${el.user.lastName}`}</TableCell>
                              <TableCell>
                                {dateFormatter(el.createdAt)}
                              </TableCell>
                              <TableCell>{el.product.materialNumber}</TableCell>
                              <TableCell>{el.product.description}</TableCell>
                              <TableCell>
                                {
                                  el.incompatibilitiesLists.inconformity
                                    .description
                                }
                              </TableCell>
                              {el.reportDetails.decisions.length > 1 ? (
                                handleDecisionMaker(el.reportDetails.decisions)
                              ) : (
                                <TableCell>{el.decisionMaker}</TableCell>
                              )}

                              {el.statusReport === 'DONE' && (
                                <TableCell>
                                  <Chip
                                    label={changeStatusName(el.statusReport)}
                                    color="success"
                                  />
                                </TableCell>
                              )}
                              {el.statusReport === 'IN_PROGRESS' && (
                                <TableCell>
                                  <Chip
                                    label={changeStatusName(el.statusReport)}
                                    sx={{ backgroundColor: 'orange' }}
                                  />
                                </TableCell>
                              )}
                              {el.statusReport === 'NEW' && (
                                <TableCell>
                                  <Chip
                                    label={changeStatusName(el.statusReport)}
                                    color="primary"
                                  />
                                </TableCell>
                              )}

                              {user.users.role === 'ADMIN' ||
                              el.userId === user.users.id ? (
                                <TableCell align="center">
                                  <Box sx={{ display: 'flex' }}>
                                    {(el.statusReport === 'NEW' ||
                                      user.users.role === 'ADMIN') && (
                                      <Button
                                        sx={{ color: 'orange', p: '0' }}
                                        component={Link}
                                        to={`/edit-pz05/${el.id}`}
                                      >
                                        <EditIcon />
                                      </Button>
                                    )}
                                    {(el.statusReport === 'NEW' ||
                                      user.users.role === 'ADMIN') && (
                                      <Button
                                        sx={{ color: 'red', p: '0' }}
                                        onClick={() => handleSubmit(el.id)}
                                      >
                                        <DeleteIcon />
                                      </Button>
                                    )}
                                  </Box>
                                </TableCell>
                              ) : (
                                <TableCell />
                              )}
                            </TableRow>
                          );
                        }
                      } else if (dateFrom !== undefined) {
                        if (new Date(el.createdAt) >= dateFrom) {
                          return (
                            <TableRow
                              hover
                              key={el.id}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                                // '&:nth-of-type(odd)': { backgroundColor: '#fafafa' },
                              }}
                            >
                              <TableCell>
                                <Button
                                  sx={{ color: '#C09A53' }}
                                  component={Link}
                                  to={`/details-pz05/${el.id}`}
                                >
                                  <ReadMore />
                                </Button>
                              </TableCell>
                              <TableCell component="th">
                                {el.reportNumber}
                              </TableCell>
                              <TableCell>{el.warehouse}</TableCell>
                              <TableCell>{`${el.user.firstName} ${el.user.lastName}`}</TableCell>
                              <TableCell>
                                {dateFormatter(el.createdAt)}
                              </TableCell>
                              <TableCell>{el.product.materialNumber}</TableCell>
                              <TableCell>{el.product.description}</TableCell>
                              <TableCell>
                                {
                                  el.incompatibilitiesLists.inconformity
                                    .description
                                }
                              </TableCell>
                              {el.reportDetails.decisions.length > 1 ? (
                                handleDecisionMaker(el.reportDetails.decisions)
                              ) : (
                                <TableCell>{el.decisionMaker}</TableCell>
                              )}

                              {el.statusReport === 'DONE' && (
                                <TableCell>
                                  <Chip
                                    label={changeStatusName(el.statusReport)}
                                    color="success"
                                  />
                                </TableCell>
                              )}
                              {el.statusReport === 'IN_PROGRESS' && (
                                <TableCell>
                                  <Chip
                                    label={changeStatusName(el.statusReport)}
                                    sx={{ backgroundColor: 'orange' }}
                                  />
                                </TableCell>
                              )}
                              {el.statusReport === 'NEW' && (
                                <TableCell>
                                  <Chip
                                    label={changeStatusName(el.statusReport)}
                                    color="primary"
                                  />
                                </TableCell>
                              )}

                              {user.users.role === 'ADMIN' ||
                              el.userId === user.users.id ? (
                                <TableCell align="center">
                                  <Box sx={{ display: 'flex' }}>
                                    {(el.statusReport === 'NEW' ||
                                      user.users.role === 'ADMIN') && (
                                      <Button
                                        sx={{ color: 'orange', p: '0' }}
                                        component={Link}
                                        to={`/edit-pz05/${el.id}`}
                                      >
                                        <EditIcon />
                                      </Button>
                                    )}
                                    {(el.statusReport === 'NEW' ||
                                      user.users.role === 'ADMIN') && (
                                      <Button
                                        sx={{ color: 'red', p: '0' }}
                                        onClick={() => handleSubmit(el.id)}
                                      >
                                        <DeleteIcon />
                                      </Button>
                                    )}
                                  </Box>
                                </TableCell>
                              ) : (
                                <TableCell />
                              )}
                            </TableRow>
                          );
                        }
                      } else {
                        return (
                          <TableRow
                            hover
                            key={el.id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                              // '&:nth-of-type(odd)': { backgroundColor: '#fafafa' },
                            }}
                          >
                            <TableCell>
                              <Button
                                sx={{ color: '#C09A53' }}
                                component={Link}
                                to={`/details-pz05/${el.id}`}
                              >
                                <ReadMore />
                              </Button>
                            </TableCell>
                            <TableCell component="th">
                              {el.reportNumber}
                            </TableCell>
                            <TableCell>{el.warehouse}</TableCell>
                            <TableCell>{`${el.user.firstName} ${el.user.lastName}`}</TableCell>
                            <TableCell>{dateFormatter(el.createdAt)}</TableCell>
                            <TableCell>{el.product.materialNumber}</TableCell>
                            <TableCell>{el.product.description}</TableCell>
                            <TableCell>
                              {
                                el.incompatibilitiesLists.inconformity
                                  .description
                              }
                            </TableCell>
                            {el.reportDetails.decisions.length > 1 ? (
                              handleDecisionMaker(el.reportDetails.decisions)
                            ) : (
                              <TableCell>{el.decisionMaker}</TableCell>
                            )}

                            {el.statusReport === 'DONE' && (
                              <TableCell>
                                <Chip
                                  label={changeStatusName(el.statusReport)}
                                  color="success"
                                />
                              </TableCell>
                            )}
                            {el.statusReport === 'IN_PROGRESS' && (
                              <TableCell>
                                <Chip
                                  label={changeStatusName(el.statusReport)}
                                  sx={{ backgroundColor: 'orange' }}
                                />
                              </TableCell>
                            )}
                            {el.statusReport === 'NEW' && (
                              <TableCell>
                                <Chip
                                  label={changeStatusName(el.statusReport)}
                                  color="primary"
                                />
                              </TableCell>
                            )}

                            {user.users.role === 'ADMIN' ||
                            el.userId === user.users.id ? (
                              <TableCell align="center">
                                <Box sx={{ display: 'flex' }}>
                                  {(el.statusReport === 'NEW' ||
                                    user.users.role === 'ADMIN') && (
                                    <Button
                                      sx={{ color: 'orange', p: '0' }}
                                      component={Link}
                                      to={`/edit-pz05/${el.id}`}
                                    >
                                      <EditIcon />
                                    </Button>
                                  )}
                                  {(el.statusReport === 'NEW' ||
                                    user.users.role === 'ADMIN') && (
                                    <Button
                                      sx={{ color: 'red', p: '0' }}
                                      onClick={() => handleSubmit(el.id)}
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  )}
                                </Box>
                              </TableCell>
                            ) : (
                              <TableCell />
                            )}
                          </TableRow>
                        );
                      }
                    }
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display="flex" justifyContent="center">
          <Pagination
            count={pageCount}
            page={activePage}
            onChange={handleChange}
          />
        </Box>
      </>
    );
  }
};

export default ReportListForm;
