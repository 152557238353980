import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import initialState from './initialState';
import thunk from 'redux-thunk';
import usersReducer from './usersRedux';
import sourcesReducer from './sourcesRedux';
import areasReducer from './areasRedux';
import unconformitiesReducer from './inconformitiesRedux';
import desNonsReducer from './desNonRedux';
import additionalDesReducer from './additionalDesRedux';
import reasonsReducer from './reasonRedux';
import brandsReducer from './brandsRedux';
import packagesReducer from './packagesRedux';
import beersReducer from './beersRedux';
import usersListReducer from './userListRedux';
import reportsPZ05Reducer from './reportsPZ05Redux';
const subreducers = {
  user: usersReducer,
  source: sourcesReducer,
  area: areasReducer,
  inconformity: unconformitiesReducer,
  desNon: desNonsReducer,
  additionalDes: additionalDesReducer,
  reason: reasonsReducer,
  brand: brandsReducer,
  packageBeer: packagesReducer,
  beer: beersReducer,
  userList: usersListReducer,
  reportPZ05: reportsPZ05Reducer,
};

const reducer = combineReducers(subreducers);

const store = createStore(
  reducer,
  initialState,
  compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f,
  ),
);

export default store;
