import { Box } from '@mui/material';
import EditSourceForm from '../../features/EditSourceForm/EditSourceForm';

const EditSource = () => {
  return (
    <Box>
      <EditSourceForm />
    </Box>
  );
};

export default EditSource;
