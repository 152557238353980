import { useDispatch, useSelector } from 'react-redux';
import { deleteReasonRequest } from '../../../redux/reasonRedux';
import { useEffect } from 'react';
import ReasonListForm from '../../common/ReasonListForm/ReasonListForm';
import { Box, Divider, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import { checkLoggedUser, getUser } from '../../../redux/usersRedux';
const ReasonList = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => getUser(state));
  useEffect(() => {
    const start = async () => {
      await dispatch(checkLoggedUser());
    };
    start();
  }, [dispatch]);

  const handleSubmit = async (id) => {
    await dispatch(deleteReasonRequest(id));
  };

  return (
    <Box>
      <h1>Lista przyczyn niezgodności</h1>
      {(user.users.role === 'ADMIN' ||
        user.users.role === 'KIER_DS_REKLAMACJI') && (
        <Button
          component={Link}
          to="/add-reason"
          sx={{ bgcolor: '#C09A53', ':hover': { bgcolor: '#9c7c43' } }}
          variant="contained"
          startIcon={<AddIcon />}
        >
          Dodaj nową przyczynę
        </Button>
      )}

      <Divider sx={{ width: '100%', mb: 5, mt: 5, bgcolor: '#C09A53' }} />

      <ReasonListForm action={handleSubmit} link="edit-reason" />
    </Box>
  );
};

export default ReasonList;
