import { useDispatch } from 'react-redux';
import ComplainsForm from '../../common/ComplainsForm/ComplainsForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  editDesNonRequest,
  loadDesNonByIdRequest,
} from '../../../redux/desNonRedux';

const EditDesNonForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const [area, setArea] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const start = async () => {
      const res = await dispatch(loadDesNonByIdRequest(id));
      setArea(res.data);
      setLoading(false);
    };
    start();
  }, [dispatch]);

  const handleSubmit = async (data) => {
    await dispatch(editDesNonRequest({ ...data, id }));
    navigate('/opis');
  };

  if (!loading) {
    return (
      <ComplainsForm
        action={handleSubmit}
        {...area}
        actionText="edycji opisu niezgodności"
        buttonText="Edytuj"
      />
    );
  }
};

export default EditDesNonForm;
