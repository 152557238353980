export const API_URL =
  process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:8000/api';

export const IMGS_URL =
  process.env.NODE_ENV === 'production'
    ? '/uploads/logo'
    : 'http://localhost:3000/uploads/logo';

export const ATT_URL =
  process.env.NODE_ENV === 'production'
    ? '/uploads/reports'
    : 'http://localhost:3000/uploads/reports';
