export function changeActionName(action) {
  switch (action) {
    case 'REPAIR':
      return 'Naprawa';
    case 'UTILIZATION':
      return 'Utylizacja';
    case 'MOVEMENT':
      return 'Przesunięcie';
    case 'SEGREGATION':
      return 'Sortowanie';
    case 'SALE':
      return 'Sprzedaż';
    case 'FIXED':
      return 'Naprawiono';
    case 'BROKEN':
      return 'Uszkodzone';
    case 'RELEASED':
      return 'Uwolnione';
    case 'DESTROYED':
      return 'Zutylizowano';
    case 'MOVED':
      return 'Przesunięto';
    case 'NOACTION':
      return 'Brak podjęcia działań w terminie 5 dni';
    case 'GOOD':
      return 'Prawidłowe';
    case 'INCORRECT':
      return 'Niezgodne';
    case 'LINE_SEGREGATION':
      return 'Segregacja na linii';
    default:
      return '';
  }
}
