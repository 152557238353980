export const dateFormatter = (date) => {
  const dateObj = new Date(date);
  //   const newDate = dateObj.toLocaleDateString('pl-PL');
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;
  const day = dateObj.getDate();

  return `${day < 10 ? '0' + day : day}.${
    month < 10 ? '0' + month : month
  }.${year}`;
};

export const dateFormatterWithTime = (date) => {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;
  const day = dateObj.getDate();
  const hour = dateObj.getHours();
  const minutes = dateObj.getMinutes();

  return `${day < 10 ? '0' + day : day}.${
    month < 10 ? '0' + month : month
  }.${year} ${hour < 10 ? '0' + hour : hour}:${
    minutes < 10 ? '0' + minutes : minutes
  }`;
};
