import { Box, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import ListSingleForm from '../../common/ListSingleForm/ListSingleForm';
import {
  deleteDesNonRequest,
  getDesNon,
  loadDesNonRequest,
} from '../../../redux/desNonRedux';
import AddDesNonForm from '../../features/AddDesNonForm/AddDesNonForm';

const AddDesNon = () => {
  const dispatch = useDispatch();
  const desNons = useSelector((state) => getDesNon(state));

  const handleSubmit = async (id) => {
    await dispatch(deleteDesNonRequest(id));
  };

  useEffect(() => {
    const start = async () => {
      await dispatch(loadDesNonRequest());
    };

    start();
  }, [dispatch]);

  return (
    <Box>
      <AddDesNonForm />
      <Divider sx={{ mt: 5 }} />
      {desNons.length > 0 && (
        <ListSingleForm
          action={handleSubmit}
          props={desNons}
          text="Opis niezgodności"
          link="edit-desNon"
        />
      )}
    </Box>
  );
};

export default AddDesNon;
