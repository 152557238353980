import { Box, Divider } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

import { useEffect } from 'react';

import ListSingleForm from '../../common/ListSingleForm/ListSingleForm';

import {
  deleteAdditionalDesRequest,
  getAdditionalDes,
  loadAdditionalDesRequest,
} from '../../../redux/additionalDesRedux';
import AddAdditionalDesForm from '../../features/AddAdditionalDesForm/AddAdditionalDesForm';

const AddAdditionalDes = () => {
  const dispatch = useDispatch();
  const additionalDes = useSelector((state) => getAdditionalDes(state));

  const handleSubmit = async (id) => {
    await dispatch(deleteAdditionalDesRequest(id));
  };

  useEffect(() => {
    const start = async () => {
      await dispatch(loadAdditionalDesRequest());
    };

    start();
  }, [dispatch]);

  return (
    <Box>
      <AddAdditionalDesForm />
      <Divider sx={{ mt: 5 }} />
      {additionalDes.length > 0 && (
        <ListSingleForm
          action={handleSubmit}
          props={additionalDes}
          text="Dodatkowy opis"
          link="edit-description"
        />
      )}
    </Box>
  );
};

export default AddAdditionalDes;
