import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
const NotFound = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h1>404</h1>
      <h2>Page not found</h2>
      <Button
        sx={{ bgcolor: '#C09A53', ':hover': { bgcolor: '#9c7c43' } }}
        variant="contained"
        compoment={Link}
        href="/"
      >
        Back to HOME
      </Button>
    </Box>
  );
};
export default NotFound;
