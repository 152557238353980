import { useDispatch, useSelector } from 'react-redux';
import {
  deleteUserListRequest,
  getUsersList,
  loadUsersListRequest,
} from '../../../redux/userListRedux';
import { useEffect } from 'react';
import {
  Box,
  Divider,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@mui/material';

import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
const UsersList = () => {
  const dispatch = useDispatch();

  const users = useSelector((state) => getUsersList(state));

  useEffect(() => {
    const start = async () => {
      await dispatch(loadUsersListRequest());
    };
    start();
  }, [dispatch]);

  const handleSubmit = async (id) => {
    await dispatch(deleteUserListRequest(id));
  };

  return (
    <Box>
      <h1>Lista użytkowników</h1>
      <Button
        component={Link}
        to="/add-user"
        sx={{ bgcolor: '#C09A53', ':hover': { bgcolor: '#9c7c43' } }}
        variant="contained"
        startIcon={<AddIcon />}
      >
        Dodaj nowego użytkownika
      </Button>
      <Divider sx={{ mt: 5, mb: 5 }} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="reason table">
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Uzytkownik</TableCell>
              <TableCell>Uprawnienia</TableCell>
              <TableCell>Magazyn</TableCell>
              <TableCell>Status konta</TableCell>
              <TableCell align="center">Akcja</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.length > 0 &&
              users.map((el) => (
                <TableRow
                  key={el.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th">{el.email}</TableCell>
                  <TableCell>{`${el.firstName} ${el.lastName}`}</TableCell>
                  <TableCell>{el.role}</TableCell>
                  <TableCell>{el.warehouse}</TableCell>
                  <TableCell>
                    {el.isConfirmed ? 'Aktywne' : 'Nieaktywne'}
                  </TableCell>
                  <TableCell align="center">
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        sx={{ color: 'orange' }}
                        component={Link}
                        to={`/edit-user/${el.id}`}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        sx={{ color: 'red' }}
                        onClick={() => handleSubmit(el.id)}
                      >
                        <DeleteIcon />
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default UsersList;
