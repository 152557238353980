import axios from 'axios';
import { API_URL } from '../config';
import initialState from './initialState';

export const getAreas = ({ area }) => area;
export const getAreaById = ({ area }, id) => area.find((el) => el.id === id);

const reducerName = 'areas';
const createActionName = (name) => `app/${reducerName}/${name}`;

const LOAD_AREAS = createActionName('LOAD_AREAS');
const ADD_AREA = createActionName('ADD_AREA');
const EDIT_AREA = createActionName('EDIT_AREA');
const DELETE_AREA = createActionName('DELETE_AREA');

export const loadAreas = (payload) => ({ payload, type: LOAD_AREAS });
export const addArea = (payload) => ({ payload, type: ADD_AREA });
export const editArea = (payload) => ({ payload, type: EDIT_AREA });
export const deleteArea = (payload) => ({ payload, type: DELETE_AREA });

export const loadAreasRequest = () => {
  return async (dispatch) => {
    try {
      let res = await axios.get(`${API_URL}/areas`, {
        withCredentials: true,
      });

      dispatch(loadAreas(res.data));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const loadAreaByIdRequest = (id) => {
  return async (dispatch) => {
    try {
      let res = await axios.get(`${API_URL}/areas/${id}`, {
        withCredentials: true,
      });

      dispatch(loadAreas(res.data));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const addAreaRequest = (data) => {
  return async (dispatch) => {
    try {
      let res = await axios.post(`${API_URL}/areas`, data, {
        withCredentials: true,
      });
      dispatch(addArea(res.data));
      return res;
    } catch (e) {
      return e.response;
    }
  };
};

export const editAreaRequest = (data) => {
  return async (dispatch) => {
    try {
      let res = await axios.put(`${API_URL}/areas/${data.id}`, data, {
        withCredentials: true,
      });
      dispatch(editArea(res.data));
    } catch (e) {
      return e.message.status;
    }
  };
};

export const deleteAreaRequest = (id) => {
  return async (dispatch) => {
    try {
      let res = await axios.delete(`${API_URL}/areas/${id}`, {
        withCredentials: true,
      });
      dispatch(deleteArea(id));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

const areasReducer = (statePart = initialState, action) => {
  switch (action.type) {
    case ADD_AREA:
      return [...statePart, action.payload];
    case EDIT_AREA:
      return statePart.map((el) =>
        el.id === action.payload.id ? action.payload : el,
      );
    case DELETE_AREA:
      return [...statePart.filter((el) => el.id !== action.payload)];
    case LOAD_AREAS:
      return action.payload;
    default:
      return statePart;
  }
};

export default areasReducer;
