import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSources, loadSourcesRequest } from '../../../redux/sourcesRedux';
import { getUser } from '../../../redux/usersRedux';
import ComplainsForm from '../../common/ComplainsForm/ComplainsForm';

const NonCompliances = () => {
  const user = useSelector((state) => getUser(state));
  const sources = useSelector((state) => getSources(state));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadSourcesRequest());
  });

  return <>{user.users !== null && <ComplainsForm />}</>;
};

export default NonCompliances;
