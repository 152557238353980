import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  editUserListRequest,
  loadUserListByIdRequest,
} from '../../../redux/userListRedux';
import { useDispatch } from 'react-redux';
import UserForm from '../../common/UserForm/UserForm';
import { Box, Snackbar, Alert, AlertTitle, Divider } from '@mui/material';

const EditUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState('');
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const [requestStatus, setRequestStatus] = useState('');
  useEffect(() => {
    const start = async () => {
      const res = await dispatch(loadUserListByIdRequest(id));

      if (res.status === 400) {
        navigate('/users');
      }

      setUser(res.data);
      setLoading(false);
    };
    start();
  }, [dispatch]);

  const handleSubmit = async (data) => {
    const res = await dispatch(editUserListRequest({ ...data, id }));

    setAlert(true);
    setRequestStatus(res.status);
    if (res.status === 200) {
      setTimeout(() => {
        navigate('/users');
      }, 2000);
    }
  };

  const handleClose = () => {
    setAlert(false);
  };

  if (!loading) {
    return (
      <Box>
        {requestStatus === 200 && (
          <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              variant="filled"
              onClose={handleClose}
              severity="success"
              sx={{ width: '100%' }}
            >
              <AlertTitle>Success</AlertTitle>
              Zaktualizowano dane
            </Alert>
          </Snackbar>
        )}
        {requestStatus === 404 && (
          <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              variant="filled"
              onClose={handleClose}
              severity="error"
              sx={{ width: '100%' }}
            >
              <AlertTitle>Error</AlertTitle>
              Użytkownik nie istnieje
            </Alert>
          </Snackbar>
        )}
        {requestStatus === 409 && (
          <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              variant="filled"
              onClose={handleClose}
              severity="warning"
              sx={{ width: '100%' }}
            >
              <AlertTitle>Error</AlertTitle>
              Adres email juz istnieje w bazie
            </Alert>
          </Snackbar>
        )}
        {requestStatus === 500 && (
          <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              variant="filled"
              onClose={handleClose}
              severity="error"
              sx={{ width: '100%' }}
            >
              <AlertTitle>Warning</AlertTitle>
              Błąd serwera
            </Alert>
          </Snackbar>
        )}
        <Box sx={{ width: '100%' }}>
          <h1>Formularz edycji użytkownika</h1>
          <Divider sx={{ width: '100%', mb: 3, bgcolor: '#C09A53' }} />
          <UserForm props={user} action={handleSubmit} />
        </Box>
      </Box>
    );
  }
};
export default EditUser;
