import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Divider } from '@mui/material';
import ReportListForm from '../../common/ReportListForm/ReportListForm';
import { checkLoggedUser, getUser } from '../../../redux/usersRedux';

const ReportList = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => getUser(state));

  useEffect(() => {
    const start = async () => {
      await dispatch(checkLoggedUser());
    };
    start();
  }, [dispatch]);

  return (
    <Box>
      <h1 style={{ fontStyle: 'italic' }}>Lista niezgodności PZ05</h1>
      <Divider sx={{ width: '100%', mb: 5, mt: 5, bgcolor: '#C09A53' }} />
      <ReportListForm />
    </Box>
  );
};

export default ReportList;
