import axios from 'axios';
import { API_URL } from '../config';
import initialState from './initialState';

export const getAdditionalDes = ({ additionalDes }) => additionalDes;

const reducerName = 'additionalDes';
const createActionName = (name) => `app/${reducerName}/${name}`;

const LOAD_ADDITIONAL_DES = createActionName('LOAD_ADDITIONAL_DES');
const ADD_ADDITIONAL_DES = createActionName('ADD_ADDITIONAL_DES');
const EDIT_ADDITIONAL_DES = createActionName('EDIT_ADDITIONAL_DES');
const DELETE_ADDITIONAL_DES = createActionName('DELETE_ADDITIONAL_DES');

export const loadAdditionalDes = (payload) => ({
  payload,
  type: LOAD_ADDITIONAL_DES,
});
export const addAdditionalDes = (payload) => ({
  payload,
  type: ADD_ADDITIONAL_DES,
});
export const editAdditionalDes = (payload) => ({
  payload,
  type: EDIT_ADDITIONAL_DES,
});
export const deleteAdditionalDes = (payload) => ({
  payload,
  type: DELETE_ADDITIONAL_DES,
});

export const loadAdditionalDesRequest = () => {
  return async (dispatch) => {
    try {
      let res = await axios.get(`${API_URL}/additional-des`, {
        withCredentials: true,
      });

      dispatch(loadAdditionalDes(res.data));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const loadAdditionalDesByIdRequest = (id) => {
  return async (dispatch) => {
    try {
      let res = await axios.get(`${API_URL}/additional-des/${id}`, {
        withCredentials: true,
      });

      dispatch(loadAdditionalDes(res.data));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const addAdditionalDesRequest = (data) => {
  return async (dispatch) => {
    try {
      let res = await axios.post(`${API_URL}/additional-des`, data, {
        withCredentials: true,
      });
      dispatch(addAdditionalDes(res.data));
      return res;
    } catch (e) {
      return e.response;
    }
  };
};

export const editAdditionalDesRequest = (data) => {
  return async (dispatch) => {
    try {
      let res = await axios.put(`${API_URL}/additional-des/${data.id}`, data, {
        withCredentials: true,
      });
      dispatch(editAdditionalDes(res.data));
    } catch (e) {
      return e.message.status;
    }
  };
};

export const deleteAdditionalDesRequest = (id) => {
  return async (dispatch) => {
    try {
      let res = await axios.delete(`${API_URL}/additional-des/${id}`, {
        withCredentials: true,
      });
      dispatch(deleteAdditionalDes(id));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

const additionalDesReducer = (statePart = initialState, action) => {
  switch (action.type) {
    case ADD_ADDITIONAL_DES:
      return [...statePart, action.payload];
    case EDIT_ADDITIONAL_DES:
      return statePart.map((el) =>
        el.id === action.payload.id ? action.payload : el,
      );
    case DELETE_ADDITIONAL_DES:
      return [...statePart.filter((el) => el.id !== action.payload)];
    case LOAD_ADDITIONAL_DES:
      return action.payload;
    default:
      return statePart;
  }
};

export default additionalDesReducer;
