import {
  TableHead,
  TableCell,
  TableRow,
  Button,
  TableContainer,
  Table,
  Paper,
  TableBody,
  Box,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getReasons, loadReasonsRequest } from '../../../redux/reasonRedux';
import { useEffect, useState } from 'react';
import { checkLoggedUser, getUser } from '../../../redux/usersRedux';
const ReasonListForm = ({ action, link }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const reasons = useSelector((state) => getReasons(state));
  const user = useSelector((state) => getUser(state));
  useEffect(() => {
    const start = async () => {
      await dispatch(loadReasonsRequest());
      await dispatch(checkLoggedUser());
      setLoading(false);
    };
    start();
  }, [dispatch]);
  const handleSubmit = (id) => {
    action(id);
  };

  if (!loading) {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="reason table">
          <TableHead>
            <TableRow>
              <TableCell>Pochodzenie</TableCell>
              <TableCell>Obszar</TableCell>
              <TableCell>Rodzaj</TableCell>
              <TableCell>Opis</TableCell>
              <TableCell>Dodatkowy opis</TableCell>
              <TableCell>Decydent</TableCell>
              {(user.users.role === 'ADMIN' ||
                user.users.role === 'KIER_DS_REKLAMACJI') && (
                <TableCell align="center">Akcja</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {reasons &&
              reasons.map((el) => (
                <TableRow
                  key={el.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th">{el.source.description}</TableCell>
                  <TableCell>{el.area.description}</TableCell>
                  <TableCell>{el.inconformity.description}</TableCell>
                  <TableCell>{el.desNonCompliance.description}</TableCell>
                  <TableCell>{el.additionalDes.description}</TableCell>
                  <TableCell>{el.decisionMaker}</TableCell>
                  {(user.users.role === 'ADMIN' ||
                    user.users.role === 'KIER_DS_REKLAMACJI') && (
                    <TableCell align="center">
                      <Box sx={{ display: 'flex' }}>
                        <Button
                          sx={{ color: 'orange' }}
                          component={Link}
                          to={`/${link}/${el.id}`}
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          sx={{ color: 'red' }}
                          onClick={() => handleSubmit(el.id)}
                        >
                          <DeleteIcon />
                        </Button>
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
};

export default ReasonListForm;

//source, area, inconformity, desNonCompliance, additionalDes, decisionMaker
