import { Box } from '@mui/material';

import EditPackageForm from '../../features/EditPackageForm/EditPackageForm';

const EditPackage = () => {
  return (
    <Box>
      <EditPackageForm />
    </Box>
  );
};

export default EditPackage;
