import { Divider } from '@mui/material';
import NonCompliances from '../../features/NonCompliances/NonCompliances';

const NonCompliance = () => {
  return (
    <>
      <h1>Lista Niezgodności</h1>
      <Divider sx={{ width: '100%' }} />
      <NonCompliances />
    </>
  );
};

export default NonCompliance;
