import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  editReasonRequest,
  loadReasonsByIdRequest,
} from '../../../redux/reasonRedux';

import ReasonForm from '../../common/ReasonForm/ReasonForm';
import { Box, Snackbar, Alert, AlertTitle } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
const EditReason = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const [requestStatus, setRequestStatus] = useState('');
  const [alert, setAlert] = useState(false);
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const start = async () => {
      const res = await dispatch(loadReasonsByIdRequest(id));
      setReason(res.data);
      setLoading(false);
    };
    start();
  }, [dispatch]);

  const handleSubmit = async (data) => {
    setAlert(true);

    const res = await dispatch(editReasonRequest({ ...data, id }));
    console.log(res);

    setRequestStatus(res.status);

    if (res.status === 200) {
      setTimeout(() => {
        navigate('/przyczyny');
      }, 2000);
    }
  };

  const handleClose = () => {
    setAlert(false);
  };

  if (!loading) {
    return (
      <Box>
        {requestStatus === 200 && (
          <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              variant="filled"
              onClose={handleClose}
              severity="success"
              sx={{ width: '100%' }}
            >
              <AlertTitle>Success</AlertTitle>
              Pomyślnie zaaktualizowano
            </Alert>
          </Snackbar>
        )}
        {requestStatus === 409 && (
          <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              variant="filled"
              onClose={handleClose}
              severity="warning"
              sx={{ width: '100%' }}
            >
              <AlertTitle>Warning</AlertTitle>
              Dane juz istnieją
            </Alert>
          </Snackbar>
        )}
        {requestStatus === 500 && (
          <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              variant="filled"
              onClose={handleClose}
              severity="error"
              sx={{ width: '100%' }}
            >
              <AlertTitle>Warning</AlertTitle>
              Błąd serwera
            </Alert>
          </Snackbar>
        )}
        <ReasonForm
          action={handleSubmit}
          props={reason}
          actionText="edycji przyczyny niezgodności"
          buttonText="Edytuj"
        />
      </Box>
    );
  }
};
export default EditReason;
