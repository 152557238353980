import { Box } from '@mui/material';
import EditAdditionalDesForm from '../../features/EditAdditionalDesForm/EditAdditionalDesForm';

const EditAdditionalDes = () => {
  return (
    <Box>
      <EditAdditionalDesForm />
    </Box>
  );
};

export default EditAdditionalDes;
