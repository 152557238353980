import axios from 'axios';
import { API_URL } from '../config';
import initialState from './initialState';

export const getUnconformities = ({ inconformity }) => inconformity;
export const getInconformityById = ({ inconformity }, id) =>
  inconformity.find((el) => el.id === id);

const reducerName = 'unconformities';
const createActionName = (name) => `app/${reducerName}/${name}`;

const LOAD_UNCONFORMITIES = createActionName('LOAD_UNCONFORMITIES');
const ADD_INCONFORMITY = createActionName('ADD_INCONFORMITY');
const EDIT_INCONFORMITY = createActionName('EDIT_INCONFORMITY');
const DELETE_INCONFORMITY = createActionName('DELETE_INCONFORMITY');

export const loadUnconformities = (payload) => ({
  payload,
  type: LOAD_UNCONFORMITIES,
});
export const addInconformity = (payload) => ({
  payload,
  type: ADD_INCONFORMITY,
});
export const editInconformity = (payload) => ({
  payload,
  type: EDIT_INCONFORMITY,
});
export const deleteInconformity = (payload) => ({
  payload,
  type: DELETE_INCONFORMITY,
});

export const loadUnconformitiesRequest = () => {
  return async (dispatch) => {
    try {
      let res = await axios.get(`${API_URL}/inconformity`, {
        withCredentials: true,
      });

      dispatch(loadUnconformities(res.data));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const loadInconformityByIdRequest = (id) => {
  return async (dispatch) => {
    try {
      let res = await axios.get(`${API_URL}/inconformity/${id}`, {
        withCredentials: true,
      });

      dispatch(loadUnconformities(res.data));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const addInconformityRequest = (data) => {
  return async (dispatch) => {
    try {
      let res = await axios.post(`${API_URL}/inconformity`, data, {
        withCredentials: true,
      });
      dispatch(addInconformity(res.data));
      return res;
    } catch (e) {
      return e.response;
    }
  };
};

export const editInconformityRequest = (data) => {
  return async (dispatch) => {
    try {
      let res = await axios.put(`${API_URL}/inconformity/${data.id}`, data, {
        withCredentials: true,
      });
      dispatch(editInconformity(res.data));
    } catch (e) {
      return e.message.status;
    }
  };
};

export const deleteInconformityRequest = (id) => {
  return async (dispatch) => {
    try {
      let res = await axios.delete(`${API_URL}/inconformity/${id}`, {
        withCredentials: true,
      });
      dispatch(deleteInconformity(id));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

const unconformitiesReducer = (statePart = initialState, action) => {
  switch (action.type) {
    case ADD_INCONFORMITY:
      return [...statePart, action.payload];
    case EDIT_INCONFORMITY:
      return statePart.map((el) =>
        el.id === action.payload.id ? action.payload : el,
      );
    case DELETE_INCONFORMITY:
      return [...statePart.filter((el) => el.id !== action.payload)];
    case LOAD_UNCONFORMITIES:
      return action.payload;
    default:
      return statePart;
  }
};

export default unconformitiesReducer;
