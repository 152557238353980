import { Box } from '@mui/material';

import EditInconformityForm from '../../features/EditInconformityForm/EditInconformityForm';

const EditInconformity = () => {
  return (
    <Box>
      <EditInconformityForm />
    </Box>
  );
};

export default EditInconformity;
