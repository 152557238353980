const initialState = {
  users: null,
  requests: [],
  sources: [],
  areas: [],
  unconformities: [],
  desNons: [],
  additionalDes: [],
  reasons: [],
  brand: [],
  packagesBeer: [],
  beers: [],
  usersList: [],
  reportsPZ05: [],
};

export default initialState;
