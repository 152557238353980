import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addReasonRequest } from '../../../redux/reasonRedux';

import ReasonForm from '../../common/ReasonForm/ReasonForm';
import { Box, Snackbar, Alert, AlertTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const AddReason = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [requestStatus, setRequestStatus] = useState('');
  const [alert, setAlert] = useState(false);

  const handleSubmit = async (data) => {
    setAlert(true);

    const res = await dispatch(addReasonRequest(data));
    setRequestStatus(res.status);

    if (res.status === 201) {
      setTimeout(() => {
        navigate('/przyczyny');
      }, 2000);
    }
  };

  const handleClose = () => {
    setAlert(false);
  };

  return (
    <Box>
      {requestStatus === 201 && (
        <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            variant="filled"
            onClose={handleClose}
            severity="success"
            sx={{ width: '100%' }}
          >
            <AlertTitle>Success</AlertTitle>
            Pomyślnie dodano
          </Alert>
        </Snackbar>
      )}
      {requestStatus === 409 && (
        <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            variant="filled"
            onClose={handleClose}
            severity="warning"
            sx={{ width: '100%' }}
          >
            <AlertTitle>Warning</AlertTitle>
            Dane juz istnieją
          </Alert>
        </Snackbar>
      )}
      {requestStatus === 500 && (
        <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            variant="filled"
            onClose={handleClose}
            severity="error"
            sx={{ width: '100%' }}
          >
            <AlertTitle>Warning</AlertTitle>
            Błąd serwera
          </Alert>
        </Snackbar>
      )}
      <ReasonForm
        action={handleSubmit}
        actionText="tworzenia nowej przyczyny"
        buttonText="Dodaj"
        prev="/przyczyny"
      />
    </Box>
  );
};
export default AddReason;
