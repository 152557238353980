import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { confirmationUser } from '../../../redux/usersRedux';
import { Box, Snackbar, Alert, AlertTitle } from '@mui/material';

const ConfirmationAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const confirmAccount = async () => {
      const res = await dispatch(confirmationUser(token));
      if (res.status === 200) {
        setSuccess(true);

        setTimeout(() => {
          navigate('/');
        }, 2000);
      } else {
        setError(true);
      }
    };
    confirmAccount();
  }, [dispatch]);

  const handleClose = () => {
    setSuccess(false);
    setError(false);
  };

  return (
    <Box>
      {success && (
        <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            variant="filled"
            onClose={handleClose}
            severity="success"
            sx={{ width: '100%' }}
          >
            <AlertTitle>Success</AlertTitle>
            Twoje konto zostało aktywowane
          </Alert>
        </Snackbar>
      )}
      {error && (
        <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            variant="filled"
            onClose={handleClose}
            severity="error"
            sx={{ width: '100%' }}
          >
            <AlertTitle>Error</AlertTitle>
            Nie udało się aktywować konta
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default ConfirmationAccount;
