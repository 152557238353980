import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { checkLoggedUser, getUser } from '../../../redux/usersRedux';
import {
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  CircularProgress,
  Pagination,
  Select,
  MenuItem,
} from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getBeers, loadBeersRequest } from '../../../redux/beersRedux';
const BeerListForm = ({ action, link }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [itemsPage, setItemsPage] = useState(30);
  const [pageCount, setPageCount] = useState(0);
  const user = useSelector((state) => getUser(state));
  const beers = useSelector((state) => getBeers(state));

  useEffect(() => {
    const start = async () => {
      await dispatch(checkLoggedUser());
      await dispatch(loadBeersRequest());
      setLoading(false);
    };
    start();
  }, [dispatch]);

  const handleSubmit = (id) => {
    action(id);
  };

  const handleChange = (event, value) => {
    setActivePage(value);
  };

  useEffect(() => {
    setPageCount(Math.ceil(beers.length / itemsPage));
  }, [beers, itemsPage]);

  if (!loading) {
    return (
      <>
        <Select
          sx={{ mb: 3 }}
          labelId="select-count"
          id="select-page-count"
          value={itemsPage}
          label="Ilość na stronie"
          onChange={(e) => setItemsPage(e.target.value)}
        >
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="beer table">
            <TableHead>
              <TableRow>
                <TableCell>Indeks</TableCell>
                <TableCell>Nazwa</TableCell>
                <TableCell>Trwałość</TableCell>
                <TableCell>Ilość/PAL</TableCell>
                <TableCell>HL/cas</TableCell>
                <TableCell>Opakowanie</TableCell>
                <TableCell>Marka</TableCell>
                {(user.users.role === 'ADMIN' ||
                  user.users.role === 'KONTROLER') && (
                  <TableCell align="center">Akcja</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {beers &&
                beers
                  .slice((activePage - 1) * itemsPage, activePage * itemsPage)
                  .map((el) => (
                    <TableRow
                      key={el.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th">{el.materialNumber}</TableCell>
                      <TableCell>{el.description}</TableCell>
                      <TableCell>{el.shelfLife}</TableCell>
                      <TableCell>{el.palletQty}</TableCell>
                      <TableCell>{el.casHL}</TableCell>
                      <TableCell>{el.package.description}</TableCell>
                      <TableCell>{el.brand.description}</TableCell>
                      {(user.users.role === 'ADMIN' ||
                        user.users.role === 'KONTROLER') && (
                        <TableCell align="center">
                          <Box sx={{ display: 'flex' }}>
                            <Button
                              sx={{ color: 'orange' }}
                              component={Link}
                              to={`/${link}/${el.id}`}
                            >
                              <EditIcon />
                            </Button>
                            <Button
                              sx={{ color: 'red' }}
                              onClick={() => handleSubmit(el.id)}
                            >
                              <DeleteIcon />
                            </Button>
                          </Box>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display="flex" justifyContent="center">
          <Pagination
            count={pageCount}
            page={activePage}
            onChange={handleChange}
          />
        </Box>
      </>
    );
  } else {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',

          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
};

export default BeerListForm;
