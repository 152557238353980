import { useDispatch } from 'react-redux';
import ComplainsForm from '../../common/ComplainsForm/ComplainsForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import {
  editAreaRequest,
  loadAreaByIdRequest,
} from '../../../redux/areasRedux';

const EditAreaForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const [area, setArea] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const start = async () => {
      const res = await dispatch(loadAreaByIdRequest(id));
      setArea(res.data);
      setLoading(false);
    };
    start();
  }, [dispatch]);

  const handleSubmit = async (data) => {
    await dispatch(editAreaRequest({ ...data, id }));
    navigate('/obszar');
  };

  if (!loading) {
    return (
      <ComplainsForm
        action={handleSubmit}
        {...area}
        actionText="edycji obszaru"
        buttonText="Edytuj"
      />
    );
  }
};

export default EditAreaForm;
