import { Box } from '@mui/material';

import EditAreaForm from '../../features/EditAreaForm/EditAreaForm';

const EditArea = () => {
  return (
    <Box>
      <EditAreaForm />
    </Box>
  );
};

export default EditArea;
