import { Box, Divider } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

import { useEffect } from 'react';

import ListSingleForm from '../../common/ListSingleForm/ListSingleForm';

import AddBrandForm from '../../features/AddBrandForm/AddBrandForm';
import {
  deleteBrandRequest,
  getBrands,
  loadBrandsRequest,
} from '../../../redux/brandsRedux';

const AddBrand = () => {
  const dispatch = useDispatch();
  const brands = useSelector((state) => getBrands(state));

  const handleSubmit = async (id) => {
    await dispatch(deleteBrandRequest(id));
  };

  useEffect(() => {
    const start = async () => {
      await dispatch(loadBrandsRequest());
    };

    start();
  }, [dispatch]);

  return (
    <Box>
      <AddBrandForm />
      <Divider sx={{ mt: 5 }} />
      {brands.length > 0 && (
        <ListSingleForm
          action={handleSubmit}
          props={brands}
          text="Marka produktu"
          link="edit-brand"
        />
      )}
    </Box>
  );
};

export default AddBrand;
