import ReportForm from '../../common/ReportForm/ReportForm';
import { useDispatch } from 'react-redux';

import {
  addAttachmentsToReportPZ05Request,
  addReportPZ05Request,
  sendCreateMail,
} from '../../../redux/reportsPZ05Redux';
import { Box, Snackbar, Alert, AlertTitle } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AddReportPZ05 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [requestStatus, setRequestStatus] = useState('');
  const [alert, setAlert] = useState(false);

  const handleSubmit = async (data) => {
    setAlert(true);
    const { files, ...otherData } = data;

    const res = await dispatch(addReportPZ05Request(otherData));

    setRequestStatus(res.status);
    if (!files || files.length > 0) {
      await dispatch(
        addAttachmentsToReportPZ05Request({ files, reportId: res.data.id }),
      );
    }
    console.log(res);
    if (res.status === 201) {
      await dispatch(sendCreateMail({ id: res.data.id }));
    }

    setTimeout(() => {
      navigate(`/details-pz05/${res.data.id}`);
    }, 2000);
  };

  const handleClose = () => {
    setAlert(false);
  };

  return (
    <Box>
      {requestStatus === 201 && (
        <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            variant="filled"
            onClose={handleClose}
            severity="success"
            sx={{ width: '100%' }}
          >
            <AlertTitle>Success</AlertTitle>
            Pomyślnie dodano niezgodność
          </Alert>
        </Snackbar>
      )}
      {requestStatus === 500 && (
        <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            variant="filled"
            onClose={handleClose}
            severity="error"
            sx={{ width: '100%' }}
          >
            <AlertTitle>Error</AlertTitle>
            Błąd serwera
          </Alert>
        </Snackbar>
      )}
      <ReportForm action={handleSubmit} actionText="tworzenia niezgodności" />
    </Box>
  );
};

export default AddReportPZ05;
