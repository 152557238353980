export function changeStatusName(status) {
  switch (status) {
    case 'NEW':
      return 'Nowy';
    case 'IN_PROGRESS':
      return 'W trakcie';
    case 'DONE':
      return 'Zakończony';
    case 'CANCELED':
      return 'Anulowany';

    default:
      return '';
  }
}
