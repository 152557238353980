import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkLoggedUser,
  getUser,
  registerUser,
} from '../../../redux/usersRedux';
import { Box, Divider, Snackbar, Alert, AlertTitle } from '@mui/material';
import UserForm from '../../common/UserForm/UserForm';
import { useNavigate } from 'react-router-dom';

const AddUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => getUser(state));
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState(user !== null ? user.users.role : '');
  const [requestStatus, setRequestStatus] = useState('');
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    const start = async () => {
      await dispatch(checkLoggedUser());
      setLoading(false);
      // setRole(user.users.role);
    };
    start();
  }, [dispatch]);
  const handleSubmit = async (data) => {
    console.log(data);
    if (data.role === '') {
      data = {
        ...data,
        role: role,
        warehouse:
          data.warehouse === '' ? user.users.warehouse : data.warehouse,
      };
    }

    if (role !== 'ADMIN' && data.role !== role) {
      data = {
        ...data,
        role: role,
        warehouse:
          data.warehouse === '' ? user.users.warehouse : data.warehouse,
      };
    }

    const res = await dispatch(registerUser(data));
    setAlert(true);

    setRequestStatus(res.status);

    if (res.status === 201) {
      setTimeout(() => {
        navigate(-1);
      }, 2000);
    }
  };

  const handleClose = () => {
    setAlert(false);
  };

  if (!loading) {
    return (
      <Box>
        {requestStatus === 201 && (
          <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              variant="filled"
              onClose={handleClose}
              severity="success"
              sx={{ width: '100%' }}
            >
              <AlertTitle>Success</AlertTitle>
              Użytkownik dodany
            </Alert>
          </Snackbar>
        )}
        {requestStatus === 409 && (
          <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              variant="filled"
              onClose={handleClose}
              severity="warning"
              sx={{ width: '100%' }}
            >
              <AlertTitle>Warning</AlertTitle>
              Dane już istnieją
            </Alert>
          </Snackbar>
        )}
        {requestStatus === 403 && (
          <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              variant="filled"
              onClose={handleClose}
              severity="warning"
              sx={{ width: '100%' }}
            >
              <AlertTitle>Warning</AlertTitle>
              Nieprawidłowe dane
            </Alert>
          </Snackbar>
        )}
        {requestStatus === 500 && (
          <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              variant="filled"
              onClose={handleClose}
              severity="error"
              sx={{ width: '100%' }}
            >
              <AlertTitle>Warning</AlertTitle>
              Błąd serwera
            </Alert>
          </Snackbar>
        )}
        <h1>Formularz dodawania użytkownika</h1>
        <Divider sx={{ width: '100%', mb: 5, mt: 5, bgcolor: '#C09A53' }} />
        <UserForm action={handleSubmit} actionStatus={true} />
      </Box>
    );
  }
};
export default AddUser;
