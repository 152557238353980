import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  IconButton,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Collapse,
  Drawer,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '../../../redux/usersRedux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { IMGS_URL } from '../../../config';

const drawerWidth = 240;
const pz05Setting = [
  {
    href: '/pz05',
    label: 'Lista niezgodności PZ05',
  },
  {
    href: 'add-pz05',
    label: 'Dodaj niezgodność PZ05',
  },
  {
    href: '/pz05/raport',
    label: 'Raport PZ05',
  },
];

const masterSetting = [
  {
    label: 'Piwo',
    subSetting: [
      { href: '/marka', label: 'Marka' },
      { href: '/opakowanie', label: 'Opakowanie' },
      { href: '/beer', label: 'Lista piw' },
    ],
  },
  {
    label: 'Niezgodności',
    subSetting: [
      { href: '/pochodzenie', label: 'Pochodzenie' },
      { href: '/obszar', label: 'Obszar' },
      { href: '/rodzaj', label: 'Rodzaj' },
      { href: '/opis', label: 'Opis' },
      { href: '/dodatkowy-opis', label: 'Dodatkowy opis' },
      { href: '/przyczyny', label: 'Przyczyny niezgodności' },
    ],
  },
];
const userSettings = [
  { href: '/account', label: 'Moje konto' },
  { href: '/users', label: 'Użytkownicy' },
  { href: '/logout', label: 'Wyloguj' },
];

function ResponsiveDrawer({ children }, props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [subOpen, setSubOpen] = useState(false);
  const [beerOpen, setBeerOpen] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [rejestrOpen, setRejestrOpen] = useState(false);
  const user = useSelector((state) => getUser(state));
  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleSubOpen = (rodzaj) => {
    if (rodzaj === 'Piwo') {
      setBeerOpen(!beerOpen);
    } else if (rodzaj === 'Niezgodności') {
      setSubOpen(!subOpen);
    }
  };

  const handleMobileMenuOpen = () => {
    setUserOpen(!userOpen);
  };

  const handleRejestrOpen = () => {
    setRejestrOpen(!rejestrOpen);
  };

  const handleAction = (action) => {
    navigate(action);
  };

  const drawer = (
    <div style={{ background: '#C09A53 ', color: 'white' }}>
      <Toolbar>
        <Box
          sx={{
            display: { xs: 'flex', md: 'flex' },
            mr: 1,
            width: '100px',
            height: '100%',
          }}
        >
          <Box
            component="img"
            src={`${IMGS_URL}/logo.png`}
            alt="logo"
            sx={{ maxHeight: '100%', maxWidth: '100%' }}
          />
        </Box>
        <Typography sx={{ mt: 1, fontWeight: 700, fontSize: '25px' }}>
          AMBF
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => handleAction('/')}>
            Home
          </ListItemButton>
        </ListItem>
        {user.users !== null && user.users.role !== 'ROZLEW' && (
          <>
            <ListItem disablePadding>
              <ListItemButton onClick={handleRejestrOpen}>
                Rejestr PZ05
                {rejestrOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            <Collapse in={rejestrOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {pz05Setting &&
                  pz05Setting.map((el) => {
                    if (
                      user.users.role === 'ADMIN' ||
                      user.users.role === 'KONTROLER' ||
                      el.label === 'Lista niezgodności PZ05' ||
                      el.label === 'Raport PZ05'
                    ) {
                      return (
                        <ListItemButton
                          key={el.label}
                          sx={{ pl: 4 }}
                          onClick={() => handleAction(el.href)}
                        >
                          <ListItemText primary={el.label} />
                        </ListItemButton>
                      );
                    }
                  })}
              </List>
            </Collapse>
          </>
        )}
        {user.users !== null &&
          (user.users.role === 'ADMIN' ||
            user.users.role === 'KIER_DS_REKLAMACJI' ||
            user.users.role === 'KONTROLER') && (
            <>
              <ListItem disablePadding>
                <ListItemButton onClick={handleOpen}>
                  MasterData {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </ListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {masterSetting.map((el) => (
                    <>
                      <ListItemButton
                        key={el.label}
                        sx={{ pl: 4 }}
                        onClick={() => handleSubOpen(el.label)}
                      >
                        <ListItemText primary={el.label} />
                        {(el.label === 'Piwo' ? beerOpen : subOpen) ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItemButton>

                      {el.subSetting && (
                        <Collapse
                          in={el.label === 'Piwo' ? beerOpen : subOpen}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {el.subSetting.map((subEl) => {
                              if (
                                user.users.role === 'ADMIN' ||
                                subEl.label === 'Lista piw' ||
                                subEl.label === 'Przyczyny niezgodności'
                              ) {
                                return (
                                  <ListItemButton
                                    key={subEl.label}
                                    sx={{
                                      pl: 8,
                                    }}
                                    onClick={() => handleAction(subEl.href)}
                                  >
                                    <ListItemText primary={subEl.label} />
                                  </ListItemButton>
                                );
                              }
                            })}
                          </List>
                        </Collapse>
                      )}
                    </>
                  ))}
                </List>
              </Collapse>
            </>
          )}
        {user.users !== null && (
          <>
            <ListItem disablePadding>
              <ListItemButton onClick={handleMobileMenuOpen}>
                {`${user.users.firstName} ${user.users.lastName}`}
                {userOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            <Collapse in={userOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {userSettings &&
                  userSettings.map((el) => {
                    if (
                      user.users.role === 'ADMIN' ||
                      el.label === 'Moje konto' ||
                      el.label === 'Wyloguj'
                    ) {
                      return (
                        <ListItemButton
                          key={el.label}
                          sx={{ pl: 4 }}
                          onClick={() => handleAction(el.href)}
                        >
                          <ListItemText primary={el.label} />
                        </ListItemButton>
                      );
                    }
                  })}
              </List>
            </Collapse>
          </>
        )}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  if (user.users === null) {
    return (
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          margin: 'auto',
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {children}
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar sx={{ bgcolor: '#C09A53' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mt: 2, mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: { xs: 'flex', md: 'none' },
              mt: 2,
              mr: 1,
              width: '100px',
              height: '100%',
            }}
          >
            <Box
              component="img"
              src={`${IMGS_URL}/logo.png`}
              alt="logo"
              sx={{ maxHeight: '100%', maxWidth: '100%' }}
            />
          </Box>
          <Typography
            sx={{ display: { xs: 'block', md: 'none' }, mt: 3 }}
            variant="h6"
            noWrap
            component="div"
          >
            AMBF
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="div"
        sx={{
          flexGrow: 1,
          m: 'auto',
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          maxWidth: '1280px',
        }}
      >
        <Toolbar />

        {children}
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;
