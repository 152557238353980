import { Box } from '@mui/material';

import EditBrandForm from '../../features/EditBrandForm/EditBrandForm';

const EditBrand = () => {
  return (
    <Box>
      <EditBrandForm />
    </Box>
  );
};

export default EditBrand;
