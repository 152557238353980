import { Box } from '@mui/material';
import EditDesNonForm from '../../features/EditDesNonForm/EditDesNonForm';

const EditDesNon = () => {
  return (
    <Box>
      <EditDesNonForm />
    </Box>
  );
};

export default EditDesNon;
