import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { loadReportPZ05ByIdRequest } from '../../../redux/reportsPZ05Redux';
import DetailPZ05Form from '../../common/DetailPZ05Form/DetailPZ05Form';

const DetailsPZ05 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [report, setReport] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const start = async () => {
      const res = await dispatch(loadReportPZ05ByIdRequest(id));
      if (res) {
        setReport(res.data);
        setLoading(false);
      } else {
        navigate('/pz05');
      }
    };
    start();
  }, [dispatch]);

  if (!loading) {
    return <DetailPZ05Form props={report} />;
  }
};
export default DetailsPZ05;
