import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  editBeerRequest,
  loadBeerByIdRequest,
} from '../../../redux/beersRedux';
import { Box, Snackbar, Alert, AlertTitle } from '@mui/material';
import BeerForm from '../../common/BeerForm/BeerForm';

const EditBeer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const [requestStatus, setRequestStatus] = useState('');
  const [alert, setAlert] = useState(false);
  const [beer, setBeer] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const start = async () => {
      const res = await dispatch(loadBeerByIdRequest(id));
      if (res) {
        setBeer(res.data);
        setLoading(false);
      } else {
        navigate('/beer');
      }
    };
    start();
  }, [dispatch]);

  const handleSubmit = async (data) => {
    setAlert(true);

    const res = await dispatch(editBeerRequest({ ...data, id }));
    console.log(res);
    setRequestStatus(res.status);

    setTimeout(() => {
      navigate('/beer');
    }, 2000);
  };

  const handleClose = () => {
    setAlert(false);
  };

  if (!loading) {
    return (
      <Box>
        {requestStatus === 200 && (
          <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              variant="filled"
              onClose={handleClose}
              severity="success"
              sx={{ width: '100%' }}
            >
              <AlertTitle>Success</AlertTitle>
              Pomyślnie zaaktualizowano
            </Alert>
          </Snackbar>
        )}
        {requestStatus === 409 && (
          <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              variant="filled"
              onClose={handleClose}
              severity="warning"
              sx={{ width: '100%' }}
            >
              <AlertTitle>Warning</AlertTitle>
              Dane juz istnieją
            </Alert>
          </Snackbar>
        )}
        {requestStatus === 500 && (
          <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              variant="filled"
              onClose={handleClose}
              severity="error"
              sx={{ width: '100%' }}
            >
              <AlertTitle>Warning</AlertTitle>
              Błąd serwera
            </Alert>
          </Snackbar>
        )}
        <BeerForm
          action={handleSubmit}
          buttonText="Edytuj"
          props={beer}
          actionText="edycji wyrobu gotowego"
        />
      </Box>
    );
  }
};

export default EditBeer;
