import axios from 'axios';
import { API_URL } from '../config';
import initialState from './initialState';

export const getPackages = ({ packageBeer }) => packageBeer;

const reducerName = 'packagesBeer';
const createActionName = (name) => `app/${reducerName}/${name}`;

const LOAD_PACKAGES = createActionName('LOAD_PACKAGES');
const ADD_PACKAGE = createActionName('ADD_PACKAGE');
const EDIT_PACKAGE = createActionName('EDIT_PACKAGE');
const DELETE_PACKAGE = createActionName('DELETE_PACKAGE');

export const loadPackages = (payload) => ({ payload, type: LOAD_PACKAGES });
export const addPackage = (payload) => ({ payload, type: ADD_PACKAGE });
export const editPackage = (payload) => ({ payload, type: EDIT_PACKAGE });
export const deletePackage = (payload) => ({ payload, type: DELETE_PACKAGE });

export const loadPackagesRequest = () => {
  return async (dispatch) => {
    try {
      let res = await axios.get(`${API_URL}/packages`, {
        withCredentials: true,
      });

      dispatch(loadPackages(res.data));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const loadPackageByIdRequest = (id) => {
  return async (dispatch) => {
    try {
      let res = await axios.get(`${API_URL}/packages/${id}`, {
        withCredentials: true,
      });

      dispatch(loadPackages(res.data));
      return res;
    } catch (e) {
      console.log(e);
    }
  };
};

export const addPackageRequest = (data) => {
  return async (dispatch) => {
    try {
      console.log(data);
      let res = await axios.post(`${API_URL}/packages`, data, {
        withCredentials: true,
      });

      dispatch(addPackage(res.data));
      return res;
    } catch (e) {
      return e.response;
    }
  };
};

export const editPackageRequest = (data) => {
  return async (dispatch) => {
    try {
      let res = await axios.put(`${API_URL}/packages/${data.id}`, data, {
        withCredentials: true,
      });
      dispatch(editPackage(res.data));
    } catch (e) {
      return e.message.status;
    }
  };
};

export const deletePackageRequest = (id) => {
  return async (dispatch) => {
    try {
      let res = await axios.delete(`${API_URL}/packages/${id}`, {
        withCredentials: true,
      });
      dispatch(deletePackage(id));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

const packagesReducer = (statePart = initialState, action) => {
  switch (action.type) {
    case ADD_PACKAGE:
      return [...statePart, action.payload];
    case EDIT_PACKAGE:
      return statePart.map((el) =>
        el.id === action.payload.id ? action.payload : el,
      );
    case DELETE_PACKAGE:
      return [...statePart.filter((el) => el.id !== action.payload)];
    case LOAD_PACKAGES:
      return action.payload;
    default:
      return statePart;
  }
};

export default packagesReducer;
