import { Box, Divider } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

import { useEffect } from 'react';

import ListSingleForm from '../../common/ListSingleForm/ListSingleForm';

import {
  deleteInconformityRequest,
  getUnconformities,
  loadUnconformitiesRequest,
} from '../../../redux/inconformitiesRedux';
import AddInconformityForm from '../../features/AddInconformityForm/AddInconformityForm';

const AddInconformity = () => {
  const dispatch = useDispatch();
  const unconformities = useSelector((state) => getUnconformities(state));

  const handleSubmit = async (id) => {
    await dispatch(deleteInconformityRequest(id));
  };

  useEffect(() => {
    const start = async () => {
      await dispatch(loadUnconformitiesRequest());
    };

    start();
  }, [dispatch]);

  return (
    <Box>
      <AddInconformityForm />
      <Divider sx={{ mt: 5 }} />
      {unconformities.length > 0 && (
        <ListSingleForm
          action={handleSubmit}
          props={unconformities}
          text="Rodzaj niezgodności"
          link="edit-inconformity"
        />
      )}
    </Box>
  );
};

export default AddInconformity;
