import { Box } from '@mui/material';
import Footer from '../Footer/Footer';

import ResponsiveDrawer from '../Drawer/Drawer';

const MainLayout = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        minHeight: '100vh',
        marginTop: '-10px',
        marginRight: '-10px',
      }}
    >
      <ResponsiveDrawer children={children} />

      <Footer />
    </Box>
  );
};

export default MainLayout;
