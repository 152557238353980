import { Box, Divider, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { checkLoggedUser, getUser } from '../../../redux/usersRedux';
import { useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import BeerListForm from '../../common/BeerListForm/BeerListForm';
import { deleteBeerRequest } from '../../../redux/beersRedux';
const BeerList = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => getUser(state));

  useEffect(() => {
    const start = async () => {
      await dispatch(checkLoggedUser());
    };
    start();
  }, [dispatch]);

  const handleSubmit = async (id) => {
    await dispatch(deleteBeerRequest(id));
  };
  return (
    <Box>
      <h1>Lista wyrobu gotowego</h1>
      {(user.users.role === 'ADMIN' || user.users.role === 'KONTROLER') && (
        <Button
          component={Link}
          to="/add-beer"
          sx={{ bgcolor: '#C09A53', ':hover': { bgcolor: '#9c7c43' } }}
          variant="contained"
          startIcon={<AddIcon />}
        >
          Dodaj nowy materiał
        </Button>
      )}
      <Divider sx={{ width: '100%', mb: 5, mt: 5, bgcolor: '#C09A53' }} />
      <BeerListForm action={handleSubmit} link="edit-beer" />
    </Box>
  );
};

export default BeerList;
