import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Snackbar,
  Alert,
  AlertTitle,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkLoggedUser, getUser } from '../../../redux/usersRedux';

const decision = [
  { decisionMaker: 'KIER_DS_REKLAMACJI' },
  { decisionMaker: 'KIER_SYST_ZAPEWNIENIA_JAK' },
  { decisionMaker: 'LIDER_PRZEPAKOWANIA' },
  { decisionMaker: 'KIER_ODC_BWH' },
  { decisionMaker: 'DSM' },
  { decisionMaker: 'WOM' },
  { decisionMaker: 'KONTROLER' },
];

const UserForm = ({ action, props, actionStatus }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const start = async () => {
      await dispatch(checkLoggedUser());
    };
    start();
  }, [dispatch]);
  const user = useSelector((state) => getUser(state));

  const [email, setEmail] = useState(props ? props.email : '');
  const [firstName, setFirstName] = useState(props ? props.firstName : '');
  const [lastName, setLastName] = useState(props ? props.lastName : '');
  const [role, setRole] = useState(props ? props.role : '');
  const [warehouse, setWarehouse] = useState(props ? props.warehouse : '');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [alertPassword, setAlertPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (password === '' && passwordRepeat === '') {
      if (role === '') {
        setRole(user.users.role);
      }

      const data = {
        email,
        firstName,
        lastName,
        role,
        warehouse,
      };

      action(data);
    } else if (password !== passwordRepeat) {
      setAlertPassword(true);
    } else {
      if (role === '') {
        setRole(user.users.role);
      }

      const data = {
        email,
        firstName,
        lastName,
        role,
        warehouse,
        password,
        passwordRepeat,
      };
      action(data);
    }
  };

  const handleClose = () => {
    setAlertPassword(false);
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: { xs: 'flex', md: 'flex' },
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      {alertPassword && (
        <Snackbar
          open={alertPassword}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            variant="filled"
            onClose={handleClose}
            severity="error"
            sx={{ width: '100%' }}
          >
            <AlertTitle>Error</AlertTitle>
            Hasła nie są takie same
          </Alert>
        </Snackbar>
      )}
      <Box
        sx={{
          width: { xs: '100%', md: '50%' },
          m: { md: 2 },
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {(user.users.role === 'ADMIN' || actionStatus) && (
          <TextField
            sx={{ mb: 2 }}
            label="Email"
            variant="outlined"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        )}
        <TextField
          sx={{ mb: 2 }}
          label="Imię"
          variant="outlined"
          required
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          sx={{ mb: 2 }}
          label="Nazwisko"
          variant="outlined"
          required
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        {user.users.role === 'ADMIN' && (
          <TextField
            sx={{ mb: 2 }}
            label="Magazyn"
            variant="outlined"
            required
            value={warehouse}
            onChange={(e) => setWarehouse(e.target.value)}
          />
        )}

        <FormControl sx={{ mb: 2 }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Hasło</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            value={password}
            error={password !== passwordRepeat}
            onChange={(e) => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl sx={{ mb: 2 }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-passwordRepeat">
            Powtórz hasło
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-passwordRepeat"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            value={passwordRepeat}
            error={password !== passwordRepeat}
            onChange={(e) => setPasswordRepeat(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        {user.users.role === 'ADMIN' && (
          <Autocomplete
            disablePortal
            id="combo-source"
            options={decision.map((el) => el.decisionMaker)}
            sx={{ width: '100%', mb: 2 }}
            value={role}
            onSelect={(e) => setRole(e.target.value)}
            renderInput={(params) => (
              <TextField {...params} label="Uprawnienia" />
            )}
          />
        )}
        <Button
          sx={{
            width: { xs: '100%', md: '150px' },
            color: 'white',
            bgcolor: '#C09A53',
            ':hover': { bgcolor: '#9c7c43' },
          }}
          type="submit"
        >
          Zatwierdź
        </Button>
      </Box>
    </Box>
  );
};

export default UserForm;
