import axios from 'axios';
import { API_URL } from '../config';
import initialState from './initialState';

export const getBrands = ({ brand }) => brand;

const reducerName = 'brands';
const createActionName = (name) => `app/${reducerName}/${name}`;

const LOAD_BRANDS = createActionName('LOAD_BRANDS');
const ADD_BRAND = createActionName('ADD_BRAND');
const EDIT_BRAND = createActionName('EDIT_BRAND');
const DELETE_BRAND = createActionName('DELETE_BRAND');

export const loadBrands = (payload) => ({ payload, type: LOAD_BRANDS });
export const addBrand = (payload) => ({ payload, type: ADD_BRAND });
export const editBrand = (payload) => ({ payload, type: EDIT_BRAND });
export const deleteBrand = (payload) => ({ payload, type: DELETE_BRAND });

export const loadBrandsRequest = () => {
  return async (dispatch) => {
    try {
      let res = await axios.get(`${API_URL}/brand`, {
        withCredentials: true,
      });

      dispatch(loadBrands(res.data));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const loadBrandByIdRequest = (id) => {
  return async (dispatch) => {
    try {
      let res = await axios.get(`${API_URL}/brand/${id}`, {
        withCredentials: true,
      });

      dispatch(loadBrands(res.data));
      return res;
    } catch (e) {
      console.log(e);
    }
  };
};

export const addBrandRequest = (data) => {
  return async (dispatch) => {
    try {
      console.log(data);
      let res = await axios.post(`${API_URL}/brand`, data, {
        withCredentials: true,
      });

      dispatch(addBrand(res.data));
      return res;
    } catch (e) {
      return e.response;
    }
  };
};

export const editBrandRequest = (data) => {
  return async (dispatch) => {
    try {
      let res = await axios.put(`${API_URL}/brand/${data.id}`, data, {
        withCredentials: true,
      });
      dispatch(editBrand(res.data));
    } catch (e) {
      return e.message.status;
    }
  };
};

export const deleteBrandRequest = (id) => {
  return async (dispatch) => {
    try {
      let res = await axios.delete(`${API_URL}/brand/${id}`, {
        withCredentials: true,
      });
      dispatch(deleteBrand(id));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

const brandsReducer = (statePart = initialState, action) => {
  switch (action.type) {
    case ADD_BRAND:
      return [...statePart, action.payload];
    case EDIT_BRAND:
      return statePart.map((el) =>
        el.id === action.payload.id ? action.payload : el,
      );
    case DELETE_BRAND:
      return [...statePart.filter((el) => el.id !== action.payload)];
    case LOAD_BRANDS:
      return action.payload;
    default:
      return statePart;
  }
};

export default brandsReducer;
