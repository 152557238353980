import {
  Autocomplete,
  Box,
  Button,
  Divider,
  TextField,
  Snackbar,
  Alert,
  AlertTitle,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loadSourcesRequest } from '../../../redux/sourcesRedux';
import { loadAreasRequest } from '../../../redux/areasRedux';
import { loadUnconformitiesRequest } from '../../../redux/inconformitiesRedux';
import { loadDesNonRequest } from '../../../redux/desNonRedux';
import { loadAdditionalDesRequest } from '../../../redux/additionalDesRedux';
import { useNavigate } from 'react-router-dom';

const decision = [
  { decisionMaker: 'KIER_DS_REKLAMACJI' },
  { decisionMaker: 'KIER_SYST_ZAPEWNIENIA_JAK' },
  { decisionMaker: 'LIDER_PRZEPAKOWANIA' },
  { decisionMaker: 'KIER_ODC_BWH' },
  { decisionMaker: 'DSM' },
  { decisionMaker: 'WOM' },
];

const ReasonForm = ({ props, action, actionText, buttonText }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [source, setSource] = useState('');
  const [sourceDes, setSourceDes] = useState(
    props ? props.source.description : '',
  );
  const [areaDes, setAreaDes] = useState(props ? props.area.description : '');
  const [area, setArea] = useState('');
  const [inconformity, setInconformity] = useState('');
  const [inconformityDes, setInconformityDes] = useState(
    props ? props.inconformity.description : '',
  );
  const [desNonCompliance, setDesNonComliance] = useState('');
  const [desNonComplianceDes, setDesNonComlianceDes] = useState(
    props ? props.desNonCompliance.description : '',
  );
  const [additionalDes, setAdditionalDes] = useState('');
  const [additionalDesDes, setAdditionalDesDes] = useState(
    props ? props.additionalDes.description : '',
  );
  const [sourceId, setSourceId] = useState(props ? props.source.id : '');
  const [areaId, setAreaId] = useState(props ? props.area.id : '');
  const [inconformityId, setInconformityId] = useState(
    props ? props.inconformity.id : '',
  );
  const [desNonComplianceId, setDesNonComlianceId] = useState(
    props ? props.desNonCompliance.id : '',
  );
  const [additionalDesId, setAdditionalDesId] = useState(
    props ? props.additionalDes.id : '',
  );
  const [decisionMaker, setDecisionMaker] = useState(
    props ? props.decisionMaker : '',
  );
  const [alert, setAlert] = useState(false);
  useEffect(() => {
    const start = async () => {
      const source = await dispatch(loadSourcesRequest());
      setSource(source.data);
      const area = await dispatch(loadAreasRequest());
      setArea(area.data);
      const inconformity = await dispatch(loadUnconformitiesRequest());
      setInconformity(inconformity.data);
      const desNonCompliance = await dispatch(loadDesNonRequest());
      setDesNonComliance(desNonCompliance.data);
      const additionalDes = await dispatch(loadAdditionalDesRequest());
      setAdditionalDes(additionalDes.data);
    };

    start();
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault(e);
    if (
      sourceId === '' ||
      areaId === '' ||
      inconformityId === '' ||
      desNonComplianceId === '' ||
      additionalDesId === '' ||
      decisionMaker === ''
    ) {
      setAlert(true);
    } else {
      const data = {
        sourceId,
        areaId,
        inconformityId,
        desNonComplianceId,
        additionalDesId,
        decisionMaker,
      };
      action(data);
    }
  };

  const handleClose = () => {
    setAlert(false);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <h1>{`Formularz ${actionText}`}</h1>
      <Divider sx={{ width: '100%', mb: 3, bgcolor: '#C09A53' }} />

      <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="error"
          sx={{ width: '100%' }}
        >
          <AlertTitle>Warning</AlertTitle>
          Uzupełnij wszystkie wymagane pola
        </Alert>
      </Snackbar>

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: { xs: 'flex', md: 'flex' },
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Box sx={{ width: { xs: '100%', md: '30%' }, m: { md: 2 } }}>
          {source.length > 0 && (
            <Autocomplete
              disablePortal
              id="combo-source"
              options={source.map((el) => ({
                id: el.id,
                label: el.description,
              }))}
              value={sourceDes}
              sx={{ width: '100%', mb: 2 }}
              onChange={(e, value) => (
                setSourceId(value ? value.id : ''),
                setSourceDes(value ? value.label : '')
              )}
              renderInput={(params) => (
                <TextField {...params} label="Pochodzenie produktu" />
              )}
            />
          )}
          {area.length > 0 && (
            <Autocomplete
              disablePortal
              id="combo-source"
              options={area.map((el) => ({
                id: el.id,
                label: el.description,
              }))}
              sx={{ width: '100%', mb: 2 }}
              value={areaDes}
              onChange={(e, value) => (
                setAreaId(value ? value.id : ''),
                setAreaDes(value ? value.label : '')
              )}
              renderInput={(params) => (
                <TextField {...params} label="Obszar niezgodności" />
              )}
            />
          )}
          {inconformity.length > 0 && (
            <Autocomplete
              disablePortal
              id="combo-source"
              options={inconformity.map((el) => ({
                id: el.id,
                label: el.description,
              }))}
              sx={{ width: '100%', mb: 2 }}
              value={inconformityDes}
              onChange={(e, value) => (
                setInconformityId(value ? value.id : ''),
                setInconformityDes(value ? value.label : '')
              )}
              renderInput={(params) => (
                <TextField {...params} label="Rodzaj niezgodności" />
              )}
            />
          )}
        </Box>
        <Box sx={{ width: { xs: '100%', md: '70%' }, m: { md: 2 } }}>
          {desNonCompliance.length > 0 && (
            <Autocomplete
              disablePortal
              id="combo-source"
              options={desNonCompliance.map((el) => ({
                id: el.id,
                label: el.description,
              }))}
              sx={{ width: '100%', mb: 2 }}
              value={desNonComplianceDes}
              onChange={(e, value) => (
                setDesNonComlianceId(value ? value.id : ''),
                setDesNonComlianceDes(value ? value.label : '')
              )}
              renderInput={(params) => (
                <TextField {...params} label="Opis niezgodności" />
              )}
            />
          )}
          {additionalDes.length > 0 && (
            <Autocomplete
              disablePortal
              id="combo-source"
              options={additionalDes.map((el) => ({
                id: el.id,
                label: el.description,
              }))}
              sx={{ width: '100%', mb: 2 }}
              value={additionalDesDes}
              onChange={(e, value) => (
                setAdditionalDesId(value ? value.id : ''),
                setAdditionalDesDes(value ? value.label : '')
              )}
              renderInput={(params) => (
                <TextField {...params} label="Dodatkowy opis" />
              )}
            />
          )}
          {decision.length > 0 && (
            <Autocomplete
              disablePortal
              id="combo-source"
              options={decision.map((el) => el.decisionMaker)}
              sx={{ width: '100%', mb: 2 }}
              value={decisionMaker}
              onSelect={(e) => setDecisionMaker(e.target.value)}
              renderInput={(params) => (
                <TextField {...params} label="Decydent" />
              )}
            />
          )}
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
            <Button
              sx={{
                width: { xs: '100%', md: '150px' },
                color: 'white',
                bgcolor: '#C09A53',
                ':hover': { bgcolor: '#9c7c43' },
              }}
              type="submit"
            >
              {buttonText}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ReasonForm;

//source, area, inconformity, desNonCompliance, additionalDes, decisionMaker
