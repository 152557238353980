import axios from 'axios';
import { API_URL } from '../config';
import initialState from './initialState';

export const getBeers = ({ beer }) => beer;

const reducerName = 'beers';
const createActionName = (name) => `app/${reducerName}/${name}`;

const LOAD_BEERS = createActionName('LOAD_BEERS');
const ADD_BEER = createActionName('ADD_BEER');
const EDIT_BEER = createActionName('EDIT_BEER');
const DELETE_BEER = createActionName('DELETE_BEER');

export const loadBeers = (payload) => ({ payload, type: LOAD_BEERS });
export const addBeer = (payload) => ({ payload, type: ADD_BEER });
export const editBeer = (payload) => ({ payload, type: EDIT_BEER });
export const deleteBeer = (payload) => ({ payload, type: DELETE_BEER });

export const loadBeersRequest = () => {
  return async (dispatch) => {
    try {
      let res = await axios.get(`${API_URL}/products`, {
        withCredentials: true,
      });

      dispatch(loadBeers(res.data));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const loadBeerByIdRequest = (id) => {
  return async (dispatch) => {
    try {
      let res = await axios.get(`${API_URL}/products/${id}`, {
        withCredentials: true,
      });

      dispatch(loadBeers(res.data));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const addBeerRequest = (data) => {
  return async (dispatch) => {
    try {
      console.log('redux', data);
      let res = await axios.post(`${API_URL}/products`, data, {
        withCredentials: true,
      });
      console.log(res);
      dispatch(addBeer(res.data));

      return res;
    } catch (e) {
      return e.response;
    }
  };
};

export const editBeerRequest = (data) => {
  return async (dispatch) => {
    try {
      let res = await axios.put(`${API_URL}/products/${data.id}`, data, {
        withCredentials: true,
      });

      // dispatch(editBeer(res.data));

      return res;
    } catch (e) {
      return e.message.status;
    }
  };
};

export const deleteBeerRequest = (id) => {
  return async (dispatch) => {
    try {
      let res = await axios.delete(`${API_URL}/products/${id}`, {
        withCredentials: true,
      });
      dispatch(deleteBeer(id));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

const beersReducer = (statePart = initialState, action) => {
  switch (action.type) {
    case ADD_BEER:
      return [...statePart, action.payload];
    case EDIT_BEER:
      return statePart.map((el) =>
        el.id === action.payload.id ? action.payload : el,
      );
    case DELETE_BEER:
      return [...statePart.filter((el) => el.id !== action.payload)];
    case LOAD_BEERS:
      return action.payload;
    default:
      return statePart;
  }
};

export default beersReducer;
