import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Divider,
  Modal,
  TextField,
  Autocomplete,
  Snackbar,
  Alert,
  AlertTitle,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Delete from '@mui/icons-material/Delete';

import {
  dateFormatter,
  dateFormatterWithTime,
} from '../../../utils/dateFormatter';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../../redux/usersRedux';
import { changeActionName } from '../../../utils/changeActionName';
import { changeStatusName } from '../../../utils/changeStatusName';
import { useEffect, useState } from 'react';
import {
  deleteActionPZ05Request,
  deleteDecisionPZ05Request,
} from '../../../redux/reportsPZ05Redux';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '90%', md: '70%' },
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const warehouses = [
  { description: '8AAA' },
  { description: '8AAB' },
  { description: '8AAC' },
  { description: '8AAE' },
  { description: '8AAG' },
];

const qualifications = [
  { description: 'Manufacturing_Process' },
  { description: 'Manufacturing_Materials' },
  { description: 'Manufacturing_Products' },
  { description: 'NPD_Design' },
  { description: 'SC_Uncontrollable' },
  { description: 'Others' },
];

const decisions = [
  {
    description: 'Naprawa',
    value: 'REPAIR',
    responses: [
      { description: 'Naprawione', value: 'FIXED' },
      { description: 'Uszkodzone', value: 'BROKEN' },
    ],
  },
  {
    description: 'Utylizacja',
    value: 'UTILIZATION',
    responses: [{ description: 'Zutylizowano', value: 'DESTROYED' }],
  },
  {
    description: 'Przesunięcie',
    value: 'MOVEMENT',
    responses: [{ description: 'Przesunięto', value: 'MOVED' }],
  },
  {
    description: 'Sortowanie',
    value: 'SEGREGATION',
    responses: [
      { description: 'Prawidłowe', value: 'GOOD' },
      { description: 'Niezgodne', value: 'INCORRECT' },
      { description: 'Uszkodzone', value: 'BROKEN' },
    ],
  },
  {
    description: 'Sprzedaż',
    value: 'SALE',
    responses: [{ description: 'Uwolnione', value: 'RELEASED' }],
  },
  {
    description: 'Brak podjęcia działań w terminie 5 dni',
    value: 'NOACTION',
    responses: [
      { description: 'Uwolnione', value: 'RELEASED' },
      { description: 'Zutylizowano', value: 'DESTROYED' },
    ],
  },
  // {
  //   description: 'Segregacja na linii',
  //   value: 'LINE_SEGREGATION',
  //   responses: [
  //     { description: 'Uszkodzone', value: 'BROKEN' },
  //     { description: 'Prawidłowe', value: 'GOOD' },
  //     { description: 'Niezgodne', value: 'INCORRECT' },
  //   ],
  // },
];

const responses = [
  {
    description: 'Naprawione',
    value: 'FIXED',
    decisions: [{ description: 'Sprzedaż', value: 'SALE' }],
  },
  {
    description: 'Uszkodzone',
    value: 'BROKEN',
    decisions: [
      {
        description: 'Przesunięcie',
        value: 'MOVEMENT',
      },
      { description: 'Utylizacja', value: 'UTILIZATION' },
    ],
  },
  { description: 'Uwolnione', value: 'RELEASED', decisions: [] },
  { description: 'Zutylizowano', value: 'DESTROYED', decisions: [] },
  {
    description: 'Przesunięto',
    value: 'MOVED',
    decisions: [
      { description: 'Sprzedaż', value: 'SALE' },
      { description: 'Naprawa', value: 'REPAIR' },
      { description: 'Sortowanie', value: 'SEGREGATION' },
      { description: 'Utylizacja', value: 'UTILIZATION' },

      // { description: 'Segregacja na linii', value: 'LINE_SEGREGATION' },
    ],
  },
  {
    description: 'Prawidłowe',
    value: 'GOOD',
    decisions: [{ description: 'Sprzedaż', value: 'SALE' }],
  },
  {
    description: 'Niezgodne',
    value: 'INCORRECT',
    decisions: [
      { description: 'Naprawa', value: 'REPAIR' },
      { description: 'Przesunięcie', value: 'MOVEMENT' },
      { description: 'Utylizacja', value: 'UTILIZATION' },
      // { description: 'Segregacja', value: 'SEGREGATION' },
      // { description: 'Segregacja na linii', value: 'LINE_SEGREGATION' },
    ],
  },
];

const DecisionForm = ({ action, action2, action3, deleteAction, props }) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [valueOne, setValueOne] = useState('');
  const [valueTwo, setValueTwo] = useState('');
  const [comments, setComments] = useState('');
  const user = useSelector((state) => getUser(state));

  const [decision, setDecision] = useState('');
  const [response, setResponse] = useState('');
  const [warehouseMove, setWarehouseMove] = useState('');
  const [qualification, setQualification] = useState('');
  const [error, setError] = useState(false);
  const [newQty, setNewQty] = useState(props.qty);
  const [currentDecision, setCurrentDecision] = useState('');
  const [currentDecisionDes, setCurrentDecisionDes] = useState('');

  const buttonColor = (action) => {
    switch (action) {
      case 'FIXED':
        return '#FF9900';
      case 'BROKEN':
        return '#FF0033';
      case 'DESTROYED':
        return '#FF0033';
      case 'MOVED':
        return '#3399FF';
      case 'RELEASED':
        return '#339900';
      case 'REPAIR':
        return '#FF9900';
      case 'UTILIZATION':
        return '#FF0033';
      case 'MOVEMENT':
        return '#3399FF';
      case 'SEGREGATION':
        return '#FF9900';
      case 'SALE':
        return '#339900';
      case 'LINE_SEGREGATION':
        return '#FF9900';
      case 'GOOD':
        return '#339900';
      case 'INCORRECT':
        return '#FF0033';
      default:
        return 'black';
    }
  };

  const buttonHoverColor = (action) => {
    switch (action) {
      case 'FIXED':
        return '#CC6600';
      case 'BROKEN':
        return '#CC0033';
      case 'DESTROYED':
        return '#CC0033';
      case 'MOVED':
        return '#003399';
      case 'RELEASED':
        return 'green';
      case 'REPAIR':
        return '#CC6600';
      case 'UTILIZATION':
        return '#CC0033';
      case 'MOVEMENT':
        return '#003399';
      case 'SEGREGATION':
        return '#CC6600';
      case 'SALE':
        return 'green';
      case 'LINE_SEGREGATION':
        return '#CC6600';
      case 'GOOD':
        return 'green';
      case 'INCORRECT':
        return '#CC0033';
      default:
        return 'black';
    }
  };

  const handleSubmit = (value) => {
    if (value === 'UTILIZATION' && qualification === '') {
      setError(true);
      return;
    }
    let warehouse = '';
    let activeWarehouse = props.activeWarehouse;
    if (value === 'MOVED') {
      warehouse = props.warehouseMove;
      activeWarehouse = props.warehouseMove;
    }
    if (value === 'MOVEMENT') {
      warehouse = warehouseMove;
    }
    const data = {
      id: props.id,
      userId: user.users.id,
      comments: comments,
      userRole: user.users.role,
      activeDecision: value,
      warehouseMove: warehouse !== '' ? warehouse : '',
      activeWarehouse: activeWarehouse,
      dateCreated: dateFormatterWithTime(new Date()),
      decisionDes: changeActionName(value),
      qualificationData: qualification !== '' ? qualification : null,
    };

    const qty = props.qty;

    action(data, qty);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleValueChange = (val) => {
    if (parseInt(val) > parseInt(props.qty)) {
      setValueOne(props.qty);
    } else if (parseInt(val) < 0) {
      setValueOne(0);
    }
    if (val !== '') {
      if (parseInt(props.qty) - parseInt(val) < 0) {
        setValueTwo('0');
      } else {
        const value = parseInt(props.qty) - parseInt(val);
        setValueTwo(value.toString());
      }
    }
  };

  useEffect(() => {
    const start = async () => {
      setDecision(decisions.find((el) => el.value === props.activeDecision));
      setResponse(responses.find((el) => el.value === props.activeDecision));
    };
    start();
  }, [props]);

  const handleSplitDecision = () => {
    const dataOne = {
      id: props.id,
      qty: valueOne,
    };
    const dataTwo = {
      reportDetailId: props.reportDetailId,
      userId: props.userId,
      comments: props.comments,
      userRole: props.actions[0].userRole,
      activeDecision: props.activeDecision,
      productId: props.productId,
      qty: valueTwo,
      status: props.status,
      warehouseMove: props.warehouseMove,
      userAssignment: props.userRole,
      activeWarehouse: props.activeWarehouse,
      dateCreated: dateFormatterWithTime(props.updatedAt),
      decisionDes: changeActionName(props.activeDecision),
      qualificationData: qualification !== '' ? qualification : null,
    };
    action2(dataOne, dataTwo);
    setModalOpen(false);
  };

  const handleHL = (value) => {
    return (value * props.product.casHL).toFixed(2);
  };

  const handleCloseAlert = () => {
    setError(false);
  };

  const handleNewQty = async () => {
    if (newQty !== '') {
      const data = {
        id: props.id,
        qty: newQty,
      };
      action3(props.id, data);
    }
  };

  const deleteDecision = async (id, index) => {
    if (props.actions.length === 1) {
      const res = await dispatch(deleteDecisionPZ05Request(props.id));

      if (res.status === 200) {
        const data = {
          decisionMaker: props.actions[index].userRole,
          reportDetailsData: {
            id: props.reportDetailId,
            activeWarehouse: props.activeWarehouse,
            assignment: props.actions[index].userRole,
          },
          decision: null,
        };
        const decisionId = props.id;

        deleteAction(data, decisionId);
      }
    } else {
      const res = await dispatch(deleteActionPZ05Request(id));
      if (res.status === 200) {
        let status = '';
        let activeWarehouse = props.activeWarehouse;

        let warehouseMove = props.actions[index + 1].warehouseMove;
        if (props.actions.length === 2) {
          status = 'NEW';
        } else {
          status = 'IN_PROGRESS';
        }
        if (props.actions[index + 1].decisions === 'MOVEMENT') {
          activeWarehouse = props.actions[index].user.warehouse;
        }

        const data = {
          statusReport: 'IN_PROGRESS',
          decisionMaker:
            props.status === 'DONE'
              ? 'KONTROLER'
              : props.actions[index].userRole,
          reportDetailsData: {
            id: props.reportDetailId,
            activeWarehouse: activeWarehouse,
            assignment:
              props.status === 'DONE'
                ? 'KONTROLER'
                : props.actions[index].userRole,
            statusDetail: 'IN_PROGRESS',
          },
          decision: {
            id: props.id,
            userId: props.actions[index + 1].userId,
            comments: props.actions[index + 1].comments,
            userRole: props.actions[index].userRole,
            activeDecision: props.actions[index + 1].decisions,
            status: status,
            warehouseMove: warehouseMove,
            activeWarehouse: activeWarehouse,
          },
        };
        deleteAction(data);
      }
    }
  };

  return (
    <Box component={Paper}>
      {error && (
        <Snackbar
          open={error}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert
            variant="filled"
            onClose={handleCloseAlert}
            severity="error"
            sx={{ width: '100%' }}
          >
            <AlertTitle>Brak kwalifikacji</AlertTitle>W przypadku wyboru decyzji
            utylizacja należy wybrać kwalifikację
          </Alert>
        </Snackbar>
      )}
      {/* dodac ilosc HL */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6" sx={{ mt: 2, ml: 2, fontStyle: 'italic' }}>
          {`Indeks: `}
          <span style={{ fontWeight: 'bold' }}>
            {props.product.materialNumber}
          </span>
          {` Nazwa: `}
          <span style={{ fontWeight: 'bold' }}>
            {props.product.description}
          </span>{' '}
          {` Ilość: `}{' '}
          <span style={{ fontWeight: 'bold' }}>{`${props.qty} cas`}</span>{' '}
          {` Ilość HL: `}{' '}
          <span style={{ fontWeight: 'bold' }}>{`${handleHL(props.qty)}`}</span>
        </Typography>
        {props.status !== 'DONE' && (
          <Typography
            variant="h6"
            sx={{ mr: 2, mt: 2, ml: 2, fontStyle: 'italic' }}
          >
            Oczekuje działania:{' '}
            <span style={{ fontWeight: 'bold' }}>{props.userRole}</span>
          </Typography>
        )}
      </Box>

      <Divider sx={{ width: '100%', mb: 3, bgcolor: '#C09A53' }} />
      <Accordion sx={{ mt: 2 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <TableCell sx={{ width: '15%' }}>
            {`${changeActionName(props.activeDecision)} ${
              props.warehouseMove !== null &&
              (props.activeDecision === 'MOVEMENT' ||
                props.activeDecision === 'MOVED')
                ? `${props.warehouseMove}`
                : ''
            }`}
          </TableCell>
          <TableCell sx={{ width: '30%' }}>
            {props.actions[0].comments}
          </TableCell>
          <TableCell
            sx={{ width: '15%' }}
          >{`${props.user.firstName} ${props.actions[0].user.lastName}`}</TableCell>
          <TableCell sx={{ width: '15%' }}>
            {props.actions[0].userRole}
          </TableCell>
          <TableCell sx={{ width: '10%' }}>
            {dateFormatter(props.updatedAt)}
          </TableCell>

          {props.status === 'DONE' && (
            <TableCell sx={{ width: '15%' }}>
              <Chip label={changeStatusName(props.status)} color="success" />
            </TableCell>
          )}
          {props.status === 'IN_PROGRESS' && (
            <TableCell sx={{ width: '15%' }}>
              <Chip
                label={changeStatusName(props.status)}
                sx={{ backgroundColor: 'orange' }}
              />
            </TableCell>
          )}
          {props.status === 'NEW' && (
            <TableCell sx={{ width: '15%' }}>
              <Chip label={changeStatusName(props.status)} color="primary" />
            </TableCell>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer>
            <Table>
              <TableHead>
                <TableCell sx={{ width: '15%' }}>Decyzja</TableCell>
                <TableCell sx={{ width: '25%' }}>Komentarz</TableCell>
                <TableCell sx={{ width: '25%' }}>Użytkownik</TableCell>
                <TableCell sx={{ width: '10%' }}>Rola</TableCell>
                <TableCell sx={{ width: '15%' }}>Data</TableCell>
                {user.users.role === 'ADMIN' && (
                  <TableCell sx={{ width: '10%', textAlign: 'center' }}>
                    Akcja
                  </TableCell>
                )}
              </TableHead>
              <TableBody>
                {props.actions.map((action, index) => (
                  <TableRow key={action.id}>
                    <TableCell sx={{ width: '15%' }}>
                      {`${changeActionName(action.decisions)} ${
                        action.warehouseMove !== null &&
                        (action.decisions === 'MOVEMENT' ||
                          action.decisions === 'MOVED')
                          ? `${action.warehouseMove}`
                          : ''
                      }`}
                    </TableCell>
                    <TableCell sx={{ width: '25%' }}>
                      {action.comments}
                    </TableCell>
                    <TableCell sx={{ width: '25%' }}>
                      {`${action.user.firstName} ${action.user.lastName}`}
                    </TableCell>
                    <TableCell sx={{ width: '10%' }}>
                      {action.userRole}
                    </TableCell>
                    <TableCell sx={{ width: '15%' }}>
                      {dateFormatter(action.createdAt)}
                    </TableCell>
                    {user.users.role === 'ADMIN' && index === 0 && (
                      <TableCell sx={{ textAlign: 'center' }}>
                        <IconButton
                          aria-label="delete"
                          color="error"
                          onClick={() => deleteDecision(action.id, index)}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {user.users.role === 'ADMIN' ||
          (user.users.role === 'KONTROLER' &&
            props.status !== 'DONE' &&
            props.userRole === user.users.role) ? (
            <Button
              variant="contained"
              sx={{
                mt: 2,
                ml: 2,
                bgcolor: '#3399FF',
                ':hover': { bgcolor: '#3366FF' },
              }}
              onClick={() => setModalOpen(true)}
            >
              Podziel pozycję
            </Button>
          ) : (
            ''
          )}
        </AccordionDetails>
      </Accordion>
      <Divider sx={{ width: '100%', mt: 3, mb: 1, bgcolor: '#C09A53' }} />

      {(props.userRole === 'KONTROLER' || user.users.role === 'ADMIN') &&
      decision !== '' &&
      user.users.role === 'KONTROLER' &&
      props.status !== 'DONE' ? (
        <Box
          sx={{
            mb: 1,
            display: 'flex',
            justifyContent: 'space-around',
            height: '60px',
          }}
        >
          <TextField
            id="comments"
            label="Komentarz"
            variant="standard"
            sx={{ height: '30px' }}
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          />
          {decision.responses.map((el) => (
            <Button
              variant="contained"
              sx={{
                bgcolor: buttonColor(el.value),
                ':hover': { bgcolor: buttonHoverColor(el.value) },
                height: '35px',
                mt: 2,
              }}
              onClick={() => handleSubmit(el.value)}
            >
              {el.description}
            </Button>
          ))}
        </Box>
      ) : (
        ''
      )}

      {(props.userRole !== 'KONTROLER' || user.users.role === 'ADMIN') &&
        user.users.role === props.userRole &&
        props.status !== 'DONE' && (
          <Box
            sx={{
              height: '60px',
              mb: 1,
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            {response !== '' && (
              <>
                <Autocomplete
                  disablePortal
                  id="combo-responses"
                  options={response.decisions.map((el) => ({
                    id: el.value,
                    label: el.description,
                  }))}
                  value={currentDecisionDes}
                  sx={{ width: '30%' }}
                  onChange={(e, value) => (
                    setCurrentDecision(value ? value.id : ''),
                    setCurrentDecisionDes(value ? value.label : '')
                  )}
                  renderInput={(params) => (
                    <TextField required {...params} label="Działanie" />
                  )}
                />
                {currentDecision === 'MOVEMENT' && (
                  <Autocomplete
                    disablePortal
                    id="combo-warehouses"
                    options={warehouses.map((el) => ({
                      label: el.description,
                    }))}
                    sx={{ width: '20%' }}
                    value={warehouseMove}
                    onChange={(e, value) =>
                      setWarehouseMove(value ? value.label : '')
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label="Magazyn"
                        variant="standard"
                      />
                    )}
                  />
                )}
                {currentDecision === 'UTILIZATION' && (
                  <Autocomplete
                    disablePortal
                    id="combo-qualifications"
                    options={qualifications.map((el) => ({
                      label: el.description,
                    }))}
                    sx={{ width: '20%' }}
                    value={qualification}
                    onChange={(e, value) =>
                      setQualification(value ? value.label : '')
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label="Kwalifikacja"
                        variant="standard"
                      />
                    )}
                  />
                )}
                <TextField
                  id="comments"
                  label="Komentarz"
                  variant="standard"
                  sx={{ height: '30px' }}
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                />
                <Button
                  sx={{
                    mt: 1,
                    color: 'white',
                    bgcolor: '#C09A53',
                    ':hover': { bgcolor: '#9c7c43' },
                    height: '40px',
                  }}
                  onClick={() => handleSubmit(currentDecision)}
                >
                  Zatwierdź
                </Button>
              </>
            )}
            {/* {response !== ''
              ? response.decisions.map((el) => (
                  <>
                    <Button
                      variant="contained"
                      sx={{
                        bgcolor: buttonColor(el.value),
                        ':hover': { bgcolor: buttonHoverColor(el.value) },
                        height: '35px',
                        mt: 2,
                      }}
                      onClick={() => handleSubmit(el.value)}
                    >
                      {el.description}
                    </Button>
                    {el.value === 'MOVEMENT' && (
                      <Autocomplete
                        disablePortal
                        id="combo-warehouses"
                        options={warehouses.map((el) => ({
                          label: el.description,
                        }))}
                        sx={{ width: '20%' }}
                        value={warehouseMove}
                        onChange={(e, value) =>
                          setWarehouseMove(value ? value.label : '')
                        }
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            label="Magazyn"
                            variant="standard"
                          />
                        )}
                      />
                    )}
                    {el.value === 'UTILIZATION' && (
                      <Autocomplete
                        disablePortal
                        id="combo-qualifications"
                        options={qualifications.map((el) => ({
                          label: el.description,
                        }))}
                        sx={{ width: '20%' }}
                        value={qualification}
                        onChange={(e, value) =>
                          setQualification(value ? value.label : '')
                        }
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            label="Kwalifikacja"
                            variant="standard"
                          />
                        )}
                      />
                    )}
                  </>
                ))
              : ''} */}
          </Box>
        )}
      {user.users.role === 'ADMIN' && props.status !== 'DONE' && (
        <Box
          sx={{
            height: '60px',
            mb: 1,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <TextField
            id="comments"
            label="Nowa ilość"
            variant="standard"
            value={newQty}
            onChange={(e) => setNewQty(e.target.value)}
          />
          <Button
            variant="contained"
            sx={{
              height: '35px',
              ml: 2,
              mt: 1,
            }}
            onClick={() => handleNewQty(newQty)}
          >
            Zmień ilość
          </Button>
        </Box>
      )}
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <Typography variant="h6">Podaj wartości do podziału</Typography>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-around', mt: 1 }}
            >
              <TextField
                id="valueOne"
                label="Wartość 1"
                variant="outlined"
                value={valueOne}
                required
                onChange={(e) => (
                  setValueOne(e.target.value), handleValueChange(e.target.value)
                )}
              />
              <TextField
                id="valueOne"
                label="Wartość 2"
                disabled
                variant="outlined"
                value={valueTwo}
              />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button
              sx={{
                mt: 2,
                ml: 2,
                color: 'white',
                bgcolor: '#C09A53',
                ':hover': { bgcolor: '#9c7c43' },
              }}
              onClick={() => handleSplitDecision()}
            >
              Zatwierdź
            </Button>
            <Button
              sx={{
                mt: 2,
                bgcolor: '#FF0000',
                ':hover': { bgcolor: '#CC0000' },
              }}
              variant="contained"
              onClick={handleModalClose}
            >
              Zamknij
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
export default DecisionForm;
