import axios from 'axios';
import { API_URL } from '../config';
import initialState from './initialState';

export const getReasons = ({ reason }) => reason;

const reducerName = 'reasons';
const createActionName = (name) => `app/${reducerName}/${name}`;

const LOAD_REASONS = createActionName('LOAD_REASONS');
const ADD_REASON = createActionName('ADD_REASON');
const EDIT_REASON = createActionName('EDIT_REASON');
const DELETE_REASON = createActionName('DELETE_REASON');

export const loadReasons = (payload) => ({ payload, type: LOAD_REASONS });
export const addReason = (payload) => ({ payload, type: ADD_REASON });
export const editReason = (payload) => ({ payload, type: EDIT_REASON });
export const deleteReason = (payload) => ({ payload, type: DELETE_REASON });

export const loadReasonsRequest = () => {
  return async (dispatch) => {
    try {
      let res = await axios.get(`${API_URL}/incompatibilities`, {
        withCredentials: true,
      });

      dispatch(loadReasons(res.data));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const loadReasonsByIdRequest = (id) => {
  return async (dispatch) => {
    try {
      let res = await axios.get(`${API_URL}/incompatibilities/${id}`, {
        withCredentials: true,
      });

      dispatch(loadReasons(res.data));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const loadReasonsBySourceId = (sourceId) => {
  return async (dispatch) => {
    try {
      let res = await axios.get(
        `${API_URL}/incompatibilities/source/${sourceId}`,
        { withCredentials: true },
      );
      // dispatch(loadReasons(res.data));
      console.log(res);
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const addReasonRequest = (data) => {
  return async (dispatch) => {
    try {
      let res = await axios.post(`${API_URL}/incompatibilities`, data, {
        withCredentials: true,
      });

      return res;
    } catch (e) {
      return e.response;
    }
  };
};

export const editReasonRequest = (data) => {
  return async (dispatch) => {
    try {
      let res = await axios.put(
        `${API_URL}/incompatibilities/${data.id}`,
        data,
        {
          withCredentials: true,
        },
      );

      return res;
    } catch (e) {
      return e.response;
    }
  };
};

export const deleteReasonRequest = (id) => {
  return async (dispatch) => {
    try {
      let res = await axios.delete(`${API_URL}/incompatibilities/${id}`, {
        withCredentials: true,
      });
      dispatch(deleteReason(id));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

const reasonsReducer = (statePart = initialState, action) => {
  switch (action.type) {
    case ADD_REASON:
      return [...statePart, action.payload];
    case EDIT_REASON:
      return statePart.map((el) =>
        el.id === action.payload.id ? action.payload : el,
      );
    case DELETE_REASON:
      return [...statePart.filter((el) => el.id !== action.payload)];
    case LOAD_REASONS:
      return action.payload;
    default:
      return statePart;
  }
};

export default reasonsReducer;
