import { useDispatch } from 'react-redux';
import { addBeerRequest } from '../../../redux/beersRedux';
import BeerForm from '../../common/BeerForm/BeerForm';
import { useState } from 'react';
import { Snackbar, Alert, AlertTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AddBeer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [requestStatus, setRequestStatus] = useState('');
  const [alert, setAlert] = useState(false);
  const handleSubmit = async (data) => {
    setAlert(true);
    const res = await dispatch(addBeerRequest(data));
    setRequestStatus(res.status);

    setTimeout(() => {
      navigate('/beer');
    }, 2000);
  };

  const handleClose = () => {
    setAlert(false);
  };

  return (
    <>
      {requestStatus === 201 && (
        <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            variant="filled"
            onClose={handleClose}
            severity="success"
            sx={{ width: '100%' }}
          >
            <AlertTitle>Success</AlertTitle>
            Pomyślnie dodano
          </Alert>
        </Snackbar>
      )}
      {requestStatus === 409 && (
        <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            variant="filled"
            onClose={handleClose}
            severity="warning"
            sx={{ width: '100%' }}
          >
            <AlertTitle>Warning</AlertTitle>
            Dane juz istnieją
          </Alert>
        </Snackbar>
      )}
      {requestStatus === 500 && (
        <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            variant="filled"
            onClose={handleClose}
            severity="error"
            sx={{ width: '100%' }}
          >
            <AlertTitle>Warning</AlertTitle>
            Błąd serwera
          </Alert>
        </Snackbar>
      )}
      <BeerForm
        action={handleSubmit}
        actionText="tworzenia nowego wyrobu gotowego"
        buttonText="Dodaj"
      />
    </>
  );
};

export default AddBeer;
