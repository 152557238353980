import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Paper,
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';

const ListSingleForm = ({ action, props, text, link }) => {
  const handleSubmit = (id) => {
    action(id);
  };

  return (
    <>
      {props.length > 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a danse table">
            <TableHead>
              <TableRow>
                <TableCell>{text}</TableCell>
                <TableCell align="center">Akcja</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.map((source) => (
                <TableRow
                  key={source.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th">{source.description}</TableCell>
                  <TableCell align="center">
                    <Button
                      sx={{ color: 'orange' }}
                      component={Link}
                      to={`/${link}/${source.id}`}
                    >
                      <EditIcon />
                    </Button>
                    <Button
                      sx={{ color: 'red' }}
                      onClick={() => handleSubmit(source.id)}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default ListSingleForm;
