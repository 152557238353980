import { useDispatch, useSelector } from 'react-redux';
import { checkLoggedUser, getUser } from '../../../redux/usersRedux';
import { useEffect, useState } from 'react';
import { Box, Button, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import UserForm from '../../common/UserForm/UserForm';
import { editUserListRequest } from '../../../redux/userListRedux';
const MyAccount = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => getUser(state));
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const start = async () => {
      await dispatch(checkLoggedUser());
      setLoading(false);
      setId(user.users.id);
    };
    start();
  }, [dispatch]);

  const handleSubmit = async (data) => {
    const res = await dispatch(editUserListRequest({ ...data, id }));
  };

  if (!loading) {
    return (
      <Box>
        <h1>Moje konto</h1>
        <Button
          component={Link}
          to="/add-user"
          sx={{ bgcolor: '#C09A53', ':hover': { bgcolor: '#9c7c43' } }}
          variant="contained"
          startIcon={<AddIcon />}
        >
          Dodaj nowego użytkownika
        </Button>
        <Divider sx={{ width: '100%', mb: 5, mt: 5, bgcolor: '#C09A53' }} />
        <UserForm props={user.users} action={handleSubmit} />
      </Box>
    );
  }
};

export default MyAccount;
