export async function generateExpirationDateFromBatch(
  batch,
  nameProduct,
  shelfLife,
  // 2731591
) {
  if (
    nameProduct.toUpperCase().includes('GROLSCH') ||
    nameProduct.toUpperCase().includes('PILSNER') ||
    nameProduct.toUpperCase().includes('ASAHI') ||
    nameProduct.toUpperCase().includes('PERONI')
  ) {
    const expirationDate = new Date();

    // return expirationDate.toLocaleDateString();
    return expirationDate;
  }
  const productionYear = await determineYearFromLastDigit(batch.charAt(2));
  const productionDay = await parseInt(batch.substring(3, 6));
  const productionDate = new Date(productionYear, 0);

  productionDate.setDate(productionDay);
  productionDate.setDate(productionDate.getDate() + shelfLife);
  // return productionDate.toLocaleDateString();
  return productionDate;
}

function determineYearFromLastDigit(lastDigit) {
  if (lastDigit >= 0 && lastDigit <= 9) {
    const currentYear = new Date().getFullYear();
    const lastYearDigit = currentYear % 10;
    const yearDifference = lastDigit - lastYearDigit;
    const determinedYear = currentYear + yearDifference;

    return determinedYear;
  } else {
    throw new Error('Ostatnia cyfra musi być w zakresie od 0 do 9.');
  }
}
