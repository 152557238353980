import axios from 'axios';
import { API_URL } from '../config';
import initialState from './initialState';

export const getUsersList = ({ userList }) => userList;

const reducerName = 'usersList';
const createActionName = (name) => `app/${reducerName}/${name}`;

const LOAD_USERS_LIST = createActionName('LOAD_USERS_LIST');
const ADD_USER_LIST = createActionName('ADD_USER_LIST');
const EDIT_USER_LIST = createActionName('EDIT_USER_LIST');
const DELETE_USER_LIST = createActionName('DELETE_USER_LIST');

export const loadUsersList = (payload) => ({ payload, type: LOAD_USERS_LIST });
export const addUserList = (payload) => ({ payload, type: ADD_USER_LIST });
export const editUserList = (payload) => ({ payload, type: EDIT_USER_LIST });
export const deleteUserList = (payload) => ({
  payload,
  type: DELETE_USER_LIST,
});

export const loadUsersListRequest = () => {
  return async (dispatch) => {
    try {
      let res = await axios.get(`${API_URL}/users`, {
        withCredentials: true,
      });

      dispatch(loadUsersList(res.data));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const loadUserListByIdRequest = (id) => {
  return async (dispatch) => {
    try {
      let res = await axios.get(`${API_URL}/users/${id}`, {
        withCredentials: true,
      });

      dispatch(loadUsersList(res.data));
      return res;
    } catch (e) {
      return e.response;
    }
  };
};

export const addUserListRequest = (data) => {
  return async (dispatch) => {
    try {
      let res = await axios.post(`${API_URL}/users`, data, {
        withCredentials: true,
      });
      dispatch(addUserList(res.data));
      return res;
    } catch (e) {
      return e.response;
    }
  };
};

export const editUserListRequest = (data) => {
  return async (dispatch) => {
    try {
      let res = await axios.put(`${API_URL}/users/${data.id}`, data, {
        withCredentials: true,
      });
      // dispatch(editUserList(res.data));
      return res;
    } catch (e) {
      return e.response;
    }
  };
};

export const deleteUserListRequest = (id) => {
  return async (dispatch) => {
    try {
      let res = await axios.delete(`${API_URL}/users/${id}`, {
        withCredentials: true,
      });
      dispatch(deleteUserList(id));
      return res;
    } catch (e) {
      console.log(e.message);
    }
  };
};

const usersListReducer = (statePart = initialState, action) => {
  switch (action.type) {
    case ADD_USER_LIST:
      return [...statePart, action.payload];
    case EDIT_USER_LIST:
      return statePart.map((el) =>
        el.id === action.payload.id ? action.payload : el,
      );
    case DELETE_USER_LIST:
      return [...statePart.filter((el) => el.id !== action.payload)];
    case LOAD_USERS_LIST:
      return action.payload;
    default:
      return statePart;
  }
};

export default usersListReducer;
